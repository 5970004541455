export const vueI18n = {"silentTranslationWarn":true,"silentFallbackWarn":true}
export const vueI18nLoader = false
export const locales = [{"iso":"tr-TR","code":"tr","file":"tr.js"},{"iso":"en-US","code":"en","file":"en.js"},{"iso":"de-DE","code":"de","file":"de.js"}]
export const defaultLocale = 'tr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = 'lang/'
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieDomain":null,"cookieKey":"lang","alwaysRedirect":false,"fallbackLocale":"","redirectOn":"root","cookieAge":365,"cookieCrossOrigin":false,"cookieSecure":false}
export const differentDomains = false
export const seo = true
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const fallbackLocale = 'tr'
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["tr","en","de"]
export const trailingSlash = undefined
