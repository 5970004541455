<template>
  <div>
    <div v-if="$store.state.company.html && !isUrl">
      <domainCmsHome />
    </div>
    <div v-else class="sub-domain">
      <domainHeader></domainHeader>
      <nuxt v-if="location" />
      <selectLocationMyRezzta v-else />
      <domainFooter></domainFooter>
    </div>
    <domainCookieLaw />
  </div>
</template>

<script>
import domainHeader from '@/layouts/header/domainHeader'
import domainFooter from '@/layouts/footer/domainFooter'
import domainCmsHome from '@/components/home/domainCmsHome'
import selectLocationMyRezzta from '@/components/common/selectLocation/selectLocationMyRezzta'
import domainCookieLaw from '@/components/common/domainCookieLaw'

export default {
  name: 'LayoutDomain',
  components: {
    domainFooter,
    domainHeader,
    domainCmsHome,
    selectLocationMyRezzta,
    domainCookieLaw
  },
  computed: {
    location() {
      return this.$store.state.auth.location?.length === 2
        ? this.$store.state.auth.location
        : null
    },
    isUrl() {
      return !/^(\/tr|\/en|\/de|\/)(\/)?$/gi.test(this.$route.path)
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        setTimeout(() => {
          this.$store.dispatch('getCompanyBlogData')
        }, 300)
      }
    }
  },
  created() {
    this.$route.params?.branchSlug &&
      this.$cookies.set('domainUrl', this.$route.params.branchSlug)
  },
  mounted() {
    this.handlePartnerInstitutionRef()
  },
  methods: {
    async handlePartnerInstitutionRef() {
      const ref = this.$route.query?.ref || null

      if (ref) {
        if (this.$store.state.auth.isLogged) {
          await this.$store.dispatch('auth/logout')
          await this.$store.dispatch('basket/getBasket')
          this.handlePartnerInstitutionRef()

          return
        }

        this.$cookies.set('ref', ref, {
          maxAge: 60 * 60 * 24 * 1,
          path: '/'
        })
        setTimeout(() => {
          this.$store.dispatch('auth/showRegisterModal')
        }, 300)
        this.$router.replace({
          query: { ref: undefined }
        })
      }
    }
  },
  head() {
    if (!this.$store.state.isBaseDomain)
      return {
        title:
          this.$store.state.company.settings.meta_title ||
          this.$t('Online Yemek Sipariş Sistemi'),
        meta: [
          // hid is used as unique identifier. Do not use `vmid` for it as it will not work
          {
            hid: 'description',
            name: 'description',
            content:
              this.$store.state.company.settings.meta_description ||
              this.$store.state.company.domainName
          }
        ],
        link: [
          {
            rel: 'icon',
            type: 'image/png',
            href:
              this.$store.state.company.settings.favicon_url ||
              '/favicon-76x76.png'
          }
        ]
      }

    return {}
  }
}
// domain sitede konum gerektiren tüm sayfalarda bu layout kullanılıyor.
</script>
