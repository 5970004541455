var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.isBaseDomain
        ? [
            _c("client-only", [_c("limonDesk")], 1),
            _vm._v(" "),
            _c("default-header"),
            _vm._v(" "),
            _c("nuxt"),
            _vm._v(" "),
            _c("default-footer")
          ]
        : [
            _c("domainHeader"),
            _vm._v(" "),
            _c("nuxt"),
            _vm._v(" "),
            _c("domainFooter"),
            _vm._v(" "),
            _c("domainCookieLaw")
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }