<template>
  <div>
    <!-- <client-only>
      <limonDesk />
    </client-only> -->
    <!-- <default-header :header-absolute="!location"></default-header>
    <div v-if="getIsPageFixed" class="page-fixed" @click="isPageFixed()"></div>
    <nuxt
      v-if="location"
      keep-alive
      :keep-alive-props="{ include: ['Restaurants'] }"
    />
    <selectLocation v-else />
    <default-footer></default-footer> -->
    <div
      class="rezzta-wrapper"
      style="background-image: url('/images/rezzta-home/background.png')"
    >
      <img src="/images/rezzta-home/line-1.png" class="rw-line-1" />
      <div class="rw-content">
        <div class="rwc-text">
          <div class="rwct-icon">
            <a
              :href="
                $i18n.locale === 'tr'
                  ? 'https://www.akinsoft.com/tr'
                  : 'https://www.akinsoft.com'
              "
              target="_blank"
            >
              <img
                alt="logo"
                src="/images/akinsoft.svg"
                class="rwc-akinsoft-logo mr-5"
              />
            </a>
            <img alt="logo" src="/images/rezzta.svg" class="rwc-rezzta-logo" />
          </div>
          <div class="rwct-center">
            <i18n
              path="rezzta_home_text_1"
              tag="p"
              class="font-weight-regular text--rezzta-black-1 rwctc-text-1"
            >
              <span class="text--rezzta-red font-weight-medium">
                {{ $t('Online Yemek Sipariş') }}
              </span>
            </i18n>
            <i18n
              path="rezzta_home_text_2"
              tag="p"
              class="font-weight-bold text--rezzta-black-1 rwctc-text-2"
            />
            <i18n
              path="rezzta_home_text_3"
              tag="p"
              class="font-weight-bold text--rezzta-black-1 mb-0 font-italic mt-auto rwctc-text-3"
            />
            <i18n
              path="rezzta_home_text_4"
              tag="p"
              class="font-weight-regular text--rezzta-black-1 mb-0 font-italic rwctc-text-4"
            />
            <div class="rwctc-bottom">
              <div class="rwctcb-btn p-relative">
                <img src="/images/rezzta-home/line-2.png" class="rw-line-2" />
                <a
                  href=""
                  class="rezzta-red text-nowrap rwctcbb-a"
                  @click.prevent="blogHandler('demo-rezzta-description')"
                >
                  {{ $t('Rezzta Hakkında') }}
                </a>
              </div>
              <i18n
                path="rezzta_home_text_5"
                tag="a"
                class="font-weight-bold text--rezzta-red text-underline text-center rwctc-text-5"
                target="_blank"
                :href="
                  $i18n.locale === 'en'
                    ? 'https://www.akinsoft.com.tr/as/irtibat/istekoneri?ozel=rezzta'
                    : 'https://www.akinsoft.com.tr/as/irtibat/istekoneri?ozel=rezzta'
                "
              />
            </div>
          </div>
        </div>
        <div class="rwc-right">
          <div class="rwcr-top">
            <img
              src="/images/rezzta-home/hamburger-background.png"
              class="rwcrt-img-1"
              alt="hamburger_background"
            />
            <img
              src="/images/rezzta-home/hamburger.png"
              class="rwcrt-img-2"
              alt="hamburger"
            />
          </div>
          <div class="rwcr-bottom">
            <img
              src="/images/rezzta-home/mobil-image.png"
              class="rwcrb-img"
              alt="mobile_image"
            />
          </div>
        </div>
      </div>
      <div class="rw-link">
        <div class="rw-lang">
          <nuxt-link
            v-if="$i18n.locale !== 'en'"
            :to="switchLocalePath('en')"
            class="fs15"
          >
            English
          </nuxt-link>
          <nuxt-link
            v-if="$i18n.locale !== 'tr'"
            :to="switchLocalePath('tr')"
            class="fs15"
          >
            Türkçe
          </nuxt-link>
          <nuxt-link
            v-if="$i18n.locale !== 'de'"
            :to="switchLocalePath('de')"
            class="fs15"
          >
            Deutsch
          </nuxt-link>
        </div>
      </div>
    </div>
    <b-modal
      v-model="blog.modal"
      size="lg"
      centered
      scrollable
      hide-footer
      content-class="custom-modal-1"
    >
      <div class="px-4 pt-2 pb-4">
        <h5 class="text-center">{{ blog.textTitle }}</h5>
        <blogArea
          :slug="blog.blogSlug"
          style="margin-top: -20px"
          @title="blog['textTitle'] = $event"
        ></blogArea>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import defaultHeader from '@/layouts/header'
// import defaultFooter from '@/layouts/footer'
// import selectLocation from '@/components/common/selectLocation'
// import trafficMixin from '@/mixins/traffic'
// import limonDesk from '@/components/limonDesk/limonDesk'
import blogArea from '@/pages/blog/_index'

export default {
  name: 'LayoutBase',
  components: {
    // defaultHeader,
    // defaultFooter,
    // selectLocation,
    // limonDesk,
    blogArea
  },
  // mixins: [trafficMixin],
  data() {
    return {
      blog: {
        modal: false,
        blogSlug: null,
        textTitle: null
      }
    }
  },
  computed: {
    ...mapGetters({
      // getIsPageFixed: 'getIsPageFixed',
      getBlogAreaSlug: 'getBlogAreaSlug'
    })
    // location() {
    //   return this.$store.state.auth.location?.length === 2
    //     ? this.$store.state.auth.location
    //     : null
    // }
  },
  // watch: {
  //   location() {
  //     // konum değiştiğinde içerikler konuma göre değiştiği için scroll başa çekiliyor
  //     window.scrollTo(0, 0)
  //   }
  // },
  // mounted() {
  //   this.$nextTick(() => {
  //     // Facebook Pixel
  //     if (localStorage.getItem('testpixel')) {
  //       this.$fb.disable()
  //     } else {
  //       this.$fb.enable()
  //       this.postTrafficMethod({ type: 'page_view' })
  //     }
  //   })
  // },
  created() {
    if (!/^\/(en|tr|de)?$/.test(this.$route.fullPath)) {
      this.$router.push(this.localePath('/'))
    }
  },
  methods: {
    isPageFixed() {
      this.$store.commit('setIsPageFixed', false)
    },
    blogHandler(name) {
      this.blog.blogSlug = this.getBlogAreaSlug(name)
      this.blog.modal = true
    }
  },
  head() {
    return {
      link: [{ rel: 'icon', type: 'image/png', href: '/favicon-76x76.png' }]
    }
  }
}
// Rezzta.comda konum gerektiren tüm sayfalarda bu layout kullanılıyor.
</script>
