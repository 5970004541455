<template>
  <div style="height: 100%; width: 100%;">
    <GmapMap
      ref="gmapComponent"
      :options="options"
      :center="center"
      :zoom="zoom"
      :style="mapStyle"
      @click="mapClick"
    >
      <template slot="visible">
        <div v-if="!hideSearch" class="search">
          <gmap-searchbox
            :options="autocompleteOptions"
            @places_changed="setPlace"
            @keydown.enter="e => e.preventDefault()"
          >
          </gmap-searchbox>
        </div>
        <div
          v-if="!hideLocateMe"
          class="locate-me control-button"
          @click="locateMe"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="map-marker-alt"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            class="svg-inline--fa fa-map-marker-alt fa-w-12"
          >
            <path
              fill="currentColor"
              d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
              class=""
            ></path>
          </svg>
        </div>
      </template>
      <GmapMarker
        v-if="marker"
        :position="marker"
        :draggable="!hideMarkerControll"
        @dragend="markerDragend"
      >
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from '@/components/googleMap/main'

export default {
  name: 'ZzMap',
  props: {
    value: {
      type: [Array, Object],
      default: undefined
    },
    getAddressText: {
      type: Boolean,
      default: false
    },
    hideLocateMe: {
      type: Boolean,
      default: false,
      required: false
    },
    hideSearch: {
      type: Boolean,
      default: false,
      required: false
    },
    hideMarkerControll: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      center: {
        lat: 39.401433,
        lng: 34.915262
      },
      zoom: 6,
      options: {
        streetViewControl: false
      },
      marker: {
        lat: Number,
        lng: Number
      },
      autocompleteOptions: {
        componentRestrictions: { country: 'tr' }
      },
      mapStyle: {
        width: '100%',
        height: '100%'
      }
    }
  },
  computed: {
    google: gmapApi
  },
  watch: {
    marker(e) {
      if (Array.isArray(this.value)) {
        if (
          this.value.length === 2 &&
          this.value[0] === this.marker.lat &&
          this.value[1] === this.marker.lng
        ) {
          return
        }
      } else if (
        this.value?.coords?.length === 2 &&
        this.value.coords[0] === this.marker.lat &&
        this.value.coords[1] === this.marker.lng
      ) {
        return
      }

      const coords = [this.marker.lat, this.marker.lng]

      if (this.getAddressText) {
        this.reverseGeocode(e, address => {
          const result = {
            addressDetail: address,
            address: address.address,
            coords
          }

          this.$emit('input', result)
        })
      } else {
        this.$emit('input', coords)
      }
    },
    value() {
      this.initValue()
    }
  },
  created() {
    if (this.$cookies.get('location') === undefined) {
      this.locateMe()
    } else {
      this.zoom = 15
      this.marker = this.center = {
        lat: this.$cookies.get('location')[0],
        lng: this.$cookies.get('location')[1]
      }
    }
  },
  mounted() {
    this.initValue()
    this.resize()
  },
  methods: {
    initValue() {
      const marker = {}

      if (Array.isArray(this.value)) {
        if (this.value.length !== 2) {
          return
        }

        marker.lat = this.value[0]
        marker.lng = this.value[1]

        if (marker.lat !== this.marker.lat || marker.lng !== this.marker.lng) {
          this.marker = this.center = marker
        }

        if (this.getAddressText) {
          const coords = [this.marker.lat, this.marker.lng]

          this.reverseGeocode(this.marker, address => {
            const result = {
              addressDetail: address,
              address: address.address,
              coords
            }

            this.$emit('input', result)
          })
        }
      }
    },
    resize() {
      // Modal görünmeme sorunu için
      this.mapStyle.width = '99%'
      setTimeout(() => {
        this.mapStyle.width = '100%'
      }, 200)
    },
    setPlace(payload) {
      if (!(payload && payload[0])) {
        return false
      }

      const { geometry } = payload[0]

      if (geometry && geometry.location) {
        this.zoom = 18
        this.marker = this.center = {
          lat: geometry.location.lat(),
          lng: geometry.location.lng()
        }
      }

      return false
    },
    markerDragend(e) {
      this.$emit('changed', e)
      this.marker = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    },
    mapClick(e) {
      if (!this.hideMarkerControll) {
        this.$emit('changed', e)
        this.marker = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
      }
    },
    reverseGeocode(position, cb) {
      this.$gmapApiPromiseLazy().then(() => {
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder()

        geocoder.geocode(
          {
            location: position
          },
          (results, status) => {
            const detail = {
              address: null,
              city: null, // İl
              district: null, // İlçe
              neighborhood: null, // Mahalle
              street: null, // Sokak
              buildingNumber: null, // Bina No
              postalTown: null, // Kasaba
              postalCode: null // Posta Kodu
            }

            if (status === 'OK') {
              detail.address = results[0].formatted_address

              const find = name => {
                const r = results[0].address_components.find(i =>
                  i.types.includes(name)
                )

                return r || {}
              }

              detail.city = find('administrative_area_level_1').long_name
              detail.district = find('administrative_area_level_2').long_name
              detail.neighborhood = find(
                'administrative_area_level_4'
              ).long_name
              detail.street = find('route').long_name
              detail.buildingNumber = find('street_number').short_name
              detail.postalTown = find('postal_town').short_name
              detail.postalCode = find('postal_code').short_name
              cb(detail)
            } else {
              cb(detail)
            }
          }
        )
      })
    },
    locateMe() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          e => {
            this.center = {
              lat: 0,
              lng: 0
            }
            this.zoom = 13
            setTimeout(() => {
              this.marker = this.center = {
                lat: e.coords.latitude,
                lng: e.coords.longitude
              }
            }, 100)
          },
          e => {
            switch (e.code) {
              case 1:
                this.$notification.error(
                  this.$t(
                    'Tarayıcınızın "Konum" özelliğine izin vermeniz gerekmektedir.'
                  )
                )
                break
              default:
                this.$notification.error(this.$t('Konum bulunamadı'))
                break
            }
          },
          options
        )
      } else {
        this.$notification.error(
          this.$t('Konum bulma tarayıcınız tarafından desteklenmiyor!')
        )
      }
    }
  }
}
</script>

<style lang="scss">
.control-button {
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  right: 0px;
  text-align: center;
  line-height: 40px;
  color: #666;
  &:hover {
    color: #333;
  }
}
.search {
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  top: 55px;
  display: inline-block;
  position: relative;
  user-select: none;
  border-radius: 2px;
  // height: 40px;
  // width: 183px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  width: calc(100% - 70px);
  top: 0px;
  input {
    width: 100%;
    transition: all 0.3s ease;
    height: 40px;
    // width: 183px;
    padding: 0 10px;
    border: none;
    outline: none;
    // &:focus {
    //   width: 500px;
    // }
    // @media (max-width: 500px) {
    //   width: 183px !important;
    // }
  }
  .vue-map-container,
  .vue-map-container .vue-map {
    width: 100%;
    height: 100%;
  }
}
.pac-container {
  z-index: 999999 !important;
}
.gmnoprint {
  // display: none;
  // top: 64px !important;
}
.gmnoprint .gm-bundled-control {
  bottom: 92px;
  display: block !important;
}
.gmnoprint .gm-style-mtc {
  display: none;
}
</style>
