<template>
  <div class="location-select-page location-select-page-myrezzta">
    <section class="icons">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="text-center">
              <h2 class="mt-3 mb-2 fs20-2 title">
                {{ $t('Konumunu Seç, Siparişini Ver') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12 pt-2 pb-2">
            <div class="mb-2 lspm-accordion-w">
              <div class="lspmaw-item mb-2">
                <div class="collapse-card-header">
                  <h6 class="mb-0">
                    <a
                      v-b-toggle="`accordionMap`"
                      href="#"
                      class="p-4"
                      @click.prevent
                    >
                      {{ $t('Haritadan Seç') }}
                      <fa icon="arrow-down" class="float-right"></fa>
                    </a>
                  </h6>
                </div>
                <b-collapse
                  :id="`accordionMap`"
                  visible
                  accordion="selectMapAcordions"
                  role="tabpanel"
                >
                  <div class="map">
                    <client-only>
                      <zzMap v-if="coords" ref="map" v-model="coords"></zzMap>
                    </client-only>
                  </div>
                </b-collapse>
              </div>
              <div
                v-show="$store.state.auth.isLogged && addresses.items.length"
                class="lspmaw-item"
              >
                <div class="collapse-card-header">
                  <h6 class="mb-0">
                    <a
                      v-b-toggle="`accordionAddress`"
                      href="javascript:;"
                      class="p-4"
                    >
                      {{ $t('Adreslerimden Seç') }}
                      <fa icon="arrow-down" class="float-right"></fa>
                    </a>
                  </h6>
                </div>
                <b-collapse
                  :id="`accordionAddress`"
                  visible
                  accordion="selectMapAcordions"
                  role="tabpanel"
                >
                  <div class="px-4 py-2 modal-effect-1-w">
                    <div class="row">
                      <div
                        v-for="address in addresses.items"
                        :key="`address${address.id}`"
                        class="col-md-6 p-2"
                        @click="handleClickAddress(address)"
                      >
                        <div
                          :class="{
                            'selected-effect-1':
                              addresses.selected.id === address.id
                          }"
                          class="bg-white box-style-6 border effect-1-w"
                        >
                          <div class="selected-effect-1-icon">
                            <svg
                              id="Outline"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="21"
                              height="21"
                            >
                              <path
                                d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"
                              />
                            </svg>
                          </div>
                          <div class="p-3 address-content">
                            <div class="media">
                              <div class="media-body">
                                <h6 class="mb-1 text-truncate fs14">
                                  {{ address.name }}
                                </h6>
                                <p class="text-black item-desc">
                                  {{ address.open_address }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <button
              class="btn btn-rezzta btn-lg d-block w-100"
              @click="handleClickContinue"
            >
              {{ $t('DEVAM ET') }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <b-modal
      ref="newAddressModal"
      v-model="addresses.formModal"
      :title="$t('Adres Düzenleme')"
      hide-footer
      size="lg"
      centered
    >
      <p class="text-danger text-center">
        {{
          $t(
            'Adresinizin konum bilgisi bulunamadığı için düzenleme formu açılmıştır! Lütfen adresinizin konum bilgisini kaydediniz.'
          )
        }}
      </p>
      <address-form
        :address-id="addresses.selected.id"
        @success="handleSuccessAddressForm"
      >
      </address-form>
    </b-modal>
  </div>
</template>

<script>
import zzMap from '@/components/zzMap'
import addressForm from '@/components/common/address/form'
import { isSeviceableArea } from '@/api/check'
import { getAddressAll } from '@/api/member'

export default {
  name: 'SelectLocation',
  components: {
    zzMap,
    addressForm
  },
  data() {
    return {
      coords: [],
      addresses: {
        items: [],
        selected: {},
        formModal: false
      }
    }
  },
  watch: {
    // Eğer giriş yapıldıysa üyenin adresleri getiriliyor
    '$store.state.auth.isLogged': {
      immediate: true,
      handler(bool) {
        if (bool) {
          this.$nextTick(() => {
            this.getAddresses()
          })
        }
      }
    }
  },
  methods: {
    // Devam Et Tıklandığında
    async handleClickContinue() {
      try {
        // Seçim yapılmadıysa
        if (!this.coords?.length) {
          this.$notification.error(this.$t('Konum seçmelisiniz'))

          return
        }

        // Seçilen bölgede hizmet veren restoranlar var mı
        const result = await this.$axios.request({
          ...isSeviceableArea(),
          headers: {
            lat: this.coords[0],
            lon: this.coords[1]
          }
        })

        if (!result.data) {
          throw Error
        }

        this.$store.dispatch('auth/setLocation', this.coords)

        // rezzta.com değilse
        if (!this.$store.state.isBaseDomain) {
          // Eğer bir tane restoran döndüyse direk o restorana git
          if (result.data.length === 1) {
            this.$router.push(
              this.localePath({
                name: 'restaurant-branchSlug',
                params: {
                  branchSlug: result.data[0]?.slug
                }
              })
            )
          }
        }

        setTimeout(() => {
          location.reload()
        }, 200)
      } catch (error) {
        // Seçilen konuma hizmet veren restoran yok
        this.$notification.error(
          this.$t('Seçilen bölgeye hizmet veren restoran bulunamadı!')
        )
      }
    },
    // Üye adreslerini getirir
    async getAddresses() {
      try {
        const result = await this.$axios.request({
          ...getAddressAll({
            memberId: this.$store.state.auth.info.id
          })
        })

        this.addresses.items = result.data
      } catch (err) {
        this.$notification.error(err.message)
      }
    },
    // Üyenin adreslerinden birine tıklandığında konum olarak adresin koordinatları ayarlanıyor. Eğer adreste daha önceden konum kaydedilmemişse adres düzenleme modalı açılıyor.
    handleClickAddress(item) {
      this.addresses.selected = item

      if (item.coordinate && item.coordinate.x && item.coordinate.y) {
        this.coords = [item.coordinate.x, item.coordinate.y]
      } else {
        this.addresses.formModal = true
      }
    },
    // Adres kaydediliyor ve konum ayarlanıyor
    handleSuccessAddressForm(model) {
      this.addresses.formModal = false

      if (
        model.location &&
        model.location.coords &&
        model.location.coords.length === 2
      ) {
        this.coords = model.location.coords
      }

      this.getAddresses()
    }
  }
}
</script>
