var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("zz-div", { attrs: { loading: _vm.loading } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form-material",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.handleFormSubmit)
                          }
                        }
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: _vm.$t("E-Posta Adresiniz") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-label-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.email,
                                              expression: "email"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: { "is-invalid": errors[0] },
                                          attrs: {
                                            id: "inputEmail",
                                            placeholder: _vm.$t(
                                              "E-Posta Adresiniz"
                                            ),
                                            disabled: _vm.loading,
                                            type: "email",
                                            required: ""
                                          },
                                          domProps: { value: _vm.email },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.email = $event.target.value
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "common-icon icon-style-1",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.icons.at)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "inputEmail" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("E-Posta Adresiniz")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 rezzta-red white--text",
                            attrs: { disabled: _vm.loading, type: "submit" }
                          },
                          [
                            _vm.loading
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("Gönder")) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }