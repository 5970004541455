import Vue from 'vue'

export default function({ store }) {
  const symbolMap = new Map([
    ['suffix', 'back'],
    ['prefix', 'front']
  ])

  const currencyList = (
    (store?.state?.currencyList?.length && store.state.currencyList) || [
      { name: 'Türk Lirası', symbol: '₺', symbol_format: 'suffix' }
    ]
  ).map(x => {
    return {
      ...x,
      symbolFormat: symbolMap.has(x.symbol_format)
        ? symbolMap.get(x.symbol_format)
        : 'back'
    }
  })

  Vue.filter('currency', (value, symbolObj = { symbol: '₺' }) => {
    const findCurrency = currencyList.find(x => x.symbol === symbolObj.symbol)

    return Vue.options.filters.defaultCurrency(value, {
      symbol: symbolObj.symbol,
      symbolPosition: findCurrency ? findCurrency.symbolFormat : 'back'
    })
  })
}
