<template>
  <span v-if="$scopedSlots['input']">
    <slot
      :ref="input"
      name="input"
      :attrs="$attrs"
      :listeners="$listeners"
    ></slot>
  </span>
  <input
    v-else-if="!$scopedSlots['input']"
    ref="input"
    :placeholder="$t('Adres Ara')"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default (x => x.default || x)(
  require('../components-implementation/searchbox')
)
</script>
