<template>
  <zz-div :loading="loading">
    <template v-if="info.id">
      <div class="mb-2">
        <p class="pa-3 mb-1 grey lighten-5 p-relative">
          <span class="fs13-1 grey--text text--darken-3 font-weight-medium">
            {{ $t('Kurum Adı') }}:
          </span>
          <span
            class="fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
          >
            {{ info.name }}
          </span>
          <img v-if="info.logo_url" class="partner-institution-logo" :src="info.logo_url" :alt="info.name">
        </p>
        <div class="pa-3 mb-1 grey lighten-5">
          <span
            class="fs13-1 grey--text text--darken-3 font-weight-medium d-block"
          >
            {{ $t('İndirim Oranları') }}
          </span>
          <div class="ml-4">
            <div v-if="info.discount_rate_deliverytoaddress">
              <span class="fs13-1 grey--text text--darken-3 font-weight-medium">
                {{ $t('Adrese Teslim') }}:
              </span>
              <span
                class="fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
              >
                {{ `%${info.discount_rate_deliverytoaddress}` }}
              </span>
            </div>
            <div v-if="info.discount_rate_comegetit">
              <span class="fs13-1 grey--text text--darken-3 font-weight-medium">
                {{ $t('Gel Al') }}:
              </span>
              <span
                class="fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
              >
                {{ `%${info.discount_rate_comegetit}` }}
              </span>
            </div>
            <div v-if="info.discount_rate_comeeat">
              <span class="fs13-1 grey--text text--darken-3 font-weight-medium">
                {{ $t('Gel Ye') }}:
              </span>
              <span
                class="fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
              >
                {{ `%${info.discount_rate_comeeat}` }}
              </span>
            </div>
            <div v-if="info.discount_rate_orderonthetable">
              <span class="fs13-1 grey--text text--darken-3 font-weight-medium">
                {{ $t('Masaya Sipariş') }}:
              </span>
              <span
                class="fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
              >
                {{ `%${info.discount_rate_orderonthetable}` }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="
            info.member_point.purchase_point &&
              info.member_point.purchase_point.price &&
              info.member_point.purchase_point.point
          "
          class="pa-3 mb-1 grey lighten-5"
        >
          <div
            class="fs13-1 grey--text text--darken-3 font-weight-medium d-block"
          >
            {{ $t('Sipariş Puanı') }}
          </div>
          <div class="ml-4 d-flex flex-column w-100">
            <div
              class="fs13-1 grey--text text--darken-3 font-weight-medium d-flex flex-column"
            >
              <template
                v-for="(pointName, index) in Object.keys(
                  info.member_point.purchase_point.point
                )"
              >
                <div
                  v-if="info.member_point.purchase_point.point[pointName]"
                  :key="index"
                >
                  {{ $t(pointName) }}:
                  {{
                    $t('Her {price} harcamanızda {point} puan kazanırsınız')
                      .replace(
                        '{price}',
                        $options.filters.currency(
                          info.member_point.purchase_point.price,
                          {
                            symbol: currency
                          }
                        )
                      )
                      .replace(
                        '{point}',
                        info.member_point.purchase_point.point[pointName]
                      )
                  }}
                </div>
              </template>
            </div>
            <div
              v-if="info.member_point.purchase_point.expire_day"
              class="fs13-1 grey--text text--darken-3 font-weight-medium"
              :class="descriptionClass"
            >
              {{
                $t(
                  'Satın alma puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür'
                ).replace('{day}', info.member_point.purchase_point.expire_day)
              }}
            </div>
            <div
              v-if="info.member_point.purchase_point.minimum_total"
              class="fs13-1 grey--text text--darken-3 font-weight-medium"
              :class="descriptionClass"
            >
              {{
                $t(
                  'Siparişinizden puan kazanabilmeniz için, sipariş toplamının en az {minTotal} olmalıdır'
                ).replace(
                  '{minTotal}',
                  $options.filters.currency(
                    info.member_point.purchase_point.minimum_total,
                    {
                      symbol: currency
                    }
                  )
                )
              }}
            </div>
          </div>
        </div>

        <b-button
          class="d-block ml-auto fs13 btn-rezzta mt-4 border-radius-7"
          style="height:32px;"
          @click.prevent="leaveInstutionModal = true"
        >
          {{ $t('Kurumdan Ayrıl') }}
        </b-button>
      </div>
    </template>
    <template v-else>
      <div class="py-2">
        <template>
          <b-form
            class="d-flex flex-row align-end justify-center flex-wrap rounded mb-2 gap-3"
            :class="{'px-4 py-4 grey lighten-5': !isModal}"
            @submit.prevent="handleFormPartnerInstitutionSubmit({ partner_institution_id: partnerInstitutionShowList.selected.id })"
          >
            <b-form-group
              class="mb-0"
              style="flex: 1 1 250px;"
            >
              <span slot="label">
                {{ $t('Üye Profili') }}
                <span v-if="isModal" class="cursor-pointer common-icon p-relative" style="top: -2px;" @click="blogModalHandler('partner-institution-description')" v-html="icons.question"></span>
              </span>

              <b-form-select
                v-model="partnerInstitutionShowList.selected"
                :options="partnerInstitutionShowList.data"
                class="rounded"
                @change="handleChangePartnerInstitution(partnerInstitutionShowList.selected)"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ $t('Üye Profili Seçin') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-button
              v-if="!isModal"
              class="d-flex flex-row align-center justify-center fs13 btn-rezzta border-radius-7"
              style="height:32px;"
              :disabled="!partnerInstitutionShowList.selected || partnerInstitutionShowList.selected.id === partnerInstitutionRequest.partner_institution_id"
              type="submit"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Onayla') }}
            </b-button>
          </b-form>
        </template>
        <b-form
          v-if="isShowCodeArea"
          class="d-flex flex-row align-end justify-center flex-wrap rounded gap-3"
          :class="{'px-4 py-4 grey lighten-5': !isModal}"
          @submit.prevent="handleFormPartnerInstitutionSubmit({code})"
        >
          <b-form-group
            :label="$t('Kurum Kodunu Giriniz')"
            label-for="coupon-code"
            class="mb-0"
            style="flex: 1 1 250px;"
          >
            <b-form-input
              id="coupon-code"
              v-model="code"
              :disabled="loading"
              class="rounded"
              type="text"
              :placeholder="$t('Kurum Kodu')"
            />
          </b-form-group>
          <b-button
            class="d-flex flex-row align-center justify-center fs13 btn-rezzta border-radius-7"
            style="height:32px;"
            :disabled="loading || !code"
            type="submit"
          >
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t('Doğrula') }}
          </b-button>
        </b-form>
      </div>
    </template>

    <div v-if="requiredDocumentInf.isRequired" class="custom-alert custom-alert-danger --outline --icon-hidden mb-2" :class="{ '--small': isModal }">
      <span class="common-icon" v-html="icons.warning"></span>
      <p class="text-center w-100">
        {{ requiredDocumentInf.message }}
      </p>
    </div>

    <div
      class="rounded d-flex flex-row align-end justify-center partner-instituiton-request"
      :class="[
        { 'grey lighten-5 pa-2': !!partnerInstitutionRequest.status && !requiredDocumentInf.isRequired },
        {
          'mt-1 pa-2': requiredDocumentInf.isRequired
        }
      ]"
    >
      <input
        v-show="false"
        ref="partnerInstitutionRequestInputFile"
        type="file"
        :accept="partnerInstitutionRequestInf.accept"
        @change="handleInputFileChange"
      />
      <div
        class="d-flex flex-row align-center justify-center gap-4 w-100"
        :class="{'flex-wrap': !isModal}"
      >
        <template v-if="partnerInstitutionRequest.file_url && !requiredDocumentInf.isRequired">
          <img v-if="extractType(partnerInstitutionRequest.file_url).is('pdf')" style="max-width: 90px; max-height: 80px;" :src="extractType(partnerInstitutionRequest.file_url).default()" class="mr-auto" />
          <img
            v-else
            style="max-width: 110px; max-height: 100px; object-fit: contain;"
            :src="partnerInstitutionRequest.file_url"
            class="mr-auto"
          />
          <div
            v-if="!isModal"
            class="d-flex flex-column align-start justify-start flex-grow-1"
          >
            <span v-if="partnerInstitutionRequest.status" class="mb-1">
              {{ $t('Durum') }}:
              {{
                partnerInstitutionRequest.status === 'pending'
                  ? $t('Bekleniyor')
                  : partnerInstitutionRequest.status === 'rejected'
                  ? $t('Reddedildi')
                  : $t('Onaylandı')
              }}
            </span>
            <span
              v-if="
                partnerInstitutionRequest.status === 'rejected' &&
                  partnerInstitutionRequest.reject_message
              "
              class="px-2 red darken-2 white--text mb-1"
            >
              {{ partnerInstitutionRequest.reject_message }}
            </span>
            <span v-if="partnerInstitutionRequest.name" class="mb-1">
              {{ $t('Kurum Adı') }}:
              {{ partnerInstitutionRequest.name }}
            </span>
            <span v-if="partnerInstitutionRequest.created_at">
              {{ $t('Oluşturma Tarihi') }}:
              {{ $moment(partnerInstitutionRequest.created_at).format('LLL') }}
            </span>
          </div>
        </template>
        <div class="d-flex flex-row align-center justify-center flex-wrap gap-1" style="max-width: 180px;">
          <a
            v-if="!info.id && requiredDocumentInf.isRequired"
            class="btn fs12-1 rezzta-green-3 white--text border-radius-7 py-1 flex-1-1-auto"
            @click="clickHandlePartnerInstitutionFile"
          >
            {{ $t('Belge yükle') }}
          </a>

          <a
            v-if="requiredDocumentInf.isRequired"
            class="btn fs12-1 rezzta-grey-4 white--text border-radius-7 py-1 flex-1-1-auto"
            @click.prevent="handleClickResetRequiredDocumentInf"
          >
            {{ $t('Vazgeç') }}
          </a>

          <a
            v-if="partnerInstitutionRequest.file_url && !requiredDocumentInf.isRequired"
            class="btn fs12-1 rezzta-grey-4 white--text border-radius-7 py-1 flex-1-1-auto"
            @click.prevent="handleClickDeletePartnerInstitutionRequest"
          >
            {{ $t('Belgeyi Sil') }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="!isModal && (partnerInstitutionRequest.status || !info.id)" class="d-flex flex-row align-center justify-end my-1">
      <a
        href="#"
        class="text-underline fs13 font-weight-regular grey--text text--darken-1 mx-2"
        @click.prevent="blogModalHandler('partner-institution-description')"
      >
        {{ $t('Detaylı Bilgi') }}
      </a>
    </div>

    <div v-if="isModal" class="d-flex flex-row align-center justify-end border-top-1px border--rezzta-grey-2 mt-2 pt-3 pb-1">
      <a
        href="#"
        class="fs13 text--rezzta-red text-underline px-0 py-1 font-weight-medium"
        @click.prevent="clickHandlePartnerInstitutionCancel"
      >
        {{ $t('Seçmeden Devam Et') }}
      </a>
      <b-button
        v-if="partnerInstitutionRequest.file_url || isShowButtonForPartnerInstitutionList"
        class="fs13 rezzta-green-3 white--text border-radius-7 py-1 ml-3"
        @click.prevent="clickHandlePartnerInstitutionSave"
      >
        {{ $t('Kaydet') }}
      </b-button>
    </div>

    <b-modal
      v-model="blog.modal"
      size="lg"
      centered
      ok-only
      lazy
      scrollable
      hide-footer
      content-class="custom-modal-1 pa-2"
    >
      <template slot="modal-title"> {{ blog.textTitle }} </template>
      <blogArea
        :slug="blog.blogSlug"
        style="margin-top: -20px"
        :blog-name="blog.name"
        @title="blog.textTitle = $event"
      ></blogArea>
    </b-modal>

    <b-modal
      v-model="partnerInstitutionRequestInf.modal"
      content-class="custom-modal-1"
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      :title="$t('Belge Düzenleme')"
    >
      <div class="px-4 pt-2 pb-4 partner-institution-request-modal">
        <embed :src="partnerInstitutionRequestInf.file_url" :type="partnerInstitutionRequestInf.file_type" width="100%">
        <div class="d-flex flex-row align-center justify-center mt-4">
          <b-button
            :disabled="loading"
            class="d-block fs13 rezzta-red white--text mr-2 border-radius-7"
            @click.prevent="handleClickPartnerInstitutionRequest"
          >
            {{ $t('Onayla') }}
          </b-button>
          <b-button
            :disabled="loading"
            class="btn fs13 grey lighten-2 grey--text text--darken-3 border-radius-7"
            @click.prevent="partnerInstitutionRequestInf.modal = false"
          >
            {{ $t('İptal') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="leaveInstutionModal"
      content-class="custom-modal-1"
      hide-footer
      centered
      :title="$t('Kurumdan Ayrıl')"
    >
      <div class="px-4 pt-2 pb-4">
        <p
          v-if="$store.state.auth.info.member_partner_institution"
          class="fs16-1 grey--text text--darken-3 font-weight-medium text-center mb-1"
        >
          {{
            $t('{name} kurumundan ayrılacaksınız.').replace(
              '{name}',
              $store.state.auth.info.member_partner_institution.name
            )
          }}
        </p>
        <p
          class="fs16-1 grey--text text--darken-3 font-weight-medium text-center mb-1"
        >
          {{ $t('Onaylıyor musunuz?') }}
        </p>
        <b-button
          :disabled="loading"
          class="d-block fs13 mt-4 mx-auto rezzta-red white--text border-radius-7"
          @click.prevent="leaveInstution"
        >
          {{ $t('Onayla') }}
        </b-button>
      </div>
    </b-modal>
  </zz-div>
</template>

<script>
import {
  postMemberPartnerInstitutionRequest,
  getMemberPartnerInstitutionRequest,
  deleteMemberPartnerInstitutionRequest,
  postMemberPartnerInstitution,
  postMemberLeavePartnerInstution,
  getMemberPartnerInstitutionRequestPresignedPutObject
} from '@/api/member'
import { getPartnerInstitutionShowListByCompanyId } from '@/api/partnerInstitution'
import { mapGetters } from 'vuex'
import blogArea from '@/pages/blog/_index'
import { warning, question } from '@/assets/icon/index.js'
import extractType from '@/mixins/extractType'

export default {
  name: 'MeIndexPartnerInstitution',
  components: {
    blogArea
  },
  mixins: [extractType],
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    receiveCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      code: this.receiveCode,
      leaveInstutionModal: false,
      currency: '₺',
      icons: {
        warning,
        question
      },
      partnerInstitutionShowList: {
        data: [],
        selected: null
      },
      partnerInstitutionRequestInf: {
        modal: false,
        file_url: null,
        file_type: null,
        accept: 'image/jpeg,image/png,image/x-png,application/pdf',
        maxSize: 1024
      },
      blog: {
        name: null,
        modal: false,
        blogSlug: null,
        textTitle: null
      },
      partnerInstitutionRequest: {
        file_url: null,
        status: null,
        partner_institution_id: null,
        reject_message: null,
        name: null,
        created_at: null
      },
      defaultPartnerInstitutionRequest: {
        file_url: null,
        status: null,
        partner_institution_id: null,
        reject_message: null,
        name: null,
        created_at: null
      },
      info: {
        id: null,
        name: null,
        discount_rate_deliverytoaddress: null,
        discount_rate_comegetit: null,
        discount_rate_comeeat: null,
        discount_rate_orderonthetable: null,
        member_point: {
          purchase_point: {
            point: {
              comeEat: null,
              comeGetIt: null,
              orderOnTheTable: null,
              deliveryToAddress: null
            },
            price: null,
            expire_day: null,
            minimum_total: null
          }
        }
      },
      defaultInfo: {
        id: null,
        name: null,
        discount_rate_deliverytoaddress: null,
        discount_rate_comegetit: null,
        discount_rate_comeeat: null,
        discount_rate_orderonthetable: null,
        member_point: {
          purchase_point: {
            point: {
              comeEat: null,
              comeGetIt: null,
              orderOnTheTable: null,
              deliveryToAddress: null
            },
            price: null,
            expire_day: null,
            minimum_total: null
          }
        }
      },
      requiredDocumentInf: {
        isRequired: false,
        message: null,
        documentTitle: null,
        partnerInstitutionId: null
      },
      defaultRequiredDocumentInf: {
        isRequired: false,
        message: null,
        documentTitle: null,
        partnerInstitutionId: null
      }
    }
  },
  computed: {
    ...mapGetters({
      getBlogAreaSlug: 'getBlogAreaSlug'
    }),
    isShowCodeArea() {
      return this.partnerInstitutionShowList.selected === 0
    },
    isShowButtonForPartnerInstitutionList() {
      const selected = this.partnerInstitutionShowList.selected

      return !!selected && !selected.is_document_required
    }
  },
  watch: {
    '$store.state.auth.info': {
      immediate: true,
      deep: true,
      handler(v) {
        Object.assign(
          this.info,
          v.member_partner_institution || this.defaultInfo
        )
      }
    },
    'partnerInstitutionRequestInf.modal'(v) {
      if (!v) {
        this.partnerInstitutionRequestInf.file_url = this.partnerInstitutionRequest.file_url
      }
    }
  },
  mounted() {
    if (this.code) {
      this.handleFormPartnerInstitutionSubmit({ code: this.code })
    }

    this.getPartnerInstitutionRequest()
    this.getPartnerInstitutionShowList()
    this.$store.dispatch('auth/getInfo')
  },
  methods: {
    clickHandlePartnerInstitutionCancel() {
      if (this.partnerInstitutionRequest.file_url) {
        this.handleClickDeletePartnerInstitutionRequest()
      }

      this.$emit('close')
    },
    clickHandlePartnerInstitutionSave() {
      if (this.isShowButtonForPartnerInstitutionList && this.partnerInstitutionShowList.selected?.id > 0) {
        this.handleFormPartnerInstitutionSubmit({ partner_institution_id: this.partnerInstitutionShowList.selected.id })
      }

      this.$emit('close')
    },
    handleChangePartnerInstitution(selected) {
      if (selected === 0 || (typeof selected?.is_document_required !== 'undefined' && !selected.is_document_required) || selected?.id === this.partnerInstitutionRequest.partner_institution_id) {
        this.resetRequiredDocumentInf()
      }

      if (selected?.id > 0 && selected.is_document_required && this.isModal) {
        this.handleFormPartnerInstitutionSubmit({ partner_institution_id: selected.id })
      }
    },
    handleClickResetRequiredDocumentInf() {
      this.setSelectedByRequest()
      this.resetRequiredDocumentInf()
    },
    resetRequiredDocumentInf() {
      Object.assign(this.requiredDocumentInf, this.defaultRequiredDocumentInf)
    },
    clickHandlePartnerInstitutionFile() {
      this.$refs.partnerInstitutionRequestInputFile.click()
    },
    async leaveInstution() {
      try {
        this.loading = true

        const params = {}

        params.memberId = +this.$store.state.auth.info.id

        const result = await this.$axios.request({
          ...postMemberLeavePartnerInstution(params)
        })

        this.$notification.success(result.data)
        this.leaveInstutionModal = false
        await this.$store.dispatch('auth/getInfo')
        await this.getPartnerInstitutionRequest()
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    async handleFormPartnerInstitutionSubmit(data = {}) {
      try {
        this.loading = true

        const params = {}

        params.memberId = +this.$store.state.auth.info.id

        const result = await this.$axios.request({
          ...postMemberPartnerInstitution(params),
          data
        })

        if (result.data.isOK) {
          this.$emit('close')

          if (!this.isModal) {
            this.$notification.success(result.data.message)
          }

          this.code = null
          this.partnerInstitutionShowList.selected = null
          this.resetRequiredDocumentInf()
          await this.$store.dispatch('auth/getInfo')
        } else {
          if (this.partnerInstitutionRequest?.partner_institution_id !== result.data.partner_institution_id) {
            this.requiredDocumentInf.isRequired = true
            this.requiredDocumentInf.message = result.data.message
            this.requiredDocumentInf.documentTitle = result.data.document_title
            this.requiredDocumentInf.partnerInstitutionId = result.data.partner_institution_id
          }

          this.setSelected({ id: result.data.partner_institution_id})
        }
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    setSelectedByRequest() {
      const requestPartnerInstitutionId = this.partnerInstitutionRequest?.partner_institution_id

      this.setSelected({ id: requestPartnerInstitutionId, defaultVal: null })
    },
    setSelected({ id, defaultVal = 0 }) {
      if (this.partnerInstitutionShowList.selected !== 0) {
        const findSelect = !!id && this.partnerInstitutionShowList.data.find(x => x.value?.id === id)

        if (findSelect) {
          this.partnerInstitutionShowList.selected = findSelect.value
        } else {
          this.partnerInstitutionShowList.selected = this.partnerInstitutionShowList.data?.length ? defaultVal : 0
        }
      }
    },
    async handleClickDeletePartnerInstitutionRequest() {
      try {
        const params = {}

        params.memberId = +this.$store.state.auth.info.id

        const result = await this.$axios.request({
          ...deleteMemberPartnerInstitutionRequest(params)
        })

        if (!this.isModal) {
          this.$notification.success(result.data)
        }

        this.setSelected({ id: null, defaultVal: null })

        Object.assign(this.partnerInstitutionRequest, this.defaultPartnerInstitutionRequest)
      } catch (err) {
        this.$notification.error(err.message)
      }
    },
    handleInputFileChange(e) {
      const file = e.target.files[0]
      if (!file) return
    
      this.partnerInstitutionRequestInf.file_type = file.type
      this.partnerInstitutionRequestInf.file_url = URL.createObjectURL(file)
      this.partnerInstitutionRequestInf.modal = true
    },
    typeCheck(type) {
      const permTypes = this.partnerInstitutionRequestInf.accept
        .trim()
        .split(/\s*,\s*/)

      return permTypes.includes(type)
    },
    sizeCheck(size) {
      return size / 1024 <= this.partnerInstitutionRequestInf.maxSize
    },
    async imageUpload(payload) {
      const file = payload

      try {
        if (!this.typeCheck(file.type)) {
          throw new Error(
            this.$t('Yalnızca {types} tipleri yüklenebilir.').replace(
              '{types}',
              this.partnerInstitutionRequestInf.accept
            )
          )
        }

        if (!this.sizeCheck(file.size)) {
          throw new Error(
            this.$t("{size} kb'den büyük dosya yüklenemez.").replace(
              '{size}',
              this.partnerInstitutionRequestInf.maxSize
            )
          )
        }

        const params = {}

        params.memberId = this.$store.state.auth.info.id

        const result = await this.$axios.request({
          ...getMemberPartnerInstitutionRequestPresignedPutObject(params),
          params: {
            mime_type: file.type
          }
        })

        const action = result.data.url
        
        this.partnerInstitutionRequest.file_url = result.data.fileName
        ajax({
          action,
          file,
          onProgress: () => Promise.resolve(),
          onError: err => this.$notification.error(err.message),
          onSuccess: () => this.submitMemberPartnerInstitutionRequest()
        })
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    async submitMemberPartnerInstitutionRequest() {
      try {
        const params = {}

        params.memberId = +this.$store.state.auth.info.id

        const result = await this.$axios.request({
          ...postMemberPartnerInstitutionRequest(params),
          data: {
            file_url: this.partnerInstitutionRequest.file_url,
            partner_institution_id: this.requiredDocumentInf.partnerInstitutionId || this.partnerInstitutionRequest.partner_institution_id || null
          }
        })

        if (!this.isModal) {
          this.$notification.success(result.data)
        }

        await this.getPartnerInstitutionRequest()
        this.resetRequiredDocumentInf()
        this.partnerInstitutionRequestInf.modal = false
        this.partnerInstitutionRequestInf.file_url = null
      } catch (err) {
        this.$notification.error(err.message)
      }
    },
    async handleClickPartnerInstitutionRequest() {
      try {
        const file = this.$refs.partnerInstitutionRequestInputFile.files[0]

        this.loading = true

        await this.imageUpload(file)
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    async getPartnerInstitutionRequest() {
      try {
        const params = {}

        params.memberId = +this.$store.state.auth.info.id

        const result = await this.$axios.request({
          ...getMemberPartnerInstitutionRequest(params)
        })

        Object.assign(this.partnerInstitutionRequest, result.data || this.defaultPartnerInstitutionRequest)
      } catch (err) {
        Object.assign(this.partnerInstitutionRequest, this.defaultPartnerInstitutionRequest)
      }
    },
    async getPartnerInstitutionShowList() {
      try {
        const result = await this.$axios.request({
          ...getPartnerInstitutionShowListByCompanyId({ companyId: this.$store.state.company?.id })
        })

        this.partnerInstitutionShowList.data = result.data.map(i => ({
          text: i.list_name,
          value: {
            id: i.id,
            is_document_required: i.is_document_required
          }
        }))

        this.partnerInstitutionShowList.data.push({
          text: this.$t('Kurum Kodu Gir'),
          value: 0
        })

        if (!result.data.length) 
          this.partnerInstitutionShowList.selected = 0
      } catch (err) {
        this.$notification.error(err.message)
      }
    },
    blogModalHandler(name) {
      try {
        this.blog.name = name
        this.blog.blogSlug = this.getBlogAreaSlug(name)
        this.blog.modal = true
      } catch (err) {
        this.$notification.error(
          this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      }
    }
  }
}

function getError(action, option, xhr) {
  let msg

  if (xhr.response) {
    msg = `${xhr.response.error || xhr.response}`
  } else if (xhr.responseText) {
    msg = `${xhr.responseText}`
  } else {
    msg = `fail to post ${action} ${xhr.status}`
  }

  const err = new Error(msg)

  err.status = xhr.status
  err.method = 'post'
  err.url = action

  return err
}

function getBody(xhr) {
  const text = xhr.responseText || xhr.response

  if (!text) {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}

// eslint-disable-next-line no-unused-vars
function ajax(option) {
  if (typeof XMLHttpRequest === 'undefined') {
    return
  }

  Object.assign(
    {
      onProgress: () => Promise.resolve(),
      onError: () => Promise.resolve(),
      onSuccess: () => Promise.resolve()
    },
    option
  )

  const xhr = new XMLHttpRequest()
  const action = option.action

  if (xhr.upload) {
    xhr.upload.onprogress = function progress(e) {
      if (e.total > 0) {
        e.percent = (e.loaded / e.total) * 100
      }

      option.onProgress(e)
    }
  }

  xhr.onerror = function error(e) {
    option.onError(e)
  }

  xhr.onload = function onload() {
    if (xhr.status < 200 || xhr.status >= 300) {
      return option.onError(getError(action, option, xhr))
    }

    option.onSuccess(getBody(xhr))
  }

  xhr.open('put', action, true)

  xhr.send(option.file)

  return xhr
}
</script>

<style lang="scss" scoped>
.partner-institution-request-modal {
  embed {
    max-height: 420px;
    max-width: 100%;
    &[type='application/pdf'] {
      height: 320px;
    }
  }
}
.partner-institution-logo {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: calc(100% - 8px);
    max-height: 100%;
    max-width: 100%;
  }
</style>
