<template>
  <div
    class="restaurant-list-item"
    :class="{ 'restaurant-list-item-disabled': !item.is_open }"
  >
    <button
      v-if="$store.state.auth.isLogged && showFavorite"
      v-b-tooltip.hover.left
      class="restaurant-list-item-favorite"
      type="button"
      :title="
        $t(item.is_favorite ? 'Favorilerimden kaldır' : 'Favorilerime ekle')
      "
      @click.stop="handleClickFavorite(item)"
    >
      <i>
        <fa v-if="item.is_favorite" icon="heart"></fa>
        <fa v-else :icon="['far', 'heart']"></fa>
      </i>
      <span> </span>
    </button>
    <div class="restaurant-list-item-delivery-type-w">
      <span
        v-for="(dt, index) in item.delivery_types"
        :key="index"
        :title="dt.title"
        class="rlidtw-item"
        :class="[
          { 'open-restaurant-icon': dt.is_open },
          { 'close-restaurant-icon': !dt.is_open },
          {
            'select-restaurant-icon':
              item.deliveryType && dt.id === item.deliveryType.id
          }
        ]"
        @click="handleClickDeliveryType(dt)"
      >
        <span class="rlidtwi-icon" v-html="dt.icon" />
      </span>
      <!-- <span
        :title="$t('Çalışma Saatleri')"
        class="restaurant-list-item-right-top-r-clock"
      >
        <fa :icon="['far', 'clock']"></fa>
        {{ item.today.open_time }}
        -
        {{ item.today.close_time }}
      </span> -->
    </div>
    <nuxt-link
      :title="item.name"
      :to="
        localePath({
          name: 'restaurant-branchSlug',
          params: {
            branchSlug: item.slug
          }
        })
      "
    >
      <div class="restaurant-list-item-right">
        <div class="restaurant-list-item-right-top">
          <div class="restaurant-list-item-img">
            <img
              onerror="this.src='/images/image80x80.png'"
              :src="item.image"
              :alt="item.name"
            />
          </div>
          <div class="restaurant-list-item-right-top-content">
            <div class="restaurant-list-item-right-top-l">
              <div class="restaurant-list-item-right-top-l-t">
                <span class="restaurant-list-item-title">
                  {{ item.name }}
                </span>
                <span class="restaurant-list-item-kitchen">
                  {{ item.kitchen }}
                </span>
              </div>
              <div class="restaurant-list-item-right-top-l-b">
                <div class="restaurant-list-item-right-top-l-b-l">
                  <span
                    v-if="item.points && item.points.avg"
                    class="restaurant-item-star restaurant-list-item-right-top-l-b-item"
                    :class="`badge star-bg-${item.points.avg * 10}`"
                  >
                    <fa icon="star"></fa>
                    <small>
                      {{ item.points.avg }}
                    </small>
                  </span>
                  <small
                    v-if="item.is_recommend"
                    class="restaurant-item-recommend restaurant-list-item-right-top-l-b-item"
                  >
                    {{ $t('Kazandıran') }}
                  </small>
                  <span
                    v-if="!item.is_open"
                    class="restaurant-list-item-close restaurant-list-item-right-top-l-b-item"
                  >
                    {{ $t('Kapalı') }}
                  </span>
                  <img
                    v-for="(icon, iconIndex) in item.icons"
                    :key="`icon${iconIndex}`"
                    onerror="this.src='/images/image30x30.png'"
                    class="restaurant-list-item-icon restaurant-list-item-right-top-l-b-item"
                    :src="icon.url"
                    alt="rezzta"
                  />
                </div>
                <div
                  v-if="item.deliveryType"
                  class="restaurant-list-item-right-top-l-b-r"
                >
                  <small
                    class="restaurant-list-item-right-top-l-r-item"
                    :title="$t('Minumum Sipariş Tutarı')"
                  >
                    <fa icon="shopping-basket"></fa>
                    {{ $t('min.') }}
                    {{
                      item.deliveryType.price | currency({ symbol: currency })
                    }}
                  </small>
                  <span
                    :title="$t('Ortalama Teslimat Süresi')"
                    class="restaurant-list-item-right-top-l-r-item"
                  >
                    <span v-html="item.deliveryType.icon"></span>
                    <!-- <fa icon="shipping-fast"></fa> -->
                    {{
                      $t('{min}-{max} dk')
                        .replace(
                          '{min}',
                          item.deliveryType.minimum_delivery_time
                        )
                        .replace(
                          '{max}',
                          item.deliveryType.maximum_delivery_time
                        )
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            item.is_busy ||
              (item.deliveryType &&
                item.deliveryType.transportation_fee &&
                item.deliveryType.transportation_fee > 0)
          "
          class="restaurant-list-item-right-bottom"
        >
          <div class="restaurant-list-item-right-bottom-l">
            <small
              v-if="item.is_busy"
              class="restaurant-list-item-right-bottom-item"
            >
              {{ $t('Yoğunluk Yaşanıyor') }}
            </small>
            <small
              v-if="
                item.deliveryType &&
                  item.deliveryType.transportation_fee &&
                  item.deliveryType.transportation_fee > 0
              "
              class="restaurant-list-item-right-bottom-item"
              :title="$t('Taşıma Ücreti')"
            >
              <fa icon="motorcycle"></fa>
              {{
                item.deliveryType.transportation_fee
                  | currency({ symbol: currency })
              }}
            </small>
          </div>
          <div class="restaurant-list-item-right-bottom-r"></div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import {
  postMemberFavoriteRestaurant,
  deleteMemberFavoriteRestaurant
} from '@/api/favorite'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    },
    showFavorite: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    currency() {
      return this.item?.currency?.symbol || '₺'
    },
    deliveryType() {
      return this.item.deliveryType
    }
  },
  methods: {
    // Restoran favorilerime ekle
    async handleClickFavorite(item) {
      const isFavorite = item.is_favorite

      item.is_favorite = !item.is_favorite

      try {
        const params = {
          memberId: this.$store.state.auth.info.id,
          restaurantId: item.id
        }

        if (isFavorite) {
          await this.$axios.request(deleteMemberFavoriteRestaurant(params))
        } else {
          await this.$axios.request(postMemberFavoriteRestaurant(params))
        }
      } catch (err) {
        item.is_favorite = !item.is_favorite
        this.$notification.error(err.message)
      }
    },
    handleClickDeliveryType(dt) {
      this.item.deliveryType = dt
    }
  }
}
</script>
