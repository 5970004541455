var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "client-only",
        [
          _c(
            "cookie-law",
            {
              attrs: { "button-text": _vm.$t("Tamam"), theme: "custom-cookie" }
            },
            [
              _c(
                "div",
                { attrs: { slot: "message" }, slot: "message" },
                [
                  _c(
                    "i18n",
                    {
                      staticClass: "text-underline",
                      attrs: {
                        path: "cookie_policy_read_and_aggre_tos",
                        tag: "span"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.lawModal("cookie")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Çerez Politikamızı")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.lawModal("privacy")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Gizlilik Politikamızı")) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            centered: "",
            "content-class": "custom-modal-1",
            lazy: "",
            "ok-only": "",
            "ok-title": _vm.$t("Kapat")
          },
          model: {
            value: _vm.law.modal,
            callback: function($$v) {
              _vm.$set(_vm.law, "modal", $$v)
            },
            expression: "law.modal"
          }
        },
        [
          _c("blogArea", {
            staticStyle: { "margin-top": "-20px" },
            attrs: { slug: _vm.law.blogSlug }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }