<template>
  <div>
    <section class="footer pt-5 pb-5">
      <div class="container">
        <div v-if="$store.state.company.settings" class="row">
          <div class="col-md-4 col-12 col-sm-12 mb-3 text-center text-md-left">
            <div
              v-if="
                $store.state.company.settings.play_store ||
                  $store.state.company.settings.app_store
              "
              class="app"
            >
              <p class="mb-2">
                {{ $t('Mobil Uygulamalarımız') }}
              </p>
              <a
                v-if="$store.state.company.settings.app_store"
                :href="$store.state.company.settings.app_store"
                target="_blank"
              >
                <img class="img-fluid" src="/images/apple.png" />
              </a>
              <a
                v-if="$store.state.company.settings.play_store"
                :href="$store.state.company.settings.play_store"
                target="_blank"
              >
                <img class="img-fluid" src="/images/google.png" />
              </a>
            </div>
            <div class="footer-language">
              <div class="footer-language-icon">
                <span
                  class="common-icon"
                  v-html="icons.language"
                ></span>
              </div>
              <div class="footer-language-items">
                <nuxt-link
                  v-if="$i18n.locale !== 'en'"
                  :to="switchLocalePath('en')"
                >
                  EN
                </nuxt-link>
                <nuxt-link
                  v-if="$i18n.locale !== 'tr'"
                  :to="switchLocalePath('tr')"
                >
                  TR
                </nuxt-link>
                <nuxt-link
                  v-if="$i18n.locale !== 'de'"
                  :to="switchLocalePath('de')"
                >
                  DE
                </nuxt-link>
              </div>
            </div>
            <div class="footer-social">
              <a
                v-if="$store.state.company.settings.facebook"
                :href="$store.state.company.settings.facebook"
                target="_blank"
              >
                <fa :icon="['fab', 'facebook']"></fa>
              </a>
              <a
                v-if="$store.state.company.settings.instagram"
                :href="$store.state.company.settings.instagram"
                target="_blank"
              >
                <fa :icon="['fab', 'instagram']"></fa>
              </a>
              <a
                v-if="$store.state.company.settings.youtube"
                :href="$store.state.company.settings.youtube"
                target="_blank"
              >
                <fa :icon="['fab', 'youtube']"></fa>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-12 col-sm-6 mb-3">
            <ul>
              <template v-if="Object.keys(domainBlogs)">
                <template v-for="(blog, index) in Object.keys(domainBlogs)">
                  <li v-if="domainBlogs[blog].is_footer" :key="index">
                    <nuxt-link
                      :to="
                        localePath({
                          name: 'blog',
                          params: {
                            index: domainBlogs[blog].name
                          }
                        })
                      "
                    >
                      {{ domainBlogs[blog].title }}
                    </nuxt-link>
                  </li>
                </template>
              </template>
            </ul>
          </div>
          <div class="col-md-5 col-12 col-sm-12 mb-3 footer-contact">
            <ul v-if="contact">
              <li v-if="contact.email">
                <span>
                  <fa icon="envelope"></fa>
                </span>
                <a :href="'mailto:' + contact.email" class="text-dark">
                  {{ contact.email }}
                </a>
              </li>
              <li v-if="contact.phone">
                <span>
                  <fa icon="phone"></fa>
                </span>
                <a :href="'tel:' + contact.phone" class="text-dark">
                  {{ contact.phone }}
                </a>
              </li>
              <li v-if="contact.address">
                <span>
                  <fa icon="map-marker-alt"></fa>
                </span>
                {{ contact.address }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="pt-4 pb-4 text-center">
      <div class="container">
        <p class="mt-0 mb-0">© Copyright {{ new Date().getFullYear() }}.</p>
        <small class="mt-0 mb-0">
          <a
            class="text-danger"
            target="_blank"
            :href="
              `https://www.myrezzta.com${$i18n.locale === 'tr' ? '' : '/en'}`
            "
          >
            AKINSOFT MyRezzta
          </a>
          {{ $t('altyapısı kullanılmıştır.') }}
        </small>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCityAll } from '@/api/city'
import { language } from '@/assets/icon/index.js'

export default {
  name: 'DomainFooter',
  data() {
    return {
      activeCities: [],
      soonCities: [],
      addresses: {
        loading: false,
        items: [],
        selected: {},
        formModal: false
      },
      contact: {
        phone: null,
        address: null,
        email: null
      },
      icons: {
        language
      }
    }
  },
  computed: {
    ...mapGetters({
      domainBlogs: 'getCompanyBlog'
    }),
    branchSlug() {
      return this.$cookies.get('domainUrl')
    }
  },
  watch: {
    branchSlug: {
      immediate: true,
      handler(slug) {
        if (this.$store.state.company?.settings) {
          this.contact.phone = this.$store.state.company.settings.phone
          this.contact.address = this.$store.state.company.settings.address
          this.contact.email = this.$store.state.company.settings.email
        }

        if (slug) {
          const findBranch = this.$store.state.company.branch.find(
            b => b.slug === slug
          )

          if (findBranch) {
            this.contact.phone = findBranch.telephone
            this.contact.address = findBranch.address
          }
        }
      }
    }
  },
  methods: {
    async getActiveCity() {
      const params = {}

      params.is_active = true

      try {
        const result = await this.$axios.request({
          ...getCityAll(),
          params
        })

        this.activeCities = result.data
      } catch (err) {}
    },
    async getSoonCity() {
      const params = {}

      params.is_active = false
      params.is_home = true

      try {
        const result = await this.$axios.request({
          ...getCityAll(),
          params
        })

        this.soonCities = result.data
      } catch (err) {}
    }
  }
}
</script>
