import { render, staticRenderFns } from "./pointInfo.vue?vue&type=template&id=6db3c116&"
import script from "./pointInfo.vue?vue&type=script&lang=js&"
export * from "./pointInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6db3c116')) {
      api.createRecord('6db3c116', component.options)
    } else {
      api.reload('6db3c116', component.options)
    }
    module.hot.accept("./pointInfo.vue?vue&type=template&id=6db3c116&", function () {
      api.rerender('6db3c116', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/common/pointInfo.vue"
export default component.exports