<template>
  <zz-div :loading="loading">
    <div class="row">
      <div class="col-md-12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="form-material"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('E-Posta Adresiniz')"
            >
              <div class="form-label-group">
                <input
                  id="inputEmail"
                  v-model="email"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('E-Posta Adresiniz')"
                  :disabled="loading"
                  type="email"
                  class="form-control"
                  required
                />
                <span class="common-icon icon-style-1" v-html="icons.at"></span>
                <label for="inputEmail">
                  {{ $t('E-Posta Adresiniz') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>

            <button
              :disabled="loading"
              class="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 rezzta-red white--text"
              type="submit"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Gönder') }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </zz-div>
</template>

<script>
import { postMemberForgetPassword } from '@/api/member'
import { at } from '@/assets/icon/index.js'

export default {
  name: 'AuthForgotPassword',
  data() {
    return {
      email: '',
      loading: false,
      icons: {
        at
      }
    }
  },
  methods: {
    async handleFormSubmit() {
      this.loading = true

      try {
        const result = await this.$axios.request({
          ...postMemberForgetPassword(),
          data: { email: this.email }
        })

        this.$notification.success(result.data)
        this.$emit('success')
      } catch (error) {
        this.$emit('fail', error)

        if (error.message) {
          this.$notification.error(
            error.message ||
              this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
          )
        } else {
          this.$notification.error(
            this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
          )
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
