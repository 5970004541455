var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header domain-header" },
    [
      _c("smartAppBanner", { attrs: { data: _vm.smartAppBannerData } }),
      _vm._v(" "),
      _c("div", { staticClass: "location-bar bg-white d-none d-sm-block" }, [
        _c("div", { staticClass: "container py-1" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$store.dispatch("auth/showLocationModal")
                }
              }
            },
            [
              _c("span", {
                staticClass: "common-icon",
                domProps: { innerHTML: _vm._s(_vm.icons.location1) }
              }),
              _vm._v(" "),
              _c("b", [
                _vm._v(
                  "\n          " + _vm._s(_vm.getShortAddress()) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "down-custom-icon" })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-navbar",
        { staticClass: "header-nav shadow-sm", attrs: { toggleable: "lg" } },
        [
          _c(
            "div",
            { staticClass: "container p-relative" },
            [
              _c("b-navbar-brand", { attrs: { to: _vm.localePath("index") } }, [
                _c("img", {
                  staticStyle: { "max-height": "38px" },
                  attrs: { alt: "logo", src: _vm.logo }
                })
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "common-icon map-mobil",
                  on: {
                    click: function($event) {
                      return _vm.$store.dispatch("auth/showLocationModal")
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { "enable-background": "new 0 0 512 512" },
                      attrs: {
                        id: "Capa_1",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 512 512",
                        "xml:space": "preserve"
                      }
                    },
                    [
                      _c("path", {
                        staticClass: "st0",
                        attrs: {
                          d:
                            "M256,61.53c-76.57,0-138.64,62.07-138.64,138.64S256,450.47,256,450.47s138.64-173.73,138.64-250.3\n                  S332.57,61.53,256,61.53z M256,264.25c-38.82,0-70.28-31.47-70.28-70.28c0-38.82,31.47-70.28,70.28-70.28s70.28,31.47,70.28,70.28\n                  C326.28,232.79,294.82,264.25,256,264.25z"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("small", { staticClass: "map-icon-wrapper" })
                ]
              ),
              _vm._v(" "),
              _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }, [
                _c("span", { staticClass: "navbar-toggler-icon" }, [
                  _c("span"),
                  _vm._v(" "),
                  _c("span"),
                  _vm._v(" "),
                  _c("span")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.$store.state.auth.notificationUnreadCount > 0,
                        expression:
                          "$store.state.auth.notificationUnreadCount > 0"
                      }
                    ],
                    staticClass: "badge badge-success menu-w-badge"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$store.state.auth.notificationUnreadCount) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { id: "nav-collapse", "is-nav": "" } },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c(
                        "b-nav-item",
                        {
                          staticStyle: { display: "none" },
                          attrs: { to: _vm.localePath("index") }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Anasayfa")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$store.state.auth.isLogged
                        ? [
                            _c(
                              "b-nav-item-dropdown",
                              {
                                staticClass: "profil-h-w",
                                attrs: {
                                  "menu-class": "shadow-sm border-0",
                                  right: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c("span", {
                                            staticClass: "common-icon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.icons.profil1
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$t("Profil")) +
                                                "\n                "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.$store.state.auth
                                                      .notificationUnreadCount >
                                                    0,
                                                  expression:
                                                    "$store.state.auth.notificationUnreadCount > 0"
                                                }
                                              ],
                                              staticClass:
                                                "badge badge-success custom-h-badge"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$store.state.auth
                                                      .notificationUnreadCount
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1350872643
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("div", { staticClass: "profil-menu-head" }, [
                                  _c("img", {
                                    staticClass: "nav-osahan-pic rounded-pill",
                                    attrs: {
                                      src:
                                        _vm.$store.state.auth.info
                                          .profile_image,
                                      onerror: "this.style.display='none'"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$store.state.auth.info.name
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.domainUrl &&
                                !(
                                  _vm.$route.params &&
                                  _vm.$route.params.branchSlug
                                )
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          to: _vm.localePath({
                                            name: "restaurant-branchSlug",
                                            params: {
                                              branchSlug: _vm.domainUrl
                                            }
                                          })
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "common-icon",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.icons.undo)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("Menüye Dön")) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  { attrs: { to: _vm.localePath("me") } },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.profil2)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Profilim")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { to: _vm.localePath("me-orders") }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.order)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Siparişlerim")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      to: _vm.localePath("me-addresses")
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.address)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Adreslerim")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      to: _vm.localePath("me-notifications")
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.icons.notification
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Bildirimlerim")) +
                                          "\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.$store.state.auth
                                                .notificationUnreadCount > 0,
                                            expression:
                                              "$store.state.auth.notificationUnreadCount > 0"
                                          }
                                        ],
                                        staticClass: "badge badge-success"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$store.state.auth
                                                .notificationUnreadCount
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { to: _vm.localePath("me-wallet") }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.wallet)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Cüzdanım")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  { attrs: { to: _vm.localePath("me-point") } },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.reward1)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Puanlarım")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      to: _vm.localePath("me-giftCards")
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.giftCard)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Hediye Çeklerim")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.handleLogout } },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.exit)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Çıkış Yap")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : [
                            _c(
                              "b-nav-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$store.dispatch(
                                      "auth/showLoginModal"
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "common-icon",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.icons.login)
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("Giriş Yap")) +
                                      "\n              "
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "b-nav-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.$store.dispatch(
                                          "auth/showRegisterModal"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.register)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("Kayıt Ol")) +
                                          "\n              "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                      _vm._v(" "),
                      _vm.$store.state.auth.siteType === "restaurant"
                        ? _c("basket-item")
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }