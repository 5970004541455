<template>
  <section class="section pt-5 pb-5 error-page">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center pt-5 pb-5">
          <component :is="errorPage" :error="error" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import errorDomainLicense from '~/components/error/domainLicense.vue'
import error404 from '~/components/error/404.vue'
import error500 from '~/components/error/500.vue'

export default {
  name: 'NuxtError',
  layout: ({ store }) => {
    if (store.state.isBaseDomain) {
      // return 'notRequiredLocation'
      return '_base'
    }

    return 'empty'
  }, // optional
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    errorPage() {
      if (this.error.message === 'ErrorDomainLicense') {
        return errorDomainLicense
      }

      if (this.error.statusCode === 404) {
        return error404
      }

      // catch everything else
      return error500
    }
  }
}
</script>
