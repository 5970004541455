var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { class: "smartbanner smartbanner-" + _vm.osName }, [
        _c("div", { staticClass: "smartbanner-container" }, [
          _c(
            "a",
            {
              staticClass: "smartbanner-close",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleClick($event)
                }
              }
            },
            [_vm._v("\n      ×\n    ")]
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "smartbanner-icon",
            style: { "background-image": "url(" + _vm.icon + ")" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "smartbanner-info" }, [
            _c("div", { staticClass: "smartbanner-title" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
            ])
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "smartbanner-button",
              attrs: { href: _vm.downloadUrl }
            },
            [
              _c("span", { staticClass: "smartbanner-button-text" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("İNDİR")) + "\n      ")
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }