<template>
  <div class="w-100 mb-1">
    <div v-if="showTotalPoint" class="mb-3">
      <h4>
        {{
          $t('Puan Bakiyeniz: {amount}').replace('{amount}', totalPoint)
        }}
      </h4>
    </div>
    <div class="w-100">
      <div v-if="isActive && parsePoint" :class="`${wrapperClass} d-flex flex-column align-start justify-center w-100`">
        <div v-if="!hiddenTopTitle && topTitle" class="font-weight-bold mb-2 fs14-1 d-flex align-center">
          <span class="common-icon mr-2" v-html="icons.warning"></span>
          {{ $t(topTitle) }}
        </div>
        <template v-if="parsePoint.earn.is_active">
          <template v-if="parsePoint.earn.purchase_point.show">
            <span v-if="!hiddenTitle" class="font-weight-bold fs11-1">
              {{ $t('Sipariş Puanı') }}:
            </span>
            <div class="ml-2 mb-1 d-flex flex-column w-100">
              <span
                v-if="
                  parsePoint.earn.purchase_point.price &&
                    parsePoint.earn.purchase_point.point
                "
                class="font-weight-medium fs11-1 d-flex flex-column ml-2"
                :class="descriptionClass"
              >
                <template
                  v-for="(pointName, index) in Object.keys(
                    parsePoint.earn.purchase_point.point
                  )"
                >
                  <span
                    v-if="parsePoint.earn.purchase_point.point[pointName]"
                    :key="index"
                  >
                    {{ $t(pointName) }}:
                    {{
                      $t('Her {price} harcamanızda {point} puan kazanırsınız')
                        .replace(
                          '{price}',
                          $options.filters.currency(
                            parsePoint.earn.purchase_point.price,
                            {
                              symbol: currency
                            }
                          )
                        )
                        .replace(
                          '{point}',
                          parsePoint.earn.purchase_point.point[pointName]
                        )
                    }}
                  </span>
                </template>
              </span>
              <span v-else :class="descriptionClass">
                {{ $t('Restoranda verdiğiniz siparişten puan kazanamazsınız') }}
              </span>
              <span
                v-if="parsePoint.earn.purchase_point.expire_day"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Satın alma puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür'
                  ).replace('{day}', parsePoint.earn.purchase_point.expire_day)
                }}
              </span>
              <span
                v-if="parsePoint.earn.purchase_point.minimum_total"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Siparişinizden puan kazanabilmeniz için, sipariş toplamının en az {minTotal} olmalıdır'
                  ).replace(
                    '{minTotal}',
                    $options.filters.currency(
                      parsePoint.earn.purchase_point.minimum_total,
                      {
                        symbol: currency
                      }
                    )
                  )
                }}
              </span>
            </div>
          </template>

          <template v-if="parsePoint.earn.wallet_point.show">
            <span v-if="!hiddenTitle" class="font-weight-bold fs11-1">
              {{ $t('Cüzdan Puanı') }}:
            </span>
            <div class="ml-2 mb-1 d-flex flex-column w-100">
              <span
                v-if="
                  parsePoint.earn.wallet_point.price &&
                    parsePoint.earn.wallet_point.point
                "
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Tek seferde her {price} cüzdan yüklemenizde {point} puan kazanırsınız'
                  )
                    .replace(
                      '{price}',
                      $options.filters.currency(
                        parsePoint.earn.wallet_point.price,
                        {
                          symbol: currency
                        }
                      )
                    )
                    .replace('{point}', parsePoint.earn.wallet_point.point)
                }}
              </span>
              <span v-else :class="descriptionClass">
                {{ $t('Restoranda cüzdan yüklemesinden puan kazanamazsınız') }}
              </span>
              <span
                v-if="parsePoint.earn.wallet_point.expire_day"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Cüzdandan kazandığınız puanının geçerlilik süresi kazandığınız tarihten itibaren {day} gündür'
                  ).replace('{day}', parsePoint.earn.wallet_point.expire_day)
                }}
              </span>
              <span
                v-if="parsePoint.earn.wallet_point.minimum_total"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Cüzdan yüklemenizde puan kazanabilmeniz için en az {minTotal} yüklemelisiniz'
                  ).replace(
                    '{minTotal}',
                    $options.filters.currency(
                      parsePoint.earn.wallet_point.minimum_total,
                      {
                        symbol: currency
                      }
                    )
                  )
                }}
              </span>
            </div>
          </template>

          <template v-if="parsePoint.earn.registration_point.show">
            <span v-if="!hiddenTitle" class="font-weight-bold fs11-1">
              {{ $t('İlk Üyelik Puanı') }}:
            </span>
            <div class="ml-2 mb-1 d-flex flex-column w-100">
              <span
                v-if="parsePoint.earn.registration_point.point"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t('İlk üyeliğinizde {point} puan kazanırsınız').replace(
                    '{point}',
                    parsePoint.earn.registration_point.point
                  )
                }}
              </span>
              <span v-else :class="descriptionClass">
                {{ $t('Restoranda ilk üyeliğinizden puan kazanamazsınız') }}
              </span>
              <span
                v-if="parsePoint.earn.registration_point.expire_day"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'İlk üyelik puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür'
                  ).replace('{day}', parsePoint.earn.registration_point.expire_day)
                }}
              </span>
            </div>
          </template>

          <template v-if="parsePoint.earn.comment_point.show">
            <span v-if="!hiddenTitle" class="font-weight-bold fs11-1">
              {{ $t('Yorum Puanı') }}:
            </span>
            <div class="ml-2 mb-1 d-flex flex-column w-100">
              <span
                v-if="parsePoint.earn.comment_point.point"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Siparişinize yorum yaptığınızda {point} puan kazanırsınız'
                  ).replace('{point}', parsePoint.earn.comment_point.point)
                }}
              </span>
              <span v-else :class="descriptionClass">
                {{ $t('Siparişinize yorum yaptığınızda puan kazanamazsınız') }}
              </span>
              <span
                v-if="parsePoint.earn.comment_point.expire_day"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'Sipariş yorum puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür'
                  ).replace('{day}', parsePoint.earn.comment_point.expire_day)
                }}
              </span>
            </div>
          </template>

          <template v-if="parsePoint.earn.suggestion_point.show">
            <span v-if="!hiddenTitle" class="font-weight-bold fs11-1">
              {{ $t('İstek Öneri') }}:
            </span>
            <div class="ml-2 mb-1 d-flex flex-column w-100">
              <span
                v-if="parsePoint.earn.suggestion_point.point"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t('İstek önerinizde {point} puan kazanırsınız').replace(
                    '{point}',
                    parsePoint.earn.suggestion_point.point
                  )
                }}
              </span>
              <span v-else :class="descriptionClass">
                {{ $t('İstek önerinizde puan kazanamazsınız') }}
              </span>
              <span
                v-if="parsePoint.earn.suggestion_point.expire_day"
                class="font-weight-medium fs11-1"
                :class="descriptionClass"
              >
                {{
                  $t(
                    'İstek öneri puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür'
                  ).replace('{day}', parsePoint.earn.suggestion_point.expire_day)
                }}
              </span>
            </div>
          </template>
        </template>

        <template v-if="parsePoint.spend.is_active && parsePoint.spend.show">
          <span class="font-weight-bold fs11-1"> {{ $t('Harcarken') }}: </span>
          <div class="ml-2 d-flex flex-column">
            <span
              v-if="parsePoint.spend.exchange_rate"
              class="font-weight-medium fs11-1"
              :class="descriptionClass"
            >
              {{
                $t('1 Puan {amount} tutarıdır').replace(
                  '{amount}',
                  $options.filters.currency(parsePoint.spend.exchange_rate, {
                    symbol: currency
                  })
                )
              }}
            </span>
            <span v-else :class="descriptionClass">
              {{ $t('Restoranda puan harcama aktif değil') }}
            </span>
            <span
              v-if="parsePoint.spend.min_limit"
              class="font-weight-medium fs11-1"
              :class="descriptionClass"
            >
              {{
                $t(
                  'Puanınızı kullanabilmek için, bakiyeniz en az {point} puan olmalıdır'
                ).replace('{point}', parsePoint.spend.min_limit)
              }}
            </span>
            <span
              v-if="parsePoint.spend.max_limit"
              class="font-weight-medium fs11-1"
              :class="descriptionClass"
            >
              {{
                $t(
                  'Her siparişirinizde en fazla {point} puan kullanabilirsiniz'
                ).replace('{point}', parsePoint.spend.max_limit)
              }}
            </span>
            <span
              v-if="parsePoint.spend.max_rate"
              class="font-weight-medium fs11-1"
              :class="descriptionClass"
            >
              {{
                $t(
                  'Siparişinizin en fazla %{percent} oranını puan ile ödeyebilirsiniz'
                ).replace('{percent}', parsePoint.spend.max_rate)
              }}
            </span>
            <span class="font-weight-medium fs11-1" :class="descriptionClass">
              {{ $t('Puan kullandığınız siparişten puan kazanamazsınız.') }}
            </span>
          </div>
        </template>
      </div>
      <div v-else-if="!isActive && !hiddenError" class="custom-alert custom-alert-danger mb-2 w-100">
          <span class="common-icon" v-html="icons.warning"></span>
          <p>
            {{ $t('Bu restoranda puan sistemi aktif değil!') }}
          </p>
        </div>
    </div>
  </div>
</template>

<script>
import { warning } from '@/assets/icon/index.js'

export default {
  props: {
    pointInf: {
      type: Object,
      default: null
    },
    currency: {
      type: String,
      default: '₺'
    },
    show: {
      type: String,
      default: null
    },
    hiddenTitle: {
      type: Boolean,
      default: false
    },
    topTitle: {
      type: String,
      default: 'Hatırlatma'
    },
    hiddenTopTitle: {
      type: Boolean,
      default: false
    },
    hiddenError: {
      type: Boolean,
      default: false
    },
    showTotalPoint: {
      type: Boolean,
      default: false
    },
    descriptionClass: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: 'custom-alert custom-alert-warning'
    },
    activeFunc: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      point: null,
      totalPoint: 0,
      isActive: false,
      icons: {
        warning
      }
    }
  },
  computed: {
    parsePoint() {
      if (!this.point) return
      const show = (this.show && this.show.split(/\s*,\s*/)) || null

      const result = {
        earn: {
          is_active: !!this.point?.earn?.is_active,
          wallet_point: {
            point: undefined,
            price: undefined,
            expire_day: undefined,
            minimum_total: undefined
          },
          purchase_point: {
            point: undefined,
            price: undefined,
            expire_day: undefined,
            minimum_total: undefined
          },
          registration_point: {
            point: undefined,
            expire_day: undefined
          },
          comment_point: {
            point: undefined,
            expire_day: undefined
          },
          suggestion_point: {
            point: undefined,
            expire_day: undefined
          }
        },
        spend: {
          is_active: !!this.point?.spend?.is_active,
          max_rate: undefined,
          max_limit: undefined,
          min_limit: undefined,
          exchange_rate: undefined
        }
      }

      result.earn.is_active &&
        (result.earn = { ...result.earn, ...this.point.earn })
      result.spend.is_active &&
        (result.spend = { ...result.spend, ...this.point.spend })

      result.earn.wallet_point.show = show?.length
        ? show.includes('wallet')
        : true
      result.earn.purchase_point.show = show?.length
        ? show.includes('purchase')
        : true
      result.earn.registration_point.show = show?.length
        ? show.includes('registration')
        : true
      result.earn.comment_point.show = show?.length
        ? show.includes('comment')
        : true
      result.earn.suggestion_point.show = show?.length
        ? show.includes('suggestion')
        : true
      result.spend.show = show?.length ? show.includes('spend') : true

      return result
    }
  },
  created() {
    if (this.showTotalPoint) {
      this.$store.dispatch('auth/getTotalPoint').then(() => {
        this.totalPoint = Math.floor(
          +this.$store.state.auth?.info?.totalPoint || 0
        )
      })
    }

    this.initPoint()
  },
  methods: {
    getIsActive(point) {
      return this.activeFunc ? this.activeFunc(point) : (!!point?.earn?.is_active || !!point?.spend?.is_active)
    },
    initPoint() {
      if (this.$store.state.isBaseDomain) return

      if (this.pointInf) {
        this.point = this.pointInf
        this.isActive = this.getIsActive(this.point)

        return
      }

      this.$store.dispatch('getCompanyPointSettingData')
        .then(result => {
        this.point = result || {}
        this.isActive = this.getIsActive(this.point)
      })
    }
  }
}
</script>
