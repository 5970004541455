<template>
  <div>
    <div
      v-if="
        $store.state.auth.isLogged &&
          addresses.items.length &&
          !hideSelectAddress
      "
      class="addresses-wrap mx-auto"
    >
      <div class="address-card-header">
        <a
          v-b-toggle="`accordionAddress`"
          href="#"
          class="px-1 py-2 modal-address-select-btn"
          @click.prevent
        >
          {{ $t('Kayıtlı Adreslerimden Seç') }}
          <span class="custom-modal-1-down-i"></span>
        </a>
      </div>
      <b-collapse
        :id="`accordionAddress`"
        accordion="selectMapAcordions"
        role="tabpanel"
      >
        <div class="modal-effect-1-w">
          <div class="px-3">
            <div class="row m-0">
              <div
                v-for="address in addresses.items"
                :key="`address${address.id}`"
                class="col-md-6 p-2"
                @click="handleClickAddress(address)"
              >
                <div
                  :class="{
                    'selected-effect-1': addresses.selected.id === address.id
                  }"
                  class="bg-white box-style-6 border effect-1-w"
                >
                  <div class="selected-effect-1-icon">
                    <svg
                      id="Outline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="21"
                      height="21"
                    >
                      <path
                        d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"
                      />
                    </svg>
                  </div>
                  <div class="p-3 address-content">
                    <div class="media">
                      <div class="media-body">
                        <h6 class="mb-1 text-truncate fs14">
                          {{ address.name }}
                        </h6>
                        <p class="text-black item-desc">
                          {{ address.open_address }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

    <div style="height:500px; width:100%; max-height:60vh;">
      <client-only>
        <zz-map ref="map" v-model="coords" hide-locate-me></zz-map>
      </client-only>
    </div>

    <div class="formMapBtns mt-3 custom-modal-1-bottom">
      <div class="locate-me-custom elevation-2" @click="locateMe">
        <svg
          id="Layer_1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
        >
          <path
            d="m12 0a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12zm1 21.949v-1.949a1 1 0 0 0 -2 0v1.949a10.015 10.015 0 0 1 -8.949-8.949h1.949a1 1 0 0 0 0-2h-1.949a10.015 10.015 0 0 1 8.949-8.949v1.949a1 1 0 0 0 2 0v-1.949a10.015 10.015 0 0 1 8.949 8.949h-1.949a1 1 0 0 0 0 2h1.949a10.015 10.015 0 0 1 -8.949 8.949zm3-9.949a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1z"
          />
        </svg>
        <p>
          {{ $t('Konum Bul') }}
        </p>
      </div>
      <p class="btnNextW" @click="handleModalSave">
        <span>
          {{ $t('Devam Et') }}
        </span>
        <svg
          id="Outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="21"
          height="21"
        >
          <path
            d="M7,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l8.17-8.17a3,3,0,0,0,0-4.24L6.29,1.71A1,1,0,0,1,7.71.29l8.17,8.17a5,5,0,0,1,0,7.08L7.71,23.71A1,1,0,0,1,7,24Z"
          />
        </svg>
      </p>
    </div>
    <!-- <div class="map-wrap">
        <zzMap ref="gmap" v-model="coords"></zzMap>
      </div>
      <template slot="modal-ok">
        {{ $t('Kaydet') }}
      </template>
      <template slot="modal-cancel">
        {{ $t('Kapat') }}
      </template> -->
    <b-modal
      ref="newAddressModal"
      v-model="addresses.formModal"
      :title="$t('Adres Düzenleme')"
      hide-footer
      size="lg"
      centered
    >
      <p class="text-danger text-center">
        {{
          $t(
            'Adresinizin konum bilgisi bulunamadığı için düzenleme formu açılmıştır! Lütfen adresinizin konum bilgisini kaydediniz.'
          )
        }}
      </p>
      <address-form
        :address-id="addresses.selected.id"
        @success="handleSuccessAddressForm"
      ></address-form>
    </b-modal>
  </div>
</template>

<script>
import zzMap from '@/components/zzMap'
import addressForm from '@/components/common/address/form'
import { getAddressAll } from '@/api/member'
import { isSeviceableArea } from '@/api/check'

export default {
  name: 'SelectMap',
  components: {
    zzMap,
    addressForm
  },
  props: {
    hideSelectAddress: {
      type: Boolean,
      required: false,
      default: false
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      coords: this.$store.state.auth.location,
      addresses: {
        items: [],
        selected: {},
        formModal: false
      }
    }
  },
  created() {
    if (this.show) {
      this.coords = this.$store.state.auth.location
    }

    this.addresses.items = []
    this.addresses.selected = {}
    this.addresses.formModal = false

    // Konum seç modalı açıldığında üyenin adresler getiriliyor
    if (this.$store.state.auth.isLogged && this.show) {
      this.$nextTick(() => {
        this.getAddresses().then(() => {
          for (const address of this.addresses.items) {
            if (
              this.coords.toString() ===
              Object.values(address.coordinate).toString()
            ) {
              this.addresses.selected = address

              return
            }
          }
        })
      })
    }
  },
  methods: {
    locateMe() {
      this.$refs.map.locateMe()
    },
    // Konum kaydet
    async handleModalSave(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault()
      }

      try {
        // Seçim yapılmadıysa
        if (!this.coords?.length) {
          this.$notification.error(this.$t('Konum seçmelisiniz'))

          return
        }

        const result = await this.$axios.request({
          ...isSeviceableArea(),
          headers: {
            lat: this.coords[0],
            lon: this.coords[1]
          }
        })

        if (!result.data) {
          throw Error
        }

        this.$store.dispatch('auth/setLocation', this.coords)
        this.$emit('handleHiddenLocateModal')

        // rezzta.com değilse
        if (!this.$store.state.isBaseDomain) {
          // Eğer bir tane restoran döndüyse direk o restorana git
          if (result.data.length === 1) {
            this.$router.push(
              this.localePath({
                name: 'restaurant-branchSlug',
                params: {
                  branchSlug: result.data[0]?.slug
                }
              })
            )
          }
        }

        setTimeout(() => {
          location.reload()
        }, 200)
      } catch (error) {
        if (this.$store.state.isBaseDomain) {
          this.$notification.error(
            this.$t('Seçilen bölgeye hizmet veren restoran bulunamadı!')
          )
        } else {
          this.$notification.error(
            this.$t('Restoran seçilen bölgeye hizmet vermemektedir!')
          )
        }
      }
    },
    // Üye adreslerini getirir
    async getAddresses() {
      try {
        const result = await this.$axios.request({
          ...getAddressAll({
            memberId: this.$store.state.auth.info.id
          })
        })

        this.addresses.items = result.data

        return result.data
      } catch (err) {
        this.$notification.error(err.message)
      }
    },
    // Üyenin adreslerinden birine tıklandığında konum olarak adresin koordinatları ayarlanıyor. Eğer adreste daha önceden konum kaydedilmemişse adres düzenleme modalı açılıyor.
    handleClickAddress(item) {
      this.addresses.selected = item

      if (item.coordinate && item.coordinate.x && item.coordinate.y) {
        this.coords = [item.coordinate.x, item.coordinate.y]
      } else {
        this.addresses.formModal = true
      }
    },
    // Adres kaydediliyor ve konum ayarlanıyor
    handleSuccessAddressForm(model) {
      this.addresses.formModal = false

      if (
        model.location &&
        model.location.coords &&
        model.location.coords.length === 2
      ) {
        this.coords = model.location.coords
      }

      this.getAddresses()
    }
  }
}
</script>

<style scoped lang="scss"></style>
