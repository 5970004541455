<template>
  <zz-div :loading="loading">
    <div class="row">
      <div class="col-md-12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="form-material"
            @submit.prevent="handleSubmit(handleMemberDetailSubmit)"
          >
            <validation-provider v-slot="{ errors }" :name="$t('İsim')">
              <div class="form-label-group">
                <input
                  id="inputName"
                  v-model="form.name"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('İsim')"
                  :disabled="loading"
                  maxlength="100"
                  type="text"
                  class="form-control"
                  required
                />
                <label for="inputName">
                  {{ $t('İsim') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" :name="$t('Soyisim')">
              <div class="form-label-group">
                <input
                  id="inputSurname"
                  v-model="form.surname"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('Soyisim')"
                  :disabled="loading"
                  maxlength="100"
                  type="text"
                  class="form-control"
                  required
                />
                <label for="inputSurname">
                  {{ $t('Soyisim') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <button
              :disabled="loading"
              class="btn btn-lg btn-block text-uppercase font-weight-bold mb-2 rezzta-red white--text"
              type="submit"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Kaydet') }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </zz-div>
</template>

<script>
export default {
  name: 'AuthMemberDetail',
  data() {
    return {
      form: {
        name: '',
        surname: ''
      },
      loading: false
    }
  },
  computed: {
    tempMember() {
      return {
        id: this.$store.state.auth?.tempMember?.id || null,
        phone: this.$store.state.auth?.tempMember?.phone || null,
        code: this.$store.state.auth?.tempMember?.code || null
      }
    }
  },
  methods: {
    async handleMemberDetailSubmit() {
      this.loading = true

      try {
        await this.$store.dispatch('auth/memberWithPhone', {
          ...this.form,
          phone: this.tempMember.phone,
          code: this.tempMember.code,
          partner_institution_ref: this.$cookies.get('ref')?.toString() || null
        })
        this.$emit('success')
      } catch (error) {
        this.$emit('fail', error)
        this.$notification.error(
          error?.message || this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
