/* eslint-disable camelcase */
import {
  login,
  info,
  anonymLogin,
  checkToken,
  facebookLogin,
  refresh
} from '@/api/auth'
import {
  getMemberNotificationUnreadCount,
  getMemberLastOrderPoints,
  getMemberTotalPoint,
  postVerifyMemberPhoneForAuth,
  loginWithPhone,
  postMemberWithPhone
} from '@/api/member'
import { getOpenstreetmapReverse } from '@/api/front'

const authInfoDef = {
  name: '',
  surname: '',
  telephone: '',
  email: '',
  birthdate: null,
  wedding_anniversary: null,
  plaque: null,
  id: '',
  is_social_register: false,
  profile_image: null,
  wallet_balance: 0,
  memberGiftCardCount: 0,
  totalPoint: 0,
  discountRate: 0,
  is_send_sms: false,
  is_send_email: false,
  is_send_notification: false,
  partner_institution: null
}

const defaultAuthInfoDef = {
  name: '',
  surname: '',
  telephone: '',
  email: '',
  birthdate: null,
  wedding_anniversary: null,
  plaque: null,
  id: '',
  is_social_register: false,
  profile_image: null,
  wallet_balance: 0,
  memberGiftCardCount: 0,
  totalPoint: 0,
  discountRate: 0,
  is_send_sms: false,
  is_send_email: false,
  is_send_notification: false,
  partner_institution: null
}

const TOKEN_NAME = 'ftoken'
const REFRESH_TOKEN_NAME = 'frefresh_token'

export const state = () => ({
  token: '',
  refreshToken: '',
  info: authInfoDef,
  isLogged: false, // Üye girişi yapılmış ise true
  location: [],
  tempMember: {
    id: null,
    phone: null,
    code: null
  },
  modal: {
    show: false,
    title: '',
    noClose: false,
    hiddenClose: false,
    component: '',
    data: null
  },
  locationModal: {
    show: false,
    component: ''
  },
  locationAddress: '',
  locationDisplayName: '',
  lang: 'tr',
  notificationUnreadCount: 0,
  siteType: 'restaurant',
  memberLastOrderPointList: [],
  orderPointModal: false
})

export const mutations = {
  setToken: (state, { token }) => {
    state.token = token
  },
  setRefreshToken: (state, { token }) => {
    state.refreshToken = token
  },
  setInfo: (state, info) => {
    state.info = Object.assign(authInfoDef, info)
    state.isLogged = true
  },
  setLocation: (state, location) => {
    state.location = location
  },
  setLang: (state, lang) => {
    state.lang = lang
  },
  setLocationAddress: (state, address) => {
    state.locationAddress = address
  },
  setLocationDisplayName: (state, displayName) => {
    state.locationDisplayName = displayName
  },
  showLoginModal: (state, payload) => {
    state.modal.component = 'loginForm'
    state.modal.title = 'Üye Girişi'
    state.modal.noClose = false
    state.modal.show = true
    state.modal.data = payload
  },
  showLoginWithEmailModal: (state, payload) => {
    state.modal.component = 'loginWithEmailForm'
    state.modal.title = 'Üye Girişi'
    state.modal.noClose = false
    state.modal.show = true
    state.modal.data = payload
  },
  showLoginWithPhoneModal: (state, payload) => {
    state.modal.component = 'loginWithPhoneForm'
    state.modal.title = 'Numaranızı Girin'
    state.modal.noClose = false
    state.modal.show = true
    state.modal.data = payload
  },
  showRegisterModal: (state, payload) => {
    state.modal.component = 'registerForm'
    state.modal.title = 'Kayıt Ol'
    state.modal.noClose = false
    state.modal.show = true
    state.modal.data = payload
  },
  showForgotPasswordModal: (state, payload) => {
    state.modal.component = 'forgotPasswordForm'
    state.modal.title = 'Şifremi Unuttum'
    state.modal.noClose = false
    state.modal.show = true
    state.modal.data = payload
  },
  showVerifyPhoneModal: (state, payload) => {
    state.modal.component = 'verifyPhoneForm'
    state.modal.title = 'Telefon Doğrulama'
    state.modal.noClose = true
    state.modal.show = true
    state.modal.data = payload
  },
  showMemberDetailModal: (state, payload) => {
    state.modal.component = 'memberDetailForm'
    state.modal.title = 'Adınızı soyadınızı girin'
    state.modal.noClose = true
    state.modal.show = true
    state.modal.data = payload
  },
  showPhoneModal: (state, payload) => {
    state.modal.component = 'phoneForm'
    state.modal.title = 'Telefonunuzu Kaydedin'
    state.modal.noClose = true
    state.modal.show = true
    state.modal.data = payload
  },
  showPartnerInstitutionModal: (state, payload) => {
    state.modal.component = 'partnerInstitutionForm'
    state.modal.title = 'Üye Profili'
    state.modal.noClose = true
    state.modal.hiddenClose = true
    state.modal.show = true
    state.modal.data = payload
  },
  closeModal: state => {
    state.modal.component = ''
    state.modal.title = ''
    state.modal.noClose = false
    state.modal.hiddenClose = false
    state.modal.show = false
    state.modal.data = null
  },
  setTempMember: (state, { id, phone, code }) => {
    state.tempMember.id = id || null
    state.tempMember.phone = phone || null
    state.tempMember.code = code || null
  },
  showLocationModal: state => {
    state.locationModal.component = 'selectMap'
    state.locationModal.show = true
  },
  closeLocationModal: state => {
    state.locationModal.component = ''
    state.locationModal.show = false
  },
  clearAuth: state => {
    state.token = ''
    state.refreshToken = ''
    state.info = defaultAuthInfoDef
    state.isLogged = false
  },
  setNotificationUnreadCount: (state, count) => {
    state.notificationUnreadCount = count
  },
  setSiteType: (state, type) => {
    state.siteType = type
  },
  setTotalPoint(state, payload) {
    state.info.totalPoint = payload || 0
  },
  setOrderPoint(state, payload) {
    state.memberLastOrderPointList = payload

    if (payload && payload.length) {
      state.orderPointModal = true
    } else {
      state.orderPointModal = false
    }
  },
  setMemberLastOrderPointList(state, orderId) {
    if (orderId) {
      state.memberLastOrderPointList = state.memberLastOrderPointList.filter(
        x => x.id !== orderId
      )
    }

    if (
      !state.memberLastOrderPointList ||
      !state.memberLastOrderPointList.length
    ) {
      state.orderPointModal = false
    }
  },
  setOrderPointModal(state, payload) {
    state.orderPointModal = payload
  }
}
// TODO: Burdaki ve plugins/auth.js fonksiyonlar ortak bir dosyaya alınacak
export const actions = {
  setSiteType({ commit }, type = 'restaurant') {
    commit('setSiteType', type)
  },
  showLoginModal({ commit, rootState }) {
    switch (rootState.loginType) {
      case 'email':
        commit('showLoginWithEmailModal')
        break
      case 'phone':
        commit('showLoginWithPhoneModal')
        break
      default:
        commit('showLoginModal')
        break
    }
  },
  showVerifyPhoneModal({ commit }) {
    commit('showVerifyPhoneModal')
  },
  showMemberDetailModal({ commit, state }) {
    commit('showMemberDetailModal')
  },
  showPhoneModal({ commit }) {
    commit('showPhoneModal')
  },
  showPartnerInstitutionModal({ commit }, payload) {
    commit('showPartnerInstitutionModal', payload)
  },
  showRegisterModal({ commit, rootState }) {
    switch (rootState.loginType) {
      case 'phone':
        commit('showLoginWithPhoneModal')
        break
      default:
        commit('showRegisterModal')
        break
    }
  },
  showForgotPasswordModal({ commit }) {
    commit('showForgotPasswordModal')
  },
  closeModal({ commit }) {
    commit('closeModal')
  },
  showLocationModal({ commit }) {
    commit('showLocationModal')
  },
  closeLocationModal({ commit }) {
    commit('closeLocationModal')
  },
  setLocation({ commit, dispatch }, location) {
    commit('setLocation', location)
    this.$cookies.set('location', location, {
      maxAge: 60 * 60 * 24 * 7 * 30,
      path: '/'
    })
    this.$axios.setHeader('lat', location[0])
    this.$axios.setHeader('lon', location[1])
    dispatch('getAddressFromCoordinate', location)
  },
  setLang({ commit, dispatch }, lang) {
    commit('setLang', lang)
    this.$cookies.set('lang', lang)
    this.$axios.setHeader('lang', lang)
  },
  async getAddressFromCoordinate({ commit }, coordinate) {
    try {
      const result = await this.$axios({
        ...getOpenstreetmapReverse(),
        params: {
          format: 'json',
          lat: coordinate[0],
          lon: coordinate[1]
        }
      })

      const { address, display_name } = result.data

      commit('setLocationAddress', address)
      commit('setLocationDisplayName', display_name)
    } catch (error) {}
  },
  async login({ commit, dispatch, state, rootState }, userInfo) {
    try {
      const { email, password, rememberMe, partner_institution_ref } = userInfo

      const resp = await this.$axios({
        ...login(),
        data: {
          email: email.trim(),
          password,
          remember_me: rememberMe,
          partner_institution_ref
        }
      })

      if (!resp.data.is_verify) {
        commit('setTempMember', {
          id: resp.data.member_id,
          phone: resp.data.phone
        })
        commit('showPhoneModal')
      } else {
        const { token, refreshToken } = resp.data

        this.$cookies.set(TOKEN_NAME, token, {
          maxAge: 60 * 60 * 24,
          path: '/'
        })
        this.$cookies.set(REFRESH_TOKEN_NAME, refreshToken, {
          maxAge: 60 * 60 * 24 * 30 * 12,
          path: '/'
        })
        this.$axios.setToken(token, 'Bearer')
        commit('setToken', { token })
        commit('setRefreshToken', { token: refreshToken })
        await dispatch('getInfo')
        await dispatch('basket/getBasket', {}, { root: true })
        await dispatch('memberLastOrderPoints')
        dispatch('closeModal')

        if (rootState.isBaseDomain) {
          // Lokasyon seçili değilse modal açılır
          if (state.location?.length !== 2) {
            dispatch('showLocationModal')
          }
        }
      }

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async loginWithPhone({ state, commit, dispatch }, userInfo) {
    try {
      const { phone } = userInfo

      const resp = await this.$axios({
        ...loginWithPhone(),
        data: {
          phone
        }
      })

      if (resp.data.member_id) {
        commit('setTempMember', { id: resp.data.member_id, phone: null })
      } else {
        commit('setTempMember', { id: null, phone })
      }

      commit('showVerifyPhoneModal')

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async memberWithPhone({ commit, dispatch, state, rootState }, userInfo) {
    try {
      const { name, surname, phone, code, partner_institution_ref } = userInfo

      const resp = await this.$axios({
        ...postMemberWithPhone(),
        data: {
          name,
          surname,
          phone,
          code,
          partner_institution_ref
        }
      })

      const { token, refreshToken } = resp.data

      this.$cookies.set(TOKEN_NAME, token, {
        maxAge: 60 * 60 * 24,
        path: '/'
      })
      this.$cookies.set(REFRESH_TOKEN_NAME, refreshToken, {
        maxAge: 60 * 60 * 24 * 30 * 12,
        path: '/'
      })
      this.$axios.setToken(token, 'Bearer')
      commit('setToken', { token })
      commit('setRefreshToken', { token: refreshToken })
      await dispatch('getInfo')
      await dispatch('basket/getBasket', {}, { root: true })
      await dispatch('memberLastOrderPoints')

      if (rootState.makePartnerInstitutionAfterRegistration && !state.info.partner_institution_id) {
        dispatch('showPartnerInstitutionModal', {
          receiveCode: this.$cookies?.get('ref') || null
        })
      } else {
        dispatch('closeModal')
      }

      if (rootState.isBaseDomain) {
        // Lokasyon seçili değilse modal açılır
        if (state.location?.length !== 2) {
          dispatch('showLocationModal')
        }
      }

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async memberPhoneVerifyForAuth(
    { commit, dispatch, state, rootState },
    userInfo
  ) {
    try {
      const { code, memberId, partner_institution_ref, isRegistration } = userInfo

      const resp = await this.$axios({
        ...postVerifyMemberPhoneForAuth({ memberId }),
        data: {
          code,
          partner_institution_ref
        }
      })

      const { token, refreshToken } = resp.data

      this.$cookies.set(TOKEN_NAME, token, {
        maxAge: 60 * 60 * 24,
        path: '/'
      })
      this.$cookies.set(REFRESH_TOKEN_NAME, refreshToken, {
        maxAge: 60 * 60 * 24 * 30 * 12,
        path: '/'
      })
      this.$axios.setToken(token, 'Bearer')
      commit('setToken', { token })
      commit('setRefreshToken', { token: refreshToken })
      await dispatch('getInfo')
      await dispatch('basket/getBasket', {}, { root: true })
      await dispatch('memberLastOrderPoints')

      if (rootState.makePartnerInstitutionAfterRegistration && !state.info.partner_institution_id && isRegistration) {
        dispatch('showPartnerInstitutionModal', {
          receiveCode: this.$cookies?.get('ref') || null
        })
      } else {
        dispatch('closeModal')
      }

      if (rootState.isBaseDomain) {
        // Lokasyon seçili değilse modal açılır
        if (state.location?.length !== 2) {
          dispatch('showLocationModal')
        }
      }

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async facebookLogin({ commit, dispatch }, userInfo) {
    try {
      const {
        id,
        email,
        first_name,
        last_name,
        access_token,
        reference_code
      } = userInfo

      const resp = await this.$axios({
        ...facebookLogin(),
        data: {
          fb_access_token: access_token,
          email,
          name: first_name,
          surname: last_name,
          fb_id: id,
          reference_code
        }
      })

      const { token, refreshToken } = resp.data

      this.$cookies.set(TOKEN_NAME, token, {
        maxAge: 60 * 60 * 24 * 30 * 12,
        path: '/'
      })
      this.$cookies.set(REFRESH_TOKEN_NAME, refreshToken, {
        maxAge: 60 * 60 * 24 * 30 * 12,
        path: '/'
      })
      this.$axios.setToken(token, 'Bearer')
      commit('setToken', { token, isLogged: true })
      commit('setRefreshToken', { token: refreshToken })

      await dispatch('getInfo')

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async getInfo({ commit, dispatch }) {
    try {
      const resp = await this.$axios({
        ...info()
      })

      resp.data.profile_image += '?t=' + resp.data.updated_at

      if (resp.data.birthdate) {
        resp.data.birthdate = resp.data.birthdate.split('T')[0]
      }

      if (resp.data.wedding_anniversary) {
        resp.data.wedding_anniversary = resp.data.wedding_anniversary.split(
          'T'
        )[0]
      }

      commit('setInfo', resp.data)
      await dispatch('getMemberNotificationUnreadCount')

      return Promise.resolve()
    } catch (err) {
      this.$cookies.remove(TOKEN_NAME, { path: '/' })
      this.$cookies.remove(REFRESH_TOKEN_NAME, { path: '/' })
      commit('clearAuth')

      return Promise.reject(err)
    }
  },
  async getTotalPoint({ state, commit, dispatch }) {
    try {
      const params = { memberId: state.info.id }

      const resp = await this.$axios({
        ...getMemberTotalPoint(params)
      })

      commit('setTotalPoint', resp.data)

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async getMemberNotificationUnreadCount({ commit, state }) {
    try {
      const resp = await this.$axios({
        ...getMemberNotificationUnreadCount({
          memberId: state.info.id
        })
      })

      commit('setNotificationUnreadCount', resp.data)

      return Promise.resolve()
    } catch (err) {
      commit('setNotificationUnreadCount', 0)

      return Promise.reject(err)
    }
  },
  async logout({ commit, dispatch }) {
    try {
      this.$cookies.remove(TOKEN_NAME, { path: '/' })
      this.$cookies.remove(REFRESH_TOKEN_NAME, { path: '/' })
      commit('clearAuth')
      await dispatch('setAnonymToken')

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  },
  setRefreshToken({ commit, dispatch }, token) {
    this.$cookies.set(REFRESH_TOKEN_NAME, token, {
      maxAge: 60 * 60 * 24 * 30 * 12,
      path: '/'
    })
    this.$axios.setToken(token, 'Bearer')
    commit('setToken', { token, isLogged: true })

    return dispatch('getInfo')
  },
  setToken({ commit, dispatch }, token) {
    this.$cookies.set(TOKEN_NAME, token, {
      maxAge: 60 * 60 * 24,
      path: '/'
    })
    this.$axios.setToken(token, 'Bearer')
    commit('setToken', { token, isLogged: true })

    return dispatch('getInfo')
  },
  async setAnonymToken({ commit }) {
    try {
      commit('clearAuth')

      const resp = await this.$axios({
        ...anonymLogin()
      })

      this.$cookies.set(TOKEN_NAME, resp.data, { path: '/' })
      commit('setToken', { token: resp.data })
      this.$axios.setToken(resp.data, 'Bearer')

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async checkToken() {
    try {
      const resp = await this.$axios({
        ...checkToken()
      })

      return Promise.resolve(resp.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async takeToken({ commit, dispatch }) {
    try {
      let token = this.$cookies.get(TOKEN_NAME, { path: '/' })

      const refreshToken = this.$cookies.get(REFRESH_TOKEN_NAME, {
        path: '/'
      })

      if (!token) {
        if (refreshToken) {
          const { data } = await this.$axios({
            ...refresh(),
            data: {
              refresh_token: refreshToken
            }
          })

          token = data.token
        } else {
          // Eğer çerezde token ve refresh token yoksa anonim token al
          return await dispatch('setAnonymToken')
        }
      }

      this.$cookies.set(TOKEN_NAME, token, {
        maxAge: 60 * 60 * 24,
        path: '/'
      })
      commit('setToken', { token })
      this.$axios.setToken(token, 'Bearer')
      commit('setRefreshToken', { token: refreshToken })

      try {
        const checkToken = await dispatch('checkToken')

        if (checkToken) {
          // true -> üyen tokenı
          return await dispatch('getInfo')
        }
      } catch (error) {
        // 401 -> Geçersiz token
        return await dispatch('setAnonymToken')
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async memberLastOrderPoints({ commit, state }) {
    try {
      if (state.isLogged) {
        const memberId = state.info.id

        const resp = await this.$axios({
          ...getMemberLastOrderPoints(memberId)
        })

        commit('setOrderPoint', resp.data)
      } else {
        commit('setOrderPoint', [])
      }

      return Promise.resolve()
    } catch (err) {
      commit('setOrderPoint', [])

      return Promise.resolve()
    }
  }
}

export const getters = {
  getMemberLastOrderPointLast(state) {
    if (
      state.memberLastOrderPointList &&
      state.memberLastOrderPointList.length &&
      state.memberLastOrderPointList[state.memberLastOrderPointList.length - 1]
    ) {
      return state.memberLastOrderPointList[
        state.memberLastOrderPointList.length - 1
      ]
    } else {
      return null
    }
  },
  getOrderPointModal(state) {
    return state.orderPointModal
  }
}

/**
 * checkToken
 * resp = true -> üye tokenı
 * resp = false -> anonim tokenı
 * resp.status code = 401 -> geçersiz token
 */
