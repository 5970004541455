var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "b-navbar",
        {
          staticClass: "header-nav shadow-sm",
          attrs: { toggleable: "lg", variant: "light", type: "light" }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "b-navbar-brand",
                { attrs: { href: "https://www.myrezzta.com/" } },
                [
                  _c("img", {
                    staticStyle: { "max-height": "30px" },
                    attrs: {
                      alt: "logo",
                      height: "30",
                      src: "/images/myrezzta.svg"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { id: "nav-collapse", "is-nav": "" } },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _vm.$i18n.locale !== "en"
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: _vm.switchLocalePath("en") } },
                            [
                              _c(
                                "span",
                                { staticClass: "grey--text text--darken-2" },
                                [
                                  _vm._v(
                                    "\n              English\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$i18n.locale !== "tr"
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: _vm.switchLocalePath("tr") } },
                            [
                              _c(
                                "span",
                                { staticClass: "grey--text text--darken-2" },
                                [_vm._v("\n              Türkçe\n            ")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$i18n.locale !== "de"
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: _vm.switchLocalePath("de") } },
                            [
                              _c(
                                "span",
                                { staticClass: "grey--text text--darken-2" },
                                [
                                  _vm._v(
                                    "\n              Almanca\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }