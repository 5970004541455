export default {
  methods: {
    extractType(url) {
      const splitParse = url?.split('.') || []
      const last = splitParse[splitParse.length - 1]

      return {
        is: type => {
          const types = Array.isArray(type) ? type : [type]
  
          return types.includes(last)
        },
        default: () => {
          switch (last) {
            case 'pdf':
              return '/images/pdf.png'
            default:
              return url
          }
        }
      }
    }
  }
}