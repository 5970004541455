<template>
  <form
    class="header-search-form"
    :class="{ 'header-search-result': isSearch }"
    @submit.prevent="handleSearch"
  >
    <div class="search-modal-bg" @click="searchModalClose"></div>
    <input
      v-model.trim="searchQuery"
      :placeholder="$t('Yemek veya restoran arayın...')"
      type="text"
      @input="handleSearchMethod"
      @click="handleSearchClick"
    />
    <button type="submit">
      <span
        class="common-icon"
        :class="{ 'search-loading': loading }"
        v-html="loading ? icons.spinner : icons.search"
      ></span>
    </button>
    <div
      v-if="isSearch && searchResult && searchResult.length"
      class="search-result-wrapper restaurant-section-list-box-w"
    >
      <div class="custom-scroll">
        <div class="restaurant-section-list">
          <Restaurant
            v-for="(item, index) in searchResult"
            :key="index"
            :item="item"
            :show-favorite="false"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { search, spinner } from '@/assets/icon/index.js'
import { getRestaurantAll } from '@/api/restaurant'
import Restaurant from '@/components/common/restaurant'

export default {
  name: 'HomeSearchBlockSearchSection',
  components: {
    Restaurant
  },
  data() {
    return {
      searchQuery: '',
      icons: {
        search,
        spinner
      },
      loading: false,
      searchResult: [],
      isSearch: false,
      cache: {}
    }
  },
  watch: {
    isSearch(e1) {
      const body = document.querySelector('body')

      if (body) {
        if (e1 === true) {
          body.classList.add('modal-open')
        } else {
          body.classList.remove('modal-open')
        }
      }
    },
    $route(to, from) {
      this.searchModalClose()
    }
  },
  methods: {
    searchModalClose() {
      this.isSearch = false

      const body = document.querySelector('body')

      if (body) {
        body.classList.remove('modal-open')
      }
    },
    handleSearchClick() {
      if (this.searchResult && this.searchResult.length && !this.isSearch) {
        this.isSearch = true
      }
    },
    async search(key) {
      const params = {
        q: key
      }

      return await this.$axios.request({
        ...getRestaurantAll(),
        params
      })
    },
    async searchCache(key) {
      try {
        key = key.toLowerCase()

        if (
          this.cache &&
          typeof this.cache === 'object' &&
          Object.keys(this.cache).length > 50
        ) {
          for (const i in this.cache) {
            if (this.cache[i] && this.cache[i].length && !this.cache[key]) {
              const index = Object.keys(this.cache).indexOf(i)

              if (index < 20) {
                delete this.cache[i]
              }
            }
          }
        }

        if (key in this.cache) {
          if (this.cache[key] && this.cache[key].length) {
            this.isSearch = true
          }

          return this.cache[key]
        }

        this.loading = true

        const result = await this.search(key)

        if (result.data && result.data.results && result.data.results.length) {
          result.data.results = result.data.results.map(item => {
            delete item.products_menus

            return item
          })
          this.isSearch = true
          this.cache[key] = result.data.results

          return result.data.results
        } else {
          this.isSearch = false
          this.cache[key] = []

          return []
        }
      } catch (err) {
        this.isSearch = false

        return []
      } finally {
        this.loading = false
      }
    },
    async handleSearchMethod(event) {
      try {
        const data = event.target.value.trim()

        this.searchQuery = data.replace(/[^a-zA-Z0-9ıüçöşğ!,.&-_ ]+/gi, '')

        if (/^[a-zA-Z0-9ıüçöşğ!,.&-_ ]{3,13}$/gi.test(data)) {
          const result = await this.searchCache(this.searchQuery)

          this.searchResult = result
        } else if (!data || !data.length) {
          this.isSearch = false

          return
        }
      } catch (e) {
        console.log('search error')
      }
    },
    handleLocateMe() {
      this.$store.dispatch('auth/showLocationModal')
    },
    handleSearch() {
      this.$router.push(
        this.localePath({
          name: 'restaurants',
          query: {
            q: this.searchQuery
          }
        })
      )

      // Facebook Pixel
      fbq('track', 'Search', {
        search_string: this.searchQuery,
        country: this.$store.state.auth.locationDisplayName || ''
      })
    }
  }
}
</script>
