var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header header-w",
      class: [
        {
          "header-w-location": _vm.headerAbsolute
        }
      ]
    },
    [
      !_vm.$store.state.isDemo
        ? _c("smartAppBanner", { attrs: { data: _vm.smartAppBannerData } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "location-bar bg-white d-block" }, [
          _c("div", { staticClass: "container py-1" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$store.dispatch("auth/showLocationModal")
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "common-icon",
                  domProps: { innerHTML: _vm._s(_vm.icons.location1) }
                }),
                _vm._v(" "),
                _c("b", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getShortAddress()) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "down-custom-icon" })
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-navbar",
        { staticClass: "header-nav shadow-sm", attrs: { toggleable: "lg" } },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "header-nav-content" },
              [
                _c(
                  "b-navbar-brand",
                  { attrs: { to: _vm.localePath("index") } },
                  [
                    _c("img", {
                      staticStyle: { "max-height": "38px" },
                      attrs: {
                        alt: "logo",
                        height: "38",
                        src: "/images/logoB.png"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "about-rezzta",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.blogHandler("demo-rezzta-description")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("REZZTA HAKKINDA")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "common-icon map-mobil",
                    on: {
                      click: function($event) {
                        return _vm.$store.dispatch("auth/showLocationModal")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticStyle: { "enable-background": "new 0 0 512 512" },
                        attrs: {
                          id: "Capa_1",
                          version: "1.1",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 512 512",
                          "xml:space": "preserve"
                        }
                      },
                      [
                        _c("path", {
                          staticClass: "st0",
                          attrs: {
                            d:
                              "M256,61.53c-76.57,0-138.64,62.07-138.64,138.64S256,450.47,256,450.47s138.64-173.73,138.64-250.3\n                    S332.57,61.53,256,61.53z M256,264.25c-38.82,0-70.28-31.47-70.28-70.28c0-38.82,31.47-70.28,70.28-70.28s70.28,31.47,70.28,70.28\n                    C326.28,232.79,294.82,264.25,256,264.25z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("small", { staticClass: "map-icon-wrapper" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header-search-w" },
                  [_c("searchLine")],
                  1
                ),
                _vm._v(" "),
                _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }, [
                  _c("span", { staticClass: "navbar-toggler-icon" }, [
                    _c("span"),
                    _vm._v(" "),
                    _c("span"),
                    _vm._v(" "),
                    _c("span")
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$store.state.auth.notificationUnreadCount > 0,
                          expression:
                            "$store.state.auth.notificationUnreadCount > 0"
                        }
                      ],
                      staticClass: "badge badge-success menu-w-badge"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$store.state.auth.notificationUnreadCount
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "b-collapse",
                  { attrs: { id: "nav-collapse", "is-nav": "" } },
                  [
                    _c(
                      "b-navbar-nav",
                      { staticClass: "ml-auto" },
                      [
                        _c(
                          "b-nav-item",
                          {
                            staticStyle: { display: "none" },
                            attrs: { to: _vm.localePath("index") }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("Anasayfa")) +
                                  "\n              "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$store.state.auth.isLogged
                          ? [
                              _c(
                                "b-nav-item-dropdown",
                                {
                                  staticClass: "profil-h-w",
                                  attrs: {
                                    "menu-class": "shadow-sm border-0",
                                    right: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function() {
                                          return [
                                            _c("span", {
                                              staticClass: "common-icon",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.icons.profil1
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.$t("Profil")) +
                                                  "\n                  "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.$store.state.auth
                                                        .notificationUnreadCount >
                                                      0,
                                                    expression:
                                                      "$store.state.auth.notificationUnreadCount > 0"
                                                  }
                                                ],
                                                staticClass:
                                                  "badge badge-success custom-h-badge"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$store.state.auth
                                                        .notificationUnreadCount
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    3356577603
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "profil-menu-head" },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "nav-osahan-pic rounded-pill",
                                        attrs: {
                                          src:
                                            _vm.$store.state.auth.info
                                              .profile_image,
                                          onerror: "this.style.display='none'"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$store.state.auth.info.name
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    { attrs: { to: _vm.localePath("me") } },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.profil2)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Profilim")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { to: _vm.localePath("me-orders") }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.order)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Siparişlerim")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: _vm.localePath("me-comments")
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.comment)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Yorumlarım")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: _vm.localePath("me-addresses")
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.address)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Adreslerim")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: _vm.localePath("me-notifications")
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.notification
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Bildirimlerim")) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.$store.state.auth
                                                  .notificationUnreadCount > 0,
                                              expression:
                                                "$store.state.auth.notificationUnreadCount > 0"
                                            }
                                          ],
                                          staticClass: "badge badge-success"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$store.state.auth
                                                  .notificationUnreadCount
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: _vm.localePath("me-favorites")
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.favorite)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Favorilerim")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { to: _vm.localePath("me-wallet") }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.wallet)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Cüzdanım")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: _vm.localePath("me-giftCards")
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.giftCard)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Hediye Çeklerim")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: _vm.localePath("me-reference")
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.reference)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Referans Sistemi")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.handleLogout } },
                                    [
                                      _c("span", {
                                        staticClass: "common-icon",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.icons.exit)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("Çıkış Yap")) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : [
                              _c(
                                "b-nav-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$store.dispatch(
                                        "auth/showLoginModal"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "common-icon",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.icons.login)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$store.state.isDemo
                                            ? _vm.$t("Demo Kullanıcı")
                                            : _vm.$t("Giriş")
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.$store.state.isDemo
                                ? _c("li", { staticClass: "nav-item" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.branchLink,
                                          target: "__blank"
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "common-icon",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.icons.login)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("Demo Restoran")) +
                                              "\n                  "
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.$store.state.isDemo
                                ? _c("b-nav-item", [
                                    _c("span", {
                                      staticClass: "common-icon",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.icons.register)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Kayıt Ol")) +
                                          "\n                "
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ],
                        _vm._v(" "),
                        _vm.$store.state.auth.isLogged
                          ? _c("basket-item")
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            centered: "",
            scrollable: "",
            "hide-footer": "",
            "content-class": "custom-modal-1"
          },
          model: {
            value: _vm.blog.modal,
            callback: function($$v) {
              _vm.$set(_vm.blog, "modal", $$v)
            },
            expression: "blog.modal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-4 pt-2 pb-4" },
            [
              _c("h5", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.blog.textTitle))
              ]),
              _vm._v(" "),
              _c("blogArea", {
                staticStyle: { "margin-top": "-20px" },
                attrs: { slug: _vm.blog.blogSlug },
                on: {
                  title: function($event) {
                    _vm.blog["textTitle"] = $event
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }