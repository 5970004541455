import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'// eslint-disable-line no-unused-vars
import scrollBehavior from './router.scrollBehavior.js'

const _577e5897 = () => interopDefault(import('../pages/appDownload/index.vue' /* webpackChunkName: "pages/appDownload/index" */))
const _c3d046ea = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _8bdfcc46 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _368b8d44 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0604a99d = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _15be7340 = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _3cc14630 = () => interopDefault(import('../pages/me/addresses.vue' /* webpackChunkName: "pages/me/addresses" */))
const _1e887254 = () => interopDefault(import('../pages/me/comments.vue' /* webpackChunkName: "pages/me/comments" */))
const _682a2590 = () => interopDefault(import('../pages/me/deleteAccount.vue' /* webpackChunkName: "pages/me/deleteAccount" */))
const _1707d265 = () => interopDefault(import('../pages/me/favorites.vue' /* webpackChunkName: "pages/me/favorites" */))
const _51be13be = () => interopDefault(import('../pages/me/giftCards.vue' /* webpackChunkName: "pages/me/giftCards" */))
const _5be83d14 = () => interopDefault(import('../pages/me/notifications.vue' /* webpackChunkName: "pages/me/notifications" */))
const _999e1472 = () => interopDefault(import('../pages/me/orders.vue' /* webpackChunkName: "pages/me/orders" */))
const _ab0d429e = () => interopDefault(import('../pages/me/permission.vue' /* webpackChunkName: "pages/me/permission" */))
const _faf937c4 = () => interopDefault(import('../pages/me/point.vue' /* webpackChunkName: "pages/me/point" */))
const _ac9fe54e = () => interopDefault(import('../pages/me/reference.vue' /* webpackChunkName: "pages/me/reference" */))
const _18253cdb = () => interopDefault(import('../pages/me/wallet.vue' /* webpackChunkName: "pages/me/wallet" */))
const _28fb659c = () => interopDefault(import('../pages/staticBlog/myrezztaRegisterDemo.vue' /* webpackChunkName: "pages/staticBlog/myrezztaRegisterDemo" */))
const _5339c9a1 = () => interopDefault(import('../pages/restaurants.vue' /* webpackChunkName: "pages/restaurants" */))
const _034f04f2 = () => interopDefault(import('../pages/staticBlog/suggestRestaurant.vue' /* webpackChunkName: "pages/staticBlog/suggestRestaurant" */))
const _8461248a = () => interopDefault(import('../pages/staticBlog/registerInfo.vue' /* webpackChunkName: "pages/staticBlog/registerInfo" */))
const _529af92d = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _6e684a2c = () => interopDefault(import('../pages/auth/reset/_code.vue' /* webpackChunkName: "pages/auth/reset/_code" */))
const _d1834aaa = () => interopDefault(import('../pages/blog/_index.vue' /* webpackChunkName: "pages/blog/_index" */))
const _1a8c0bc8 = () => interopDefault(import('../pages/campaigns/_campaignSlug/index.vue' /* webpackChunkName: "pages/campaigns/_campaignSlug/index" */))
const _2458efbf = () => interopDefault(import('../pages/restaurant/_branchSlug.vue' /* webpackChunkName: "pages/restaurant/_branchSlug" */))
const _40b09ca0 = () => interopDefault(import('../pages/campaigns/_campaignSlug/_campaignContent.vue' /* webpackChunkName: "pages/campaigns/_campaignSlug/_campaignContent" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,
  /* eslint-disable array-bracket-spacing, quotes, quote-props, object-curly-spacing, key-spacing */
  routes: [{
    path: "/appDownload",
    component: _577e5897,
    name: "appDownload___tr"
  }, {
    path: "/checkout",
    component: _c3d046ea,
    name: "checkout___tr"
  }, {
    path: "/de",
    component: _8bdfcc46,
    name: "index___de"
  }, {
    path: "/en",
    component: _8bdfcc46,
    name: "index___en"
  }, {
    path: "/maintenance",
    component: _368b8d44,
    name: "maintenance___tr"
  }, {
    path: "/me",
    component: _0604a99d,
    children: [{
      path: "",
      component: _15be7340,
      name: "me___tr"
    }, {
      path: "addresses",
      component: _3cc14630,
      name: "me-addresses___tr"
    }, {
      path: "comments",
      component: _1e887254,
      name: "me-comments___tr"
    }, {
      path: "deleteAccount",
      component: _682a2590,
      name: "me-deleteAccount___tr"
    }, {
      path: "favorites",
      component: _1707d265,
      name: "me-favorites___tr"
    }, {
      path: "giftCards",
      component: _51be13be,
      name: "me-giftCards___tr"
    }, {
      path: "notifications",
      component: _5be83d14,
      name: "me-notifications___tr"
    }, {
      path: "orders",
      component: _999e1472,
      name: "me-orders___tr"
    }, {
      path: "permission",
      component: _ab0d429e,
      name: "me-permission___tr"
    }, {
      path: "point",
      component: _faf937c4,
      name: "me-point___tr"
    }, {
      path: "reference",
      component: _ac9fe54e,
      name: "me-reference___tr"
    }, {
      path: "wallet",
      component: _18253cdb,
      name: "me-wallet___tr"
    }]
  }, {
    path: "/myrezzta-demo-kayit",
    component: _28fb659c,
    name: "staticBlog-myrezztaRegisterDemo___tr"
  }, {
    path: "/restaurants",
    component: _5339c9a1,
    name: "restaurants___tr"
  }, {
    path: "/restoran-oner",
    component: _034f04f2,
    name: "staticBlog-suggestRestaurant___tr"
  }, {
    path: "/uye-isyeri-kayit",
    component: _8461248a,
    name: "staticBlog-registerInfo___tr"
  }, {
    path: "/auth/reset",
    component: _529af92d,
    name: "auth-reset___tr",
    children: [{
      path: ":code?",
      component: _6e684a2c,
      name: "auth-reset-code___tr"
    }]
  }, {
    path: "/de/appDownload",
    component: _577e5897,
    name: "appDownload___de"
  }, {
    path: "/de/checkout",
    component: _c3d046ea,
    name: "checkout___de"
  }, {
    path: "/de/maintenance",
    component: _368b8d44,
    name: "maintenance___de"
  }, {
    path: "/de/me",
    component: _0604a99d,
    children: [{
      path: "",
      component: _15be7340,
      name: "me___de"
    }, {
      path: "addresses",
      component: _3cc14630,
      name: "me-addresses___de"
    }, {
      path: "comments",
      component: _1e887254,
      name: "me-comments___de"
    }, {
      path: "deleteAccount",
      component: _682a2590,
      name: "me-deleteAccount___de"
    }, {
      path: "favorites",
      component: _1707d265,
      name: "me-favorites___de"
    }, {
      path: "giftCards",
      component: _51be13be,
      name: "me-giftCards___de"
    }, {
      path: "notifications",
      component: _5be83d14,
      name: "me-notifications___de"
    }, {
      path: "orders",
      component: _999e1472,
      name: "me-orders___de"
    }, {
      path: "permission",
      component: _ab0d429e,
      name: "me-permission___de"
    }, {
      path: "point",
      component: _faf937c4,
      name: "me-point___de"
    }, {
      path: "reference",
      component: _ac9fe54e,
      name: "me-reference___de"
    }, {
      path: "wallet",
      component: _18253cdb,
      name: "me-wallet___de"
    }]
  }, {
    path: "/de/myrezzta-registrieren-demo",
    component: _28fb659c,
    name: "staticBlog-myrezztaRegisterDemo___de"
  }, {
    path: "/de/register-fur-firmenmitglieder",
    component: _8461248a,
    name: "staticBlog-registerInfo___de"
  }, {
    path: "/de/restaurant-vorschlagen",
    component: _034f04f2,
    name: "staticBlog-suggestRestaurant___de"
  }, {
    path: "/de/restaurants",
    component: _5339c9a1,
    name: "restaurants___de"
  }, {
    path: "/en/appDownload",
    component: _577e5897,
    name: "appDownload___en"
  }, {
    path: "/en/business-member-register",
    component: _8461248a,
    name: "staticBlog-registerInfo___en"
  }, {
    path: "/en/checkout",
    component: _c3d046ea,
    name: "checkout___en"
  }, {
    path: "/en/maintenance",
    component: _368b8d44,
    name: "maintenance___en"
  }, {
    path: "/en/me",
    component: _0604a99d,
    children: [{
      path: "",
      component: _15be7340,
      name: "me___en"
    }, {
      path: "addresses",
      component: _3cc14630,
      name: "me-addresses___en"
    }, {
      path: "comments",
      component: _1e887254,
      name: "me-comments___en"
    }, {
      path: "deleteAccount",
      component: _682a2590,
      name: "me-deleteAccount___en"
    }, {
      path: "favorites",
      component: _1707d265,
      name: "me-favorites___en"
    }, {
      path: "giftCards",
      component: _51be13be,
      name: "me-giftCards___en"
    }, {
      path: "notifications",
      component: _5be83d14,
      name: "me-notifications___en"
    }, {
      path: "orders",
      component: _999e1472,
      name: "me-orders___en"
    }, {
      path: "permission",
      component: _ab0d429e,
      name: "me-permission___en"
    }, {
      path: "point",
      component: _faf937c4,
      name: "me-point___en"
    }, {
      path: "reference",
      component: _ac9fe54e,
      name: "me-reference___en"
    }, {
      path: "wallet",
      component: _18253cdb,
      name: "me-wallet___en"
    }]
  }, {
    path: "/en/myrezzta-register-demo",
    component: _28fb659c,
    name: "staticBlog-myrezztaRegisterDemo___en"
  }, {
    path: "/en/restaurants",
    component: _5339c9a1,
    name: "restaurants___en"
  }, {
    path: "/en/suggest-restaurant",
    component: _034f04f2,
    name: "staticBlog-suggestRestaurant___en"
  }, {
    path: "/de/auth/reset",
    component: _529af92d,
    name: "auth-reset___de",
    children: [{
      path: ":code?",
      component: _6e684a2c,
      name: "auth-reset-code___de"
    }]
  }, {
    path: "/en/auth/reset",
    component: _529af92d,
    name: "auth-reset___en",
    children: [{
      path: ":code?",
      component: _6e684a2c,
      name: "auth-reset-code___en"
    }]
  }, {
    path: "/de/blog/:index",
    component: _d1834aaa,
    name: "blog___de"
  }, {
    path: "/de/campaigns/:campaignSlug",
    component: _1a8c0bc8,
    name: "campaigns-campaignSlug___de"
  }, {
    path: "/de/register/:code",
    component: _8bdfcc46,
    name: "registerMember___de"
  }, {
    path: "/de/restaurant/:branchSlug?",
    component: _2458efbf,
    name: "restaurant-branchSlug___de"
  }, {
    path: "/en/blog/:index",
    component: _d1834aaa,
    name: "blog___en"
  }, {
    path: "/en/campaigns/:campaignSlug",
    component: _1a8c0bc8,
    name: "campaigns-campaignSlug___en"
  }, {
    path: "/en/register/:code",
    component: _8bdfcc46,
    name: "registerMember___en"
  }, {
    path: "/en/restaurant/:branchSlug?",
    component: _2458efbf,
    name: "restaurant-branchSlug___en"
  }, {
    path: "/de/campaigns/:campaignSlug?/:campaignContent",
    component: _40b09ca0,
    name: "campaigns-campaignSlug-campaignContent___de"
  }, {
    path: "/en/campaigns/:campaignSlug?/:campaignContent",
    component: _40b09ca0,
    name: "campaigns-campaignSlug-campaignContent___en"
  }, {
    path: "/blog/:index",
    component: _d1834aaa,
    name: "blog___tr"
  }, {
    path: "/campaigns/:campaignSlug",
    component: _1a8c0bc8,
    name: "campaigns-campaignSlug___tr"
  }, {
    path: "/register/:code",
    component: _8bdfcc46,
    name: "registerMember___tr"
  }, {
    path: "/restaurant/:branchSlug?",
    component: _2458efbf,
    name: "restaurant-branchSlug___tr"
  }, {
    path: "/campaigns/:campaignSlug?/:campaignContent",
    component: _40b09ca0,
    name: "campaigns-campaignSlug-campaignContent___tr"
  }, {
    path: "/",
    component: _8bdfcc46,
    name: "index___tr"
  }],
  /* eslint-enable array-bracket-spacing, quotes, quote-props, object-curly-spacing, key-spacing */

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
