var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.isBaseDomain ? _c("layoutBase") : _c("layoutDomain"),
      _vm._v(" "),
      _c("order-rating"),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "loginModal",
          attrs: {
            "content-class":
              (_vm.authModal.hiddenClose ? "custom-modal-1-close-h-btn" : "") +
              " " +
              (_vm.authModal.component === "loginForm"
                ? "custom-modal-1-visible custom-modal-1-mask-image-none"
                : "") +
              " custom-modal-1 user-login-register",
            "hide-footer": "",
            centered: "",
            "no-close-on-backdrop": _vm.authModal.noClose,
            "no-close-on-esc": _vm.authModal.noClose
          },
          on: { hidden: _vm.handleHiddenModal },
          model: {
            value: _vm.authModal.show,
            callback: function($$v) {
              _vm.$set(_vm.authModal, "show", $$v)
            },
            expression: "authModal.show"
          }
        },
        [
          _c("template", { slot: "modal-title" }, [
            _vm._v(" " + _vm._s(_vm.$t(_vm.authModal.title)) + " ")
          ]),
          _vm._v(" "),
          _c(
            "client-only",
            [
              _c(
                _vm.authModal.component,
                _vm._b(
                  {
                    tag: "component",
                    attrs: { "is-modal": "" },
                    on: {
                      success: _vm.handleLoginSuccess,
                      close: _vm.handleLoginClose
                    }
                  },
                  "component",
                  _vm.authModal.data,
                  false
                )
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "locationModal",
          attrs: {
            title: _vm.$t("Konumunuzu Ayarlayın"),
            size: "lg",
            "content-class": "custom-modal-1",
            "modal-class": "location-map-modal",
            centered: "",
            scrollable: "",
            "hide-footer": ""
          },
          on: { hidden: _vm.handleHiddenLocateModal },
          model: {
            value: _vm.locateModal.show,
            callback: function($$v) {
              _vm.$set(_vm.locateModal, "show", $$v)
            },
            expression: "locateModal.show"
          }
        },
        [
          _c(_vm.locateModal.component, {
            tag: "component",
            attrs: { show: _vm.locateModal.show },
            on: { handleHiddenLocateModal: _vm.handleHiddenLocateModal }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }