import Vue from 'vue'
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  configure
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

configure({
  events: 'blur'
})
Object.keys(rules).forEach(rule => {
  // eslint-disable-next-line import/namespace
  extend(rule, rules[rule])
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
