var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "zz-div",
    { attrs: { loading: _vm.loading } },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.handleFormSubmit)
                      }
                    }
                  },
                  [
                    _c("validation-provider", {
                      attrs: { name: _vm.$t("Telefon"), rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "b-form-group",
                                  { attrs: { "label-for": "input-phone" } },
                                  [
                                    _c("phoneNumberInput", {
                                      attrs: {
                                        id: "input-phone",
                                        disabled: _vm.loading,
                                        state: _vm.state(errors[0]),
                                        required: "",
                                        "border-radius": 2,
                                        "default-country-code": "TR",
                                        "no-example": "",
                                        "no-flags": "",
                                        translations: {
                                          phoneNumberLabel: _vm.$t("Telefon"),
                                          countrySelectorLabel: _vm.$t(
                                            "Ülke Kodu"
                                          )
                                        }
                                      },
                                      on: {
                                        update: function(p) {
                                          return (_vm.form["phone"] =
                                            p.formattedNumber)
                                        }
                                      },
                                      model: {
                                        value: _vm.model.phone,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "phone", $$v)
                                        },
                                        expression: "model.phone"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "fs12-1 rezzta-red white--text",
                            attrs: { disabled: _vm.loading, type: "submit" }
                          },
                          [
                            _vm.loading
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _vm._e(),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("Kaydet")) +
                                "\n        "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }