<template>
  <div>
    <!-- <myrezztaHeader /> -->
    <nuxt></nuxt>
  </div>
</template>

<script>
import { postFrontError } from '@/api/frontError'
// import myrezztaHeader from '@/layouts/header/myrezztaHeader'
export default {
  name: 'LayoutMyrezzta',
  scrollToTop: true,
  errorCaptured(err, vm, info) {
    this.$axios({
      ...postFrontError(),
      data: {
        data: {
          message: err.message || '',
          stack: err.stack || '',
          info,
          url: window.location.href
        }
      }
    })
  },
  // components: { myrezztaHeader },
  computed: {},
  mounted() {},
  methods: {},
  head() {
    return {
      title: this.$t('Myrezzta Hesabınızı Oluşturun'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Myrezzta Hesabınızı Oluşturun'
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          href: '/favicon-76x76.png'
        }
      ]
    }
  }
}
// MyRezzta logolu boş layout
</script>
