<template>
  <div
    class="header header-w"
    :class="[
      {
        'header-w-location': headerAbsolute
      }
    ]"
  >
    <smartAppBanner
      v-if="!$store.state.isDemo"
      :data="smartAppBannerData"
    ></smartAppBanner>
    <div>
      <div class="location-bar bg-white d-block">
        <div class="container py-1">
          <a
            href="#"
            @click.prevent="$store.dispatch('auth/showLocationModal')"
          >
            <span class="common-icon" v-html="icons.location1"></span>
            <b>
              {{ getShortAddress() }}
            </b>
            <span class="down-custom-icon"></span>
          </a>
        </div>
      </div>
      <!-- <div class="loading-location-bar location-bar loading-common">
        <div
          class="loading-location-bar-content loading-back loading-anim"
        ></div>
      </div> -->
    </div>
    <b-navbar toggleable="lg" class="header-nav shadow-sm">
      <div class="container">
        <div class="header-nav-content">
          <b-navbar-brand :to="localePath('index')">
            <img
              alt="logo"
              height="38"
              style="max-height:38px;"
              src="/images/logoB.png"
            />
            <!-- <img
              alt="logo"
              height="30"
              style="max-height: 30px;"
              src="/images/rezzta.svg"
            /> -->
          </b-navbar-brand>
          <a
            href=""
            class="about-rezzta"
            @click.prevent="blogHandler('demo-rezzta-description')"
          >
            {{ $t('REZZTA HAKKINDA') }}
          </a>
          <span
            class="common-icon map-mobil"
            @click="$store.dispatch('auth/showLocationModal')"
          >
            <svg
              id="Capa_1"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              xml:space="preserve"
            >
              <path
                class="st0"
                d="M256,61.53c-76.57,0-138.64,62.07-138.64,138.64S256,450.47,256,450.47s138.64-173.73,138.64-250.3
                      S332.57,61.53,256,61.53z M256,264.25c-38.82,0-70.28-31.47-70.28-70.28c0-38.82,31.47-70.28,70.28-70.28s70.28,31.47,70.28,70.28
                      C326.28,232.79,294.82,264.25,256,264.25z"
              />
            </svg>
            <small class="map-icon-wrapper"> </small>
          </span>
          <div class="header-search-w">
            <searchLine />
          </div>
          <b-navbar-toggle target="nav-collapse">
            <span class="navbar-toggler-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span
              v-show="$store.state.auth.notificationUnreadCount > 0"
              class="badge badge-success menu-w-badge"
            >
              {{ $store.state.auth.notificationUnreadCount }}
            </span>
          </b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item :to="localePath('index')" style="display:none;">
                <span>
                  {{ $t('Anasayfa') }}
                </span>
              </b-nav-item>
              <!-- <b-nav-item
                class="d-block d-sm-none"
                @click="$store.dispatch('auth/showLocationModal')"
              >
                <span class="common-icon" v-html="icons.map"></span>
                <span>
                  {{ $t('Konum Seç') }}
                </span>
              </b-nav-item> -->
              <template v-if="$store.state.auth.isLogged">
                <b-nav-item-dropdown
                  class="profil-h-w"
                  menu-class="shadow-sm border-0"
                  right
                >
                  <template v-slot:button-content>
                    <span class="common-icon" v-html="icons.profil1"></span>
                    <span>
                      {{ $t('Profil') }}
                    </span>
                    <span
                      v-show="$store.state.auth.notificationUnreadCount > 0"
                      class="badge badge-success custom-h-badge"
                    >
                      {{ $store.state.auth.notificationUnreadCount }}
                    </span>
                  </template>
                  <div class="profil-menu-head">
                    <img
                      :src="$store.state.auth.info.profile_image"
                      class="nav-osahan-pic rounded-pill"
                      onerror="this.style.display='none'"
                    />
                    <span>
                      {{ $store.state.auth.info.name }}
                    </span>
                  </div>
                  <b-dropdown-item :to="localePath('me')">
                    <span class="common-icon" v-html="icons.profil2"></span>
                    <span>
                      {{ $t('Profilim') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-orders')">
                    <span class="common-icon" v-html="icons.order"></span>
                    <span>
                      {{ $t('Siparişlerim') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-comments')">
                    <span class="common-icon" v-html="icons.comment"></span>
                    <span>
                      {{ $t('Yorumlarım') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-addresses')">
                    <span class="common-icon" v-html="icons.address"></span>
                    <span>
                      {{ $t('Adreslerim') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-notifications')">
                    <span
                      class="common-icon"
                      v-html="icons.notification"
                    ></span>
                    <span>
                      {{ $t('Bildirimlerim') }}
                    </span>
                    <span
                      v-show="$store.state.auth.notificationUnreadCount > 0"
                      class="badge badge-success"
                    >
                      {{ $store.state.auth.notificationUnreadCount }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-favorites')">
                    <span class="common-icon" v-html="icons.favorite"></span>
                    <span>
                      {{ $t('Favorilerim') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-wallet')">
                    <span class="common-icon" v-html="icons.wallet"></span>
                    <span>
                      {{ $t('Cüzdanım') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-giftCards')">
                    <span class="common-icon" v-html="icons.giftCard"></span>
                    <span>
                      {{ $t('Hediye Çeklerim') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="localePath('me-reference')">
                    <span class="common-icon" v-html="icons.reference"></span>
                    <span>
                      {{ $t('Referans Sistemi') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleLogout">
                    <span class="common-icon" v-html="icons.exit"></span>
                    <span>
                      {{ $t('Çıkış Yap') }}
                    </span>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </template>
              <template v-else>
                <b-nav-item @click="$store.dispatch('auth/showLoginModal')">
                  <span class="common-icon" v-html="icons.login"></span>
                  <span>
                    {{
                      $store.state.isDemo ? $t('Demo Kullanıcı') : $t('Giriş')
                    }}
                  </span>
                </b-nav-item>
                <li v-if="$store.state.isDemo" class="nav-item">
                  <a :href="branchLink" target="__blank">
                    <span class="common-icon" v-html="icons.login"></span>
                    <span>
                      {{ $t('Demo Restoran') }}
                    </span>
                  </a>
                </li>
                <b-nav-item v-if="!$store.state.isDemo">
                  <span class="common-icon" v-html="icons.register"></span>
                  <span>
                    {{ $t('Kayıt Ol') }}
                  </span>
                </b-nav-item>
              </template>
              <basket-item v-if="$store.state.auth.isLogged"></basket-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </div>
    </b-navbar>

    <b-modal
      v-model="blog.modal"
      size="lg"
      centered
      scrollable
      hide-footer
      content-class="custom-modal-1"
    >
      <div class="px-4 pt-2 pb-4">
        <h5 class="text-center">{{ blog.textTitle }}</h5>
        <blogArea
          :slug="blog.blogSlug"
          style="margin-top: -20px"
          @title="blog['textTitle'] = $event"
        ></blogArea>

        <!-- <i18n path="home_message_2" tag="div"> </i18n> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import smartAppBanner from '@/components/smartAppBanner'
import trafficMixin from '@/mixins/traffic'
import searchLine from '@/components/home/searchBlock/searchLine'
import blogArea from '@/pages/blog/_index'
import {
  location1,
  map,
  profil1,
  profil2,
  order,
  address,
  notification,
  favorite,
  wallet,
  giftCard,
  reference,
  exit,
  comment,
  login,
  register,
  search
} from '@/assets/icon/index.js'
import basketItem from './items/basket'

export default {
  name: 'DefaultHeader',
  components: {
    basketItem,
    smartAppBanner,
    searchLine,
    blogArea
  },
  mixins: [trafficMixin],
  props: {
    headerAbsolute: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons: {
        location1,
        map,
        profil1,
        profil2,
        order,
        address,
        notification,
        favorite,
        wallet,
        giftCard,
        reference,
        exit,
        comment,
        login,
        register,
        search
      },
      blog: {
        modal: false,
        blogSlug: null,
        textTitle: null
      }
    }
  },
  computed: {
    ...mapGetters({
      getBlogAreaSlug: 'getBlogAreaSlug'
    }),
    branchLink() {
      return this.$axios.defaults.baseURL === 'http://rezzta-api/api'
        ? 'http://rezzta-management/login?user=demo'
        : this.$axios.defaults.baseURL === 'https://api.rezzta.com'
        ? 'https://panel.rezzta.com/login?user=demo'
        : 'https://rezztatestpanel.akinsoft.com.tr/login?user=demo'
    },
    authModal() {
      const authModal = JSON.parse(JSON.stringify(this.$store.state.auth.modal))

      return authModal
    },
    locateModal() {
      return JSON.parse(JSON.stringify(this.$store.state.auth.locationModal))
    },
    smartAppBannerData() {
      return {
        ...process.env.smartAppBannerData,
        title: this.$t('Rezzta Online Yemek Siparişi')
      }
    },
    isLocation() {
      if (this.$store.state.auth.location?.length === 2) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(name) {
        if (name && name.includes('registerMember')) {
          if (this.$store.state.auth.isLogged) {
            this.$router.push(
              this.localePath({
                name: 'Index'
              })
            )
          } else {
            this.$store.dispatch('auth/showRegisterModal')
          }
        }
      }
    }
  },
  methods: {
    blogHandler(name) {
      this.blog.blogSlug = this.getBlogAreaSlug(name)
      this.blog.modal = true
    },
    // Çıkış yap tıklandı
    async handleLogout() {
      await this.$store.dispatch('auth/logout')
      await this.$store.dispatch('basket/getBasket')
    },
    // Seçilen adresin kısaltılmış hali
    getShortAddress() {
      if (!this.$store.state.auth.locationAddress)
        return this.$t('Konum bilgisi alınamadı')

      try {
        const address = { ...this.$store.state.auth.locationAddress }

        delete address.country_code
        delete address.country
        delete address.state
        delete address.state_district
        delete address.postcode
        delete address.road
        delete address.house_number
        delete address.region
        delete address.province

        const r = Object.keys(address).map(i => {
          return address[i]
        })

        return r.join(' / ')
      } catch (error) {
        return this.$t('Konum bilgisi alınamadı')
      }
    }
  },
  head() {
    return {
      link: [{ rel: 'icon', type: 'image/png', href: '/favicon-76x76.png' }]
    }
  }
}
</script>

<style scoped lang="scss">
.about-rezzta {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ffc300 100%
  );
  padding: 4px 6px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 4px;
  font-size: calc(12px + 0.1vw);
  font-weight: 500;
  display: inline-flex;
  margin: 0 8px;
  font-weight: 500;
  white-space: nowrap;
}
</style>
