import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

export default async (ctx, inject) => {
  const runtimeConfig = ctx.$config && ctx.$config.googleAnalytics || {}
  const moduleOptions = {"dev":true,"debug":{"sendHitTask":false},"asyncID":context => {
      if (context.store.state.isBaseDomain) {
        return 'UA-25160894-11'
      } else if (
        context.store.state.company &&
        context.store.state.company.settings &&
        context.store.state.company.settings.site &&
        context.store.state.company.settings.site.googleAnalytics &&
        context.store.state.company.settings.site.googleAnalytics
          .google_analytics_id
      ) {
        return context.store.state.company.settings.site.googleAnalytics
          .google_analytics_id
      } else {
        return null
      }
    }}
  const options = {...moduleOptions, ...runtimeConfig}

  if (typeof options.asyncID === 'function') {
    options.id = await options.asyncID(ctx)
  }

  Vue.use(VueAnalytics, {...{ router: ctx.app.router }, ...options})

  ctx.$ga = Vue.$ga
  inject('ga', Vue.$ga)
}
