export const login = () => {
  return {
    url: `/member/login`,
    method: 'post'
  }
}

export const info = () => {
  return {
    url: `/member/get-member-info`,
    method: 'get'
  }
}

export const checkToken = () => {
  return {
    url: `/member/check-token`,
    method: 'post'
  }
}

export const refresh = () => {
  return {
    url: `/member/refresh`,
    method: 'post'
  }
}

export const anonymLogin = () => {
  return {
    url: `/member/anonymous/login`,
    method: 'post'
  }
}

export const facebookLogin = () => {
  return {
    url: `/facebook/login`,
    method: 'post'
  }
}
