var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-center justify-center mb-4 gap-2" },
      _vm._l(_vm.types, function(type, index) {
        return _c(
          "button",
          {
            key: index,
            staticClass:
              "btn btn-block btn-login font-weight-medium my-0 fs12-1 pa-1",
            class: [
              {
                "text--rezzta-red rezzta-grey-2": _vm.selectedType !== type.name
              },
              { "rezzta-green-1 white--text": _vm.selectedType === type.name }
            ],
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.selectedType = type.name
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t(type.title)) + "\n    ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", [_c(_vm.selectedType, { tag: "component" })], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }