<template>
  <div v-if="show" :class="`smartbanner smartbanner-${osName}`">
    <div class="smartbanner-container">
      <a href="#" class="smartbanner-close" @click.prevent="handleClick">
        &times;
      </a>
      <span
        class="smartbanner-icon"
        :style="{ 'background-image': `url(${icon})` }"
      />
      <div class="smartbanner-info">
        <div class="smartbanner-title">
          {{ title }}
        </div>
      </div>
      <a :href="downloadUrl" class="smartbanner-button">
        <span class="smartbanner-button-text">
          {{ $t('İNDİR') }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import trafficMixin from '@/mixins/traffic'

export default {
  name: 'SmartAppBanner',
  mixins: [trafficMixin],
  props: { data: { type: Object, default: null } },
  data() {
    return {
      show: false,
      osName: 'android',
      downloadUrl: '',
      icon: '',
      title: ''
    }
  },
  mounted() {
    if (!localStorage.getItem('smartApp') && this.$device.isMobileOrTablet) {
      this.icon = this.data.icon
      this.title = this.data.title

      if (this.$device.isAndroid) {
        this.downloadUrl = this.data.androidLink
        this.osName = 'android'
      } else {
        this.downloadUrl = this.data.iosLink
        this.osName = 'ios'
      }

      if (this.icon && this.downloadUrl) this.show = true
    }
  },
  methods: {
    handleClick() {
      this.show = false
      localStorage.setItem('smartApp', new Date())
    }
    // handleTraffic() {
    //   this.postTrafficMethod({
    //     type: 'download'
    //   })
    // }
  }
}
</script>

<style></style>
