import Vue from 'vue'

const moment = require('moment')

export default function({ $axios, store, redirect, app }) {
  const lang = store.state.auth.lang

  moment.locale(lang || 'tr')
}

Vue.prototype.$moment = moment
// TODO: Localize ayarlanacak
