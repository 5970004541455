<template>
  <div class="container" style="min-height:calc(100vh - 350px);">
    <div
      class="bg-white p-2 p-sm-2 p-md-4 p-lg-4 p-xl-4 my-4 blog-wrapper"
      v-html="description"
    ></div>
  </div>
</template>

<script>
export default {
  layout: 'notRequiredLocation',
  props: {
    slug: {
      type: [Number, String],
      default: 0
    },
    address: {
      type: Object,
      default: () => {}
    },
    blogName: {
      type: String,
      default: 'normalDescription'
    }
  },
  data() {
    return {
      title: '',
      description: '',
      descriptionType: null
    }
  },
  mounted() {
    // 'slug' dışarıdan gelirse null veya undefined gelir
    if ([null, undefined].includes(this.slug) && !this.$route.params?.index) {
      return
    }

    this.$store
      .dispatch(
        'getCompanyBlogContentData',
        this.slug || this.$route.params.index
      )
      .then(data => {
        this.title = data.title
        this.description = this.contextDescription(data.description)
        this.$emit('title', data.title)
      })
      .catch(() => {
        this.$notification.error(
          this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      })
  },
  methods: {
    contextDescription(description) {
      const blogObj = {
        'sales-agreement': 'salesAgreement'
      }

      this.blogName = blogObj[this.blogName] || this.blogName

      return (
        (this[this.blogName] && this[this.blogName](description)) ||
        this.normalDescription(description)
      )
    },
    normalDescription(description) {
      return description
    },
    salesAgreement(description) {
      const findBranch = () => {
        const cookieDomainUrl = this.$cookies.get('domainUrl')

        if (cookieDomainUrl) {
          const branch = this.$store.state?.company?.branch.find(x =>
            cookieDomainUrl.includes(x.slug)
          )

          if (branch) {
            return branch.name || '-'
          } else {
            return this.$store.state?.company?.branch?.length
              ? this.$store.state?.company?.branch[0].name
              : '-'
          }
        } else {
          return this.$store.state?.company?.branch?.length
            ? this.$store.state.company.branch[0]?.name
            : '-'
        }
      }

      const domainVariables = {
        companyname: this.$store.state?.company?.name || '-',
        restaurantname: findBranch(),
        domain: this.$store.state?.domainName || '-',
        address:
          this.$store.state?.company?.settings?.site?.contact?.address || '-',
        tel: this.$store.state?.company?.settings?.site?.contact?.phone || '-',
        email: this.$store.state?.company?.settings?.site?.contact?.email || '-'
      }

      const baseVariables = {
        username: this.address?.contact_person || '-',
        useraddress: this.address?.open_address || '-',
        usertel: this.$store.state.auth?.info?.telephone || '-',
        useremail: this.$store.state.auth?.info?.email || '-'
      }

      baseVariables.useremail = /^.*@myrezzta.com$/.test(
        baseVariables.useremail
      )
        ? '-'
        : baseVariables.useremail

      const variables = { ...baseVariables, ...domainVariables }

      for (const key in variables) {
        description = description.replaceAll(`{${key}}`, variables[key])
      }

      return description
    }
  },
  head() {
    return {
      title: this.id ? false : this.title
    }
  }
}
</script>
