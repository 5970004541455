var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "availableTableModal",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": _vm.noCloseOnBackdrop,
        "no-close-on-esc": _vm.noCloseOnEsc,
        "content-class": "custom-modal-1"
      },
      model: {
        value: _vm.deliveryTypeObj.modal,
        callback: function($$v) {
          _vm.$set(_vm.deliveryTypeObj, "modal", $$v)
        },
        expression: "deliveryTypeObj.modal"
      }
    },
    [
      _c("template", { slot: "modal-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm
                .$t("Masa numarası: {table_no}")
                .replace("{table_no}", _vm.deliveryType.table_no)
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pa-4 delivery-type-modal" }, [
        _c("div", { staticClass: "dtm-type-2" }, [
          _c(
            "p",
            {
              staticClass:
                "font-weight-medium mb-3 fs15-1 text--rezzta-grey-1 text-center"
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.deliveryTypeObj.text) + "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-rezzta-gray mx-auto d-flex",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.deliveryTypeObj.modal = false
                }
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("Tamam")) + "\n      ")]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }