var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$store.state.isDemo
      ? _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "a",
                {
                  staticClass: "text-dark teklif-al",
                  attrs: {
                    href:
                      "https://www.akinsoft.com.tr/as/irtibat/istekoneri?d=rezzta",
                    target: "__blank"
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "Rezzta Hakkında Detaylı Bilgi ve Fiyat Teklifi için tıklayınız."
                        )
                      ) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "footer pt-5 pb-5" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-3 col-12 col-sm-12 mb-3 text-center text-md-left"
            },
            [
              _c("div", { staticClass: "app" }, [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("Rezzta mobil ile restoranlar cebinizde")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$store.state.isDemo
                        ? "#"
                        : "https://itunes.apple.com/us/app/rezzta/id1357638100?ls=1&mt=8",
                      target: _vm.$store.state.isDemo ? "_self" : "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/images/apple.png" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$store.state.isDemo
                        ? "#"
                        : "https://play.google.com/store/apps/details?id=tr.com.akinsoft.rezzta",
                      target: _vm.$store.state.isDemo ? "_self" : "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/images/google.png" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer-language" }, [
                _c("div", { staticClass: "footer-language-icon" }, [
                  _c("span", {
                    staticClass: "common-icon",
                    domProps: { innerHTML: _vm._s(_vm.icons.language) }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footer-language-items" },
                  [
                    _vm.$i18n.locale !== "en"
                      ? _c(
                          "nuxt-link",
                          { attrs: { to: _vm.switchLocalePath("en") } },
                          [_vm._v("\n                EN\n              ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$i18n.locale !== "tr"
                      ? _c(
                          "nuxt-link",
                          { attrs: { to: _vm.switchLocalePath("tr") } },
                          [_vm._v("\n                TR\n              ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$i18n.locale !== "de"
                      ? _c(
                          "nuxt-link",
                          { attrs: { to: _vm.switchLocalePath("de") } },
                          [_vm._v("\n                DE\n              ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer-social" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$store.state.isDemo
                        ? "#"
                        : "https://www.facebook.com/rezzta",
                      target: _vm.$store.state.isDemo ? "_self" : "_blank"
                    }
                  },
                  [_c("fa", { attrs: { icon: ["fab", "facebook"] } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$store.state.isDemo
                        ? "#"
                        : "https://www.instagram.com/rezzta",
                      target: _vm.$store.state.isDemo ? "_self" : "_blank"
                    }
                  },
                  [_c("fa", { attrs: { icon: ["fab", "instagram"] } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$store.state.isDemo
                        ? "#"
                        : "https://www.youtube.com/channel/UC-l89F8USJWIK3z5ak6PFFQ",
                      target: _vm.$store.state.isDemo ? "_self" : "_blank"
                    }
                  },
                  [_c("fa", { attrs: { icon: ["fab", "youtube"] } })],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.$store.state.isDemo
            ? _c(
                "div",
                { staticClass: "col-md-4 offset-md-1 col-12 col-sm-6 mb-3" },
                [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        { attrs: { href: _vm.branchLink, target: "_blank" } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Demo Restoran Portalı")) +
                              "\n              "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "nuxt-link",
                          {
                            attrs: {
                              to: _vm.localePath({
                                name: "blog",
                                params: {
                                  index: _vm.getBlogAreaSlug(
                                    "demo-rezzta-description"
                                  )
                                }
                              })
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("Rezzta Hakkında")) +
                                "\n              "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.$i18n.locale === "en"
                                ? "https://www.akinsoft.com/contact"
                                : "https://www.akinsoft.com.tr/as/irtibat/irtibat.php",
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("İletişim")) +
                              "\n              "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$store.state.isDemo
            ? _c("div", { staticClass: "col-md-2 col-12 col-sm-6 mb-3" }, [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _vm.$i18n.locale !== "en"
                        ? _c(
                            "nuxt-link",
                            { attrs: { to: _vm.switchLocalePath("en") } },
                            [
                              _vm._v(
                                "\n                English\n              "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$i18n.locale !== "tr"
                        ? _c(
                            "nuxt-link",
                            { attrs: { to: _vm.switchLocalePath("tr") } },
                            [_vm._v("\n                Türkçe\n              ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath({
                              name: "blog",
                              params: {
                                index: _vm.getBlogAreaSlug("about")
                              }
                            })
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Rezzta Hakkında")) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath({
                              name: "blog",
                              params: {
                                index: _vm.getBlogAreaSlug(
                                  "demo-rezzta-description"
                                )
                              }
                            })
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Kullanıcı Sözleşmesi")) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href:
                            _vm.$i18n.locale === "en"
                              ? "https://www.akinsoft.com/pdpp.php"
                              : "https://www.akinsoft.com.tr/kisisel-verilerin-korunmasi-kanunu.php"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("Kişisel Verilerin Korunması Kanunu")
                            ) +
                            "\n              "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath({
                              name: "blog",
                              params: {
                                index: _vm.getBlogAreaSlug("cookie")
                              }
                            })
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Çerez Politikası")) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath({
                              name: "blog",
                              params: {
                                index: _vm.getBlogAreaSlug("privacy")
                              }
                            })
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Gizlilik Politikası")) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$store.state.isDemo
            ? _c("div", { staticClass: "col-md-2 col-12 col-sm-6 mb-3" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            _vm.$i18n.locale === "en"
                              ? "https://panel.rezzta.com/home?lang=en"
                              : "https://panel.rezzta.com/home?lang=tr",
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("Restoran Portalı")) +
                            "\n              "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath("staticBlog-registerInfo")
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Üye İş Yeri Kayıt")) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath("staticBlog-suggestRestaurant")
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("Restoran Öner")) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            _vm.$i18n.locale === "en"
                              ? "https://www.akinsoft.com/contact"
                              : "https://www.akinsoft.com.tr/as/irtibat/irtibat.php",
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("İletişim")) +
                            "\n              "
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-12 col-sm-12 mb-3 footer-contact",
              class: { "mx-auto": _vm.$store.state.isBaseDomain }
            },
            [
              _c("ul", [
                !_vm.$store.state.isDemo
                  ? _c("li", [
                      _c(
                        "span",
                        [_c("fa", { attrs: { icon: "envelope" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-dark",
                          attrs: { href: "mailto:bilgi@rezzta.com" }
                        },
                        [
                          _vm._v(
                            "\n                bilgi@rezzta.com\n              "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$store.state.isDemo
                  ? _c("li", [
                      _c("span", [_c("fa", { attrs: { icon: "phone" } })], 1),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "text-dark",
                          attrs: { href: "tel:08504414000" }
                        },
                        [
                          _vm._v(
                            "\n                0 850 441 4000\n              "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$store.state.isDemo
                  ? _c("li", [
                      _c(
                        "span",
                        [_c("fa", { attrs: { icon: "map-marker-alt" } })],
                        1
                      ),
                      _vm._v(
                        "\n              İstanbul Yolu, AKINSOFT Plaza No:103 42070\n              Selçuklu/Konya/TÜRKİYE\n            "
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "pt-4 pb-4 text-center" },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("p", { staticClass: "mt-0 mb-0" }, [
              _vm._v("© Copyright " + _vm._s(new Date().getFullYear()) + ".")
            ]),
            _vm._v(" "),
            _c(
              "i18n",
              {
                staticClass: "mt-0 mb-0",
                attrs: { path: "akinsoft_footer", tag: "small" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "text-danger",
                    attrs: {
                      target: "_blank",
                      href: "https://www.akinsoft.com/"
                    }
                  },
                  [_vm._v("\n          AKINSOFT\n        ")]
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "client-only",
          [
            _c(
              "cookie-law",
              {
                attrs: {
                  "button-text": _vm.$t("Tamam"),
                  theme: "custom-cookie"
                }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "message" }, slot: "message" },
                  [
                    _c(
                      "i18n",
                      {
                        staticClass: "text-underline",
                        attrs: {
                          path: "cookie_policy_read_and_aggre_tos",
                          tag: "span"
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.lawModal("cookie")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("Çerez Politikamızı")) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.lawModal("privacy")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("Gizlilik Politikamızı")) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              size: "lg",
              centered: "",
              "content-class": "custom-modal-1",
              lazy: "",
              "ok-only": "",
              "ok-title": _vm.$t("Kapat")
            },
            model: {
              value: _vm.law.modal,
              callback: function($$v) {
                _vm.$set(_vm.law, "modal", $$v)
              },
              expression: "law.modal"
            }
          },
          [
            _c("blogArea", {
              staticStyle: { "margin-top": "-20px" },
              attrs: { slug: _vm.law.blogSlug }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }