var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative zz-div" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "loading-wrap",
          style: { position: _vm.fixed ? "fixed" : "absolute" }
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex justify-content-center align-items-center h-100" },
      [
        _c(
          "div",
          {
            staticClass: "spinner-border text-danger",
            attrs: { role: "status" }
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }