<template>
  <div style="height:100%;width:100%; position:relative">
    <GmapMap
      ref="gmapComponent"
      :options="options"
      :center="center"
      :zoom="zoom"
      :style="mapStyle"
    >
      <GmapMarker v-if="coords" :position="marker" />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'ZzMap',
  props: {
    coords: {
      type: [Object, Array],
      default: null
    },
    zoom: {
      type: Number,
      default: 17
    }
  },
  data() {
    return {
      options: {
        streetViewControl: false
      },
      mapStyle: {
        width: '100%',
        height: '100%'
      }
    }
  },
  computed: {
    marker() {
      if (this.coords) {
        if (Array.isArray(this.coords)) {
          return {
            lat: this.coords[0],
            lng: this.coords[1]
          }
        }
      }

      return this.coords
    },
    center() {
      if (this.coords) {
        if (Array.isArray(this.coords)) {
          return {
            lat: this.coords[0],
            lng: this.coords[1]
          }
        }
      }

      return {
        lat: 37.978168,
        lng: 32.51318
      }
    }
  },
  methods: {
    invalidateSize() {
      // Modal görünmeme sorunu için
      this.mapStyle.width = '99%'
      setTimeout(() => {
        this.mapStyle.width = '100%'
      }, 200)
    }
  }
}
</script>

<style></style>
