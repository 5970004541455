<template>
  <div class="error-500">
    <img src="/images/500.jpg" />
    <div class="text">
      <h1 class="mb-2">
        {{ $t('Bir şeyler ters gitti!') }}
      </h1>
      <p>
        {{
          $t(
            'Özür dileriz. İşlem sırasında bir hata oluştu. Hata kaydını aldık. En kısa sürede çözüm sağlanacaktır.'
          )
        }}
      </p>
      <h4 class="mt-md-5">
        {{ error.message }}
      </h4>
    </div>
    <nuxt-link
      v-if="$store.state.isBaseDomain"
      class="btn btn-primary btn-lg"
      :to="localePath('index')"
    >
      {{ $t('Anasayfaya Dön') }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'Error500',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.error-500 {
  position: relative;
}
.text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  h1,
  h4,
  p {
    color: #fff;
  }
  @media (max-width: 767px) {
    h1 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1rem;
    }
  }
}
img {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px 5px;
  width: 80%;
  height: auto;
}
@media only screen and (max-width: 1000px) {
  img {
    width: 100%;
  }
}
</style>
