export default {
  data() {
    return {
      loading: false,
      basket: {
        piece: 0,
        restaruantName: '',
        price: {
          net: 0, // Sepet Tutarı
          withoutDiscount: 0, // Sepet Tutarı(İndirim Hariç)
          extra: 0, // Malzeme, Ürün Grubu(material + selectGroup)
          discount: 0, // İndirim Tutarı
          material: 0, // Malzemelerin toplamı
          selectGroup: 0 // Ürün grubunda seçilen ürünün fark fiyatı
        },
        items: [],
        form: {
          piece: {}
        }
      }
    }
  },
  mounted() {
    if (this.$store.state.basket.count) {
      this.parseBasket(this.$store.state.basket.items)
      this.parsePrice(this.$store.state.basket.price)
      this.currency = this.$store.state.basket.restaurant.currency
    }
  },
  methods: {
    // price,basket
    // eslint-disable-next-line camelcase
    parseBasketData({ price, basket, restaruant_name }) {
      if (price) {
        this.basket.piece = price.piece || 0
        // eslint-disable-next-line camelcase
        this.basket.restaruantName = restaruant_name
        this.parsePrice(price)
      }

      if (basket && basket.length) {
        this.parseBasket(basket)
      }
    },
    parseBasket(basket) {
      const items = basket.map(item => {
        const model = {
          price: {
            net: 0, // Tutar(Tüm fiyatlar dahil)
            withoutDiscount: 0, // Birim Tutar(İndirim Hariç)
            totalWithoutDiscount: 0, // Adet çarpı tutar(İndirim Hariç)
            product: 0, // Malzeme ve Grup hariç ürünün kendi fiyatı
            extra: 0, // Malzeme, Ürün grubu(material + selectgroup)
            discount: 0, // İndirim tutarı
            material: 0, // Malzemelerin toplamı
            selectGroup: 0 // Ürün grubunda seçilen ürünün fark fiyatı
          },
          name: '', // Ürün adı
          content: '', // Ürün içeriği
          uuid: '',
          piece: 0 // Ürün adeti
        }

        model.name = item.name
        model.uuid = item.uuid

        if (item.material_groups && item.material_groups.length) {
          model.content += this.getMaterialsDesc(item.material_groups)
        }

        if (item.products && item.products.length) {
          model.content += this.getProductsDesc(item.products)
        }

        if (item.product_groups && item.product_groups.length) {
          model.content += this.getProductGroupsDesc(item.product_groups)
        }

        model.piece = parseInt(item.piece)

        const { price } = item

        model.price.net = parseFloat(price.total_price || 0)
        model.price.product = parseFloat(price.net_price || 0)

        model.price.withoutDiscount = model.price.net
        model.price.discount = 0

        if (price.is_discount) {
          model.price.withoutDiscount = parseFloat(
            price.total_price_without_discount || 0
          )
          model.price.discount = parseFloat(price.discount_price || 0)
        }

        model.price.totalWithoutDiscount =
          model.price.withoutDiscount * model.piece
        model.price.material = parseFloat(price.material_price || 0)
        model.price.selectGroup = parseFloat(price.add_price || 0)
        model.price.extra = parseFloat(
          model.price.material + model.price.selectGroup
        )

        if (!this.basket.form.piece?.[model.uuid]) {
          this.basket.form.piece[model.uuid] = model.piece
        }

        return model
      })

      this.basket.items = items
    },
    parsePrice(price) {
      this.basket.price.net = parseFloat(price.net_price || 0)

      this.basket.price.withoutDiscount = this.basket.price.net
      this.basket.price.discount = 0

      if (price.is_discount) {
        // İndirim uygulandıysa
        this.basket.price.withoutDiscount = parseFloat(
          price.price_without_discount || 0
        )
        this.basket.price.discount = parseFloat(price.discount_price || 0)
      }

      this.basket.price.material = parseFloat(price.material_price || 0)
      this.basket.price.selectGroup = parseFloat(price.add_price || 0)
      this.basket.price.extra = parseFloat(
        this.basket.price.material + this.basket.price.selectGroup
      )
    },
    async handleClearBasket() {
      try {
        this.loading = true
        await this.$store.dispatch('basket/clearBasket')
        // this.$notification.success(message)
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteBasketItem(uuid) {
      try {
        this.loading = true
        await this.$store.dispatch('basket/deleteBasketItem', uuid)
        // this.$notification.success(message)
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    async handleChangeBasketItemPiece(uuid) {
      try {
        this.loading = true

        const data = { piece: this.basket.form.piece[uuid], uuid }

        await this.$store.dispatch('basket/patchBasketItemPiece', data)
        // this.$notification.success(message)
      } catch (err) {
        this.$store.dispatch('basket/getBasket').then(res => {
          if (res?.basket && Array.isArray(res.basket)) {
            const findItem = res.basket.find(b => b.uuid === uuid)
            if (findItem) this.basket.form.piece[uuid] = findItem.piece
          }
        })
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    getMaterialsDesc(groups) {
      const result = groups
        .map(group => {
          return (
            group.materials &&
            `${group.name}: ` +
              group.materials
                .map(material => {
                  return `${material.name}`
                })
                .join(',')
          )
        })
        .join(' - ')

      return result
    },
    getProductsDesc(products) {
      const result = products
        .map(product => {
          if (product.material_groups && product.material_groups.length) {
            return (
              `[${product.name}] ` +
              this.getMaterialsDesc(product.material_groups)
            )
          } else {
            return product.name
          }
        })
        .join(' - ')

      return result
    },
    getProductGroupsDesc(group) {
      const result = group
        .map(group => {
          if (group.product) {
            return (
              `[${group.name}](${group.product.name}) ` +
              this.getMaterialsDesc(group.product.material_groups)
            )
          } else {
            return `${group.name}`
          }
        })
        .join(' - ')

      return result
    }
  }
}
