<template>
  <div>
    <div class="d-flex align-center justify-center mb-4 gap-2">
      <button
        v-for="(type, index) in types"
        :key="index"
        class="btn btn-block btn-login font-weight-medium my-0 fs12-1 pa-1"
        :class="[
          { 'text--rezzta-red rezzta-grey-2': selectedType !== type.name },
          { 'rezzta-green-1 white--text': selectedType === type.name }
        ]"
        @click.prevent="selectedType = type.name"
      >
        {{ $t(type.title) }}
      </button>
    </div>
    <div>
      <component :is="selectedType"></component>
    </div>
  </div>
</template>

<script>
import loginWithEmailForm from '@/components/common/auth/loginWithEmail'
import loginWithPhoneForm from '@/components/common/auth/loginWithPhone'

export default {
  name: 'AuthLoginBoth',
  components: {
    loginWithEmailForm,
    loginWithPhoneForm
  },
  data() {
    return {
      selectedType: 'loginWithEmailForm',
      types: [
        {
          title: 'Email ile giriş yap',
          name: 'loginWithEmailForm'
        },
        {
          title: 'Telefon ile giriş yap',
          name: 'loginWithPhoneForm'
        }
      ]
    }
  }
}
</script>
