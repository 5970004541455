<template>
  <div>
    <div
      v-append="$store.state.company.html"
      class="company-theme-custom"
    ></div>
    <zz-map v-show="false" class="zzMap" style="height:400px" />
  </div>
</template>

<script>
import zzMap from '@/components/zzMap/default'

export default {
  name: 'DomainCmsHome',
  components: {
    zzMap
  },
  data() {
    return {
      languages: [],
      defaultLanguages: [
        {
          lang: 'tr',
          label: 'Türkçe',
          text: '',
          active: true
        },
        {
          lang: 'en',
          label: 'English',
          text: '',
          active: false
        },
        {
          lang: 'de',
          label: 'Deutsch',
          text: '',
          active: false
        }
      ]
    }
  },
  mounted() {
    if (
      this.$store.state.company.html &&
      !this.$store.state.isBaseDomain &&
      !this.isUrl
    ) {
      this.$nextTick(() => {
        if (this.$store.state.company.languages) {
          this.languages = JSON.parse(this.$store.state.company.languages)
        }

        this.selectLanguage()
        this.setLanguage()
        this.workingHour()
        setTimeout(() => {
          this.mapInit()
        }, 1500)
      })
    }
  },
  methods: {
    workingHour() {
      const hours = document.querySelectorAll('.themeR3')
      const tzOffsetMinute = new Date().getTimezoneOffset()

      hours.forEach(hour => {
        if (
          hour.textContent &&
          /^([0-1]?[0-9]|2[0-3]):[0-5][0-9][\s\/]+([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g.test(
            hour.textContent
          ) &&
          hour.hasAttribute('tzoffsetminute')
        ) {
          const hourSplit = hour.textContent.split(/[\s/]+/g)
          const branchTzOffset = hour.getAttribute('tzoffsetminute')
          const offsetDiff = +tzOffsetMinute * -1 - +branchTzOffset

          const o = this.$moment(hourSplit[0], 'HH:mm')
            .add(offsetDiff, 'minutes')
            .format('HH:mm')

          const c = this.$moment(hourSplit[1], 'HH:mm')
            .add(offsetDiff, 'minutes')
            .format('HH:mm')

          hour.textContent = o + ' / ' + c
        } else {
          hour.textContent = '-/-'
        }
      })
    },
    mapInit() {
      const dataMaps = document.querySelectorAll('.restaurantMapContent')

      dataMaps.forEach(dataMap => {
        if (dataMap.hasAttribute('coord')) {
          dataMap.innerHTML = ''

          const map = new google.maps.Map(dataMap, {
            zoom: 15,
            center: JSON.parse(dataMap.getAttribute('coord'))
          })

          const map2 = new google.maps.Marker({
            position: JSON.parse(dataMap.getAttribute('coord')),
            map,
            title: dataMap.getAttribute('restaurant-name') || ''
          })
        }
      })
    },
    selectLanguage() {
      const languagedatas = document.querySelectorAll('[languagedata]')

      languagedatas.forEach(languagedata => {
        languagedata.addEventListener('click', () => {
          let prevLang = null

          languagedatas.forEach(x => {
            if (x.classList.contains('theme-language-active')) {
              prevLang = x.getAttribute('languagedata')
              x.classList.remove('theme-language-active')
            }
          })

          const newLang = languagedata.getAttribute('languagedata')

          languagedata.classList.add('theme-language-active')

          if (prevLang !== newLang) {
            this.$router.push(this.switchLocalePath(newLang))
            setTimeout(() => {
              location.reload()
            }, 700)
          }
        })
      })
    },
    setLanguage(prevLang, newLang) {
      let _prevLang = prevLang
      let _newLang = newLang

      if (!_newLang) {
        const getItem = this.$store.state.auth.lang

        if (getItem) {
          _newLang = getItem
        }
      }

      if (!_prevLang) {
        const findActiveLang =
          this.defaultLanguages.find(x => x.active) || this.defaultLanguages[0]

        if (findActiveLang) {
          _prevLang = findActiveLang.lang
        }
      }

      const languagedatas = document.querySelectorAll('[languagedata]')

      if (_newLang) {
        languagedatas.forEach(languagedata => {
          if (_prevLang) {
            if (languagedata.getAttribute('languagedata') === _prevLang) {
              languagedata.classList.remove('theme-language-active')
            }
          } else {
            languagedatas.forEach(languagedata => {
              if (languagedata.getAttribute('languagedata') !== _newLang) {
                languagedata.classList.remove('theme-language-active')
              }
            })
          }

          if (languagedata.getAttribute('languagedata') === _newLang) {
            languagedata.classList.add('theme-language-active')
          }
        })

        const elems = document.querySelectorAll('#theme-w *')

        elems.forEach(elem => {
          const findA = elem.querySelector('a')

          if (
            findA &&
            findA.getAttribute('href') &&
            /^http(s)?:\/\/.*\/restaurant\//gi.test(findA.getAttribute('href'))
          ) {
            let changedLink = null

            if (_newLang !== 'tr') {
              if (
                !new RegExp(`[\w/.]+${_newLang}\/restaurant`, 'gi').test(
                  findA.getAttribute('href')
                )
              ) {
                changedLink = findA
                  .getAttribute('href')
                  .replace(/(.)\/restaurant/i, `$1/${_newLang}/restaurant`)
              }
            } else {
              changedLink = findA
                .getAttribute('href')
                .replace(new RegExp(`${prevLang}\/`, 'i'), '')
            }

            if (changedLink) {
              findA.setAttribute('href', changedLink)
            }
          }

          const include = {
            classReturn(elem, value) {
              return elem.classList.contains(value)
            },
            attrReturn(elem, value) {
              return elem.hasAttribute(value)
            }
          }

          const parser = elem.outerHTML
            .replace(/\n/g, '')
            .replace(/(\s)+/g, ' ')
            .trim()
            .match(/(<\/|\/>)/g)

          if (
            parser &&
            parser.length === 1 &&
            elem.textContent.trim() &&
            !include.classReturn(elem, 'themeModalClose') &&
            !include.attrReturn(elem, 'languagedata')
          ) {
            elem.textContent = elem.textContent
              .replace(/\n/g, '')
              .replace(/(\s)+/g, ' ')
              .trim()
            this.languages.forEach(language => {
              if (
                language[_prevLang] === elem.textContent &&
                language.type === 'tText' &&
                language[_newLang]
              ) {
                elem.textContent = language[_newLang]
              }
            })
          } else if (elem.hasAttribute('placeholder')) {
            elem.setAttribute(
              'placeholder',
              elem
                .getAttribute('placeholder')
                .replace(/\n/g, '')
                .replace(/(\s)+/g, ' ')
                .trim()
            )
            this.languages.forEach(language => {
              if (
                language[_prevLang] === elem.getAttribute('placeholder') &&
                language.type === 'tPlaceholder' &&
                language[_newLang]
              ) {
                elem.setAttribute('placeholder', language[_newLang])
              }
            })
          }
        })
      } else {
        languagedatas.forEach(languagedata => {
          if (languagedata.classList.contains('theme-language-active')) {
            const data = languagedata.getAttribute('languagedata')

            if (data) {
              this.$router.push(this.switchLocalePath(data))
              setTimeout(() => {
                location.reload()
              }, 700)
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
#theme-language {
  z-index: 99 !important;
}
</style>