var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      staticStyle: { "min-height": "calc(100vh - 350px)" }
    },
    [
      _c("div", {
        staticClass:
          "bg-white p-2 p-sm-2 p-md-4 p-lg-4 p-xl-4 my-4 blog-wrapper",
        domProps: { innerHTML: _vm._s(_vm.description) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }