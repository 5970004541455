var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("zz-div", { attrs: { loading: _vm.loading } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form-material",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.handleMemberDetailSubmit)
                          }
                        }
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: _vm.$t("İsim") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-label-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.name,
                                              expression: "form.name"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: { "is-invalid": errors[0] },
                                          attrs: {
                                            id: "inputName",
                                            placeholder: _vm.$t("İsim"),
                                            disabled: _vm.loading,
                                            maxlength: "100",
                                            type: "text",
                                            required: ""
                                          },
                                          domProps: { value: _vm.form.name },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "inputName" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("İsim")) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("validation-provider", {
                          attrs: { name: _vm.$t("Soyisim") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-label-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.surname,
                                              expression: "form.surname"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: { "is-invalid": errors[0] },
                                          attrs: {
                                            id: "inputSurname",
                                            placeholder: _vm.$t("Soyisim"),
                                            disabled: _vm.loading,
                                            maxlength: "100",
                                            type: "text",
                                            required: ""
                                          },
                                          domProps: { value: _vm.form.surname },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "surname",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "inputSurname" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("Soyisim")) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-lg btn-block text-uppercase font-weight-bold mb-2 rezzta-red white--text",
                            attrs: { disabled: _vm.loading, type: "submit" }
                          },
                          [
                            _vm.loading
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("Kaydet")) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }