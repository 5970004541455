<template>
  <zz-div :loading="loading">
    <div class="row">
      <div class="col-md-12">
        <p class="mb-2">
          {{
            $t(
              'Lütfen telefonunuza gönderilen 6 haneli doğrulama kodunu giriniz.'
            )
          }}
        </p>
        <p v-if="tempMember.phone" class="mb-2">
          <span class="grey--text text--darken-2">
            {{ tempMember.phone }}
          </span>
          <a
            v-if="tempMember.phone && tempMember.id"
            href="#"
            class="blue--text text--darken-3 text-underline font-weight-medium ml-1"
            @click.prevent="changeMemberPhone"
          >
            {{ $t('Değiştir') }}
          </a>
        </p>
        <b-form @submit.prevent="handleFormSubmitVerify">
          <b-form-group :label="$t('Doğrulama Kodu')" label-for="input-code">
            <b-form-input
              id="input-code"
              v-model="verifyForm.code"
              v-mask="'######'"
              placeholder="######"
              :disabled="loading"
              type="text"
              required
            >
            </b-form-input>
          </b-form-group>
          <div class="d-flex flex-row align-center justify-space-between">
            <b-button
              size="sm"
              class="fs12-1 modal-custom-button text--rezzta-red border-radius-7"
              :class="[
                { 'grey lighten-2 grey--text text--darken-4': !timeEnd }
              ]"
              variant="link"
              :disabled="!timeEnd"
              @click="resendCode"
            >
              {{ $t('Doğrulama Kodunu Tekrar Gönder') }}
              <span v-if="!timeEnd"> ({{ remainingSecond }}) </span>
            </b-button>
            <b-button
              :disabled="loading"
              class="fs12-1 rezzta-red white--text"
              type="submit"
            >
              {{ $t('Doğrula') }}
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </zz-div>
</template>

<script>
import trafficMixin from '@/mixins/traffic'
import {
  patchMemberResendSms,
  resendSmsForPhoneRegister,
  phoneVerifyCodeControl
} from '@/api/member'

export default {
  name: 'AuthVerifyPhone',
  mixins: [trafficMixin],
  data() {
    return {
      verifyForm: {
        code: ''
      },
      loading: false,
      timeEnd: false,
      remainingSecond: 180
    }
  },
  computed: {
    tempMember() {
      return {
        id: this.$store.state.auth?.tempMember?.id || null,
        phone: this.$store.state.auth?.tempMember?.phone || null,
        code: this.$store.state.auth?.tempMember?.code || null
      }
    }
  },
  watch: {
    timeEnd: {
      immediate: true,
      handler(e) {
        if (!e) {
          const interval = setInterval(() => {
            this.remainingSecond--

            if (this.remainingSecond <= 0) {
              clearInterval(interval)
              this.timeEnd = true
              this.remainingSecond = 180
            }
          }, 1000)
        }
      }
    }
  },
  methods: {
    changeMemberPhone() {
      this.$store.commit('auth/showPhoneModal')
    },
    async resendCode() {
      if (!this.timeEnd) return
      const params = {}

      params.memberId = this.tempMember.id

      try {
        let result = null

        if (params.memberId) {
          result = await this.$axios.request({
            ...patchMemberResendSms(params)
          })
        } else {
          result = await this.$axios.request({
            ...resendSmsForPhoneRegister(),
            data: {
              phone: this.tempMember.phone
            }
          })
        }

        this.$notification.success(result.data)
        this.timeEnd = false
      } catch (error) {
        this.$notification.error(
          this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      }
    },
    async handleFormSubmitVerify() {
      try {
        this.loading = true

        if (this.tempMember.id) {
          await this.$store.dispatch('auth/memberPhoneVerifyForAuth', {
            memberId: this.tempMember.id,
            code: this.verifyForm.code,
            partner_institution_ref:
              this.$cookies.get('ref')?.toString() || null,
            isRegistration: !!this.tempMember.phone
          })

          // Facebook Pixel
          // fbq('trackCustom', 'login', {
          //   country: this.$store.state.auth.locationDisplayName || ''
          // })
          // await this.postTrafficMethod({ type: 'login' })
        } else {
          await this.$axios.request({
            ...phoneVerifyCodeControl(),
            data: {
              phone: this.tempMember.phone,
              code: this.verifyForm.code
            }
          })
          this.$store.commit('auth/setTempMember', {
            phone: this.tempMember.phone,
            code: this.verifyForm.code
          })
          this.$store.dispatch('auth/showMemberDetailModal')
        }
      } catch (error) {
        this.$notification.error(
          error?.message || this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
