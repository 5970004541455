var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%", position: "relative" } },
    [
      _c(
        "GmapMap",
        {
          ref: "gmapComponent",
          style: _vm.mapStyle,
          attrs: { options: _vm.options, center: _vm.center, zoom: _vm.zoom }
        },
        [
          _vm.coords
            ? _c("GmapMarker", { attrs: { position: _vm.marker } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }