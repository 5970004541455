export const postBasket = params => {
  return {
    url: '/basket',
    method: 'post'
  }
}

export const getBasket = params => {
  return {
    url: '/basket',
    method: 'get'
  }
}

export const deleteBasket = params => {
  return {
    url: '/basket',
    method: 'delete'
  }
}

export const deleteBasketItem = params => {
  return {
    url: `/basket/${params.itemUuid}`,
    method: 'delete'
  }
}

export const patchBasketItemPiece = params => {
  return {
    url: `/basket/${params.itemUuid}`,
    method: 'patch'
  }
}

export const patchBasketDeliveryDate = () => {
  return {
    url: '/basket/delivery-date',
    method: 'patch'
  }
}
