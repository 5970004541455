const middleware = {}
/* eslint-disable dot-notation */

middleware['isNotDemo'] = require('../middleware/isNotDemo.js')
middleware['isNotDemo'] = middleware['isNotDemo'].default || middleware['isNotDemo']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['notBaseDomain'] = require('../middleware/notBaseDomain.js')
middleware['notBaseDomain'] = middleware['notBaseDomain'].default || middleware['notBaseDomain']

middleware['requiredAuth'] = require('../middleware/requiredAuth.js')
middleware['requiredAuth'] = middleware['requiredAuth'].default || middleware['requiredAuth']

middleware['requiredLocation'] = require('../middleware/requiredLocation.js')
middleware['requiredLocation'] = middleware['requiredLocation'].default || middleware['requiredLocation']

middleware['requiredNotAuth'] = require('../middleware/requiredNotAuth.js')
middleware['requiredNotAuth'] = middleware['requiredNotAuth'].default || middleware['requiredNotAuth']

middleware['router'] = require('../middleware/router.js')
middleware['router'] = middleware['router'].default || middleware['router']

/* eslint-enable dot-notation */
export default middleware
