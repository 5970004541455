<template>
  <zz-div :loading="loading">
    <b-form @submit.prevent="handleFormSubmit">
      <slot name="productInf"></slot>
      <div class="row mb-5">
        <div class="col-md-4 text-center rating-w">
          <span class="d-block text--rezzta-red"> {{ $t('Hız') }} </span>
          <starRating
            :star-size="25"
            :increment="0.5"
            :rating="0"
            :show-rating="false"
            class="d-inline-block"
            :glow="1"
            :star-points="[
              23,
              2,
              14,
              17,
              0,
              19,
              10,
              34,
              7,
              50,
              23,
              43,
              38,
              50,
              36,
              34,
              46,
              19,
              31,
              17
            ]"
            @rating-selected="setSpeed"
          ></starRating>
        </div>
        <div class="col-md-4 text-center rating-w">
          <span class="d-block text--rezzta-red"> {{ $t('Lezzet') }} </span>
          <starRating
            :star-size="25"
            :increment="0.5"
            :rating="0"
            :show-rating="false"
            class="d-inline-block"
            :glow="1"
            :star-points="[
              23,
              2,
              14,
              17,
              0,
              19,
              10,
              34,
              7,
              50,
              23,
              43,
              38,
              50,
              36,
              34,
              46,
              19,
              31,
              17
            ]"
            @rating-selected="setTaste"
          ></starRating>
        </div>
        <div class="col-md-4 text-center rating-w">
          <span class="d-block text--rezzta-red">
            {{ $t('Servis') }}
          </span>
          <starRating
            :star-size="25"
            :increment="0.5"
            :rating="0"
            :show-rating="false"
            class="d-inline-block"
            :glow="1"
            :star-points="[
              23,
              2,
              14,
              17,
              0,
              19,
              10,
              34,
              7,
              50,
              23,
              43,
              38,
              50,
              36,
              34,
              46,
              19,
              31,
              17
            ]"
            @rating-selected="setPresentation"
          ></starRating>
        </div>
      </div>
      <textarea
        v-model="form.comment"
        :placeholder="
          $t('Siparişiniz hakkındaki düşüncelerinizi paylaşabilirsiniz.')
        "
        class="form-control border-radius-7"
        rows="4"
        maxlength="1000"
        style="min-height: 90px; max-height: 230px; height: 140px;"
      ></textarea>
      <hr />
      <div class="text-right d-flex flex-row align-center justify-center">
        <b-button type="submit" class="btn-rezzta btn-block border-radius-7">
          {{ $t('Gönder') }}
        </b-button>
        <slot name="ignore" />
      </div>
    </b-form>
  </zz-div>
</template>

<script>
import { postOrderPoint } from '@/api/member'

export default {
  name: 'OrderRating',
  props: {
    orderId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      form: {
        taste: 0,
        speed: 0,
        presentation: 0,
        comment: ''
      }
    }
  },
  methods: {
    setSpeed(rate) {
      this.form.speed = rate * 2
    },
    setTaste(rate) {
      this.form.taste = rate * 2
    },
    setPresentation(rate) {
      this.form.presentation = rate * 2
    },
    async handleFormSubmit() {
      if (this.loading) return
      if (!this.orderId) return

      if (!(this.form.taste && this.form.speed && this.form.presentation)) {
        this.$notification.error(this.$t('En az bir puan vermelisiniz!'))

        return
      }

      try {
        this.loading = true

        const params = { ...this.$route.params }

        // params.memberId = this.$store.state.auth.info.id
        params.orderId = this.orderId

        const result = await this.$axios.request({
          ...postOrderPoint(params),
          data: this.form
        })

        this.$notification.success(result.data)
        this.$emit('success')
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    state(error) {
      return typeof error === 'undefined' ? error : !error
    }
  }
}
</script>

<style></style>
