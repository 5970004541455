var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "zz-div",
    { attrs: { loading: _vm.loading } },
    [
      _vm.info.id
        ? [
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "p",
                  { staticClass: "pa-3 mb-1 grey lighten-5 p-relative" },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs13-1 grey--text text--darken-3 font-weight-medium"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("Kurum Adı")) +
                            ":\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.info.name) + "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.info.logo_url
                      ? _c("img", {
                          staticClass: "partner-institution-logo",
                          attrs: { src: _vm.info.logo_url, alt: _vm.info.name }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "pa-3 mb-1 grey lighten-5" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs13-1 grey--text text--darken-3 font-weight-medium d-block"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("İndirim Oranları")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _vm.info.discount_rate_deliverytoaddress
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Adrese Teslim")) +
                                  ":\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "%" +
                                      _vm.info.discount_rate_deliverytoaddress
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.discount_rate_comegetit
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Gel Al")) +
                                  ":\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "%" + _vm.info.discount_rate_comegetit
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.discount_rate_comeeat
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Gel Ye")) +
                                  ":\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s("%" + _vm.info.discount_rate_comeeat) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.discount_rate_orderonthetable
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Masaya Sipariş")) +
                                  ":\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium ml-1"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "%" + _vm.info.discount_rate_orderonthetable
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.info.member_point.purchase_point &&
                _vm.info.member_point.purchase_point.price &&
                _vm.info.member_point.purchase_point.point
                  ? _c("div", { staticClass: "pa-3 mb-1 grey lighten-5" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "fs13-1 grey--text text--darken-3 font-weight-medium d-block"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Sipariş Puanı")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-4 d-flex flex-column w-100" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "fs13-1 grey--text text--darken-3 font-weight-medium d-flex flex-column"
                            },
                            [
                              _vm._l(
                                Object.keys(
                                  _vm.info.member_point.purchase_point.point
                                ),
                                function(pointName, index) {
                                  return [
                                    _vm.info.member_point.purchase_point.point[
                                      pointName
                                    ]
                                      ? _c("div", { key: index }, [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t(pointName)) +
                                              ":\n                " +
                                              _vm._s(
                                                _vm
                                                  .$t(
                                                    "Her {price} harcamanızda {point} puan kazanırsınız"
                                                  )
                                                  .replace(
                                                    "{price}",
                                                    _vm.$options.filters.currency(
                                                      _vm.info.member_point
                                                        .purchase_point.price,
                                                      {
                                                        symbol: _vm.currency
                                                      }
                                                    )
                                                  )
                                                  .replace(
                                                    "{point}",
                                                    _vm.info.member_point
                                                      .purchase_point.point[
                                                      pointName
                                                    ]
                                                  )
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.info.member_point.purchase_point.expire_day
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "fs13-1 grey--text text--darken-3 font-weight-medium",
                                  class: _vm.descriptionClass
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm
                                          .$t(
                                            "Satın alma puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür"
                                          )
                                          .replace(
                                            "{day}",
                                            _vm.info.member_point.purchase_point
                                              .expire_day
                                          )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.info.member_point.purchase_point.minimum_total
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "fs13-1 grey--text text--darken-3 font-weight-medium",
                                  class: _vm.descriptionClass
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm
                                          .$t(
                                            "Siparişinizden puan kazanabilmeniz için, sipariş toplamının en az {minTotal} olmalıdır"
                                          )
                                          .replace(
                                            "{minTotal}",
                                            _vm.$options.filters.currency(
                                              _vm.info.member_point
                                                .purchase_point.minimum_total,
                                              {
                                                symbol: _vm.currency
                                              }
                                            )
                                          )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass:
                      "d-block ml-auto fs13 btn-rezzta mt-4 border-radius-7",
                    staticStyle: { height: "32px" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.leaveInstutionModal = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("Kurumdan Ayrıl")) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "py-2" },
              [
                [
                  _c(
                    "b-form",
                    {
                      staticClass:
                        "d-flex flex-row align-end justify-center flex-wrap rounded mb-2 gap-3",
                      class: { "px-4 py-4 grey lighten-5": !_vm.isModal },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.handleFormPartnerInstitutionSubmit({
                            partner_institution_id:
                              _vm.partnerInstitutionShowList.selected.id
                          })
                        }
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-0",
                          staticStyle: { flex: "1 1 250px" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Üye Profili")) +
                                  "\n              "
                              ),
                              _vm.isModal
                                ? _c("span", {
                                    staticClass:
                                      "cursor-pointer common-icon p-relative",
                                    staticStyle: { top: "-2px" },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.icons.question)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.blogModalHandler(
                                          "partner-institution-description"
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-select", {
                            staticClass: "rounded",
                            attrs: {
                              options: _vm.partnerInstitutionShowList.data
                            },
                            on: {
                              change: function($event) {
                                return _vm.handleChangePartnerInstitution(
                                  _vm.partnerInstitutionShowList.selected
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function() {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Üye Profili Seçin"))
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.partnerInstitutionShowList.selected,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.partnerInstitutionShowList,
                                  "selected",
                                  $$v
                                )
                              },
                              expression: "partnerInstitutionShowList.selected"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isModal
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "d-flex flex-row align-center justify-center fs13 btn-rezzta border-radius-7",
                              staticStyle: { height: "32px" },
                              attrs: {
                                disabled:
                                  !_vm.partnerInstitutionShowList.selected ||
                                  _vm.partnerInstitutionShowList.selected.id ===
                                    _vm.partnerInstitutionRequest
                                      .partner_institution_id,
                                type: "submit"
                              }
                            },
                            [
                              _vm.loading
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _vm._e(),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("Onayla")) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                _vm._v(" "),
                _vm.isShowCodeArea
                  ? _c(
                      "b-form",
                      {
                        staticClass:
                          "d-flex flex-row align-end justify-center flex-wrap rounded gap-3",
                        class: { "px-4 py-4 grey lighten-5": !_vm.isModal },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.handleFormPartnerInstitutionSubmit({
                              code: _vm.code
                            })
                          }
                        }
                      },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0",
                            staticStyle: { flex: "1 1 250px" },
                            attrs: {
                              label: _vm.$t("Kurum Kodunu Giriniz"),
                              "label-for": "coupon-code"
                            }
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "rounded",
                              attrs: {
                                id: "coupon-code",
                                disabled: _vm.loading,
                                type: "text",
                                placeholder: _vm.$t("Kurum Kodu")
                              },
                              model: {
                                value: _vm.code,
                                callback: function($$v) {
                                  _vm.code = $$v
                                },
                                expression: "code"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "d-flex flex-row align-center justify-center fs13 btn-rezzta border-radius-7",
                            staticStyle: { height: "32px" },
                            attrs: {
                              disabled: _vm.loading || !_vm.code,
                              type: "submit"
                            }
                          },
                          [
                            _vm.loading
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _vm._e(),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("Doğrula")) +
                                "\n        "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ],
      _vm._v(" "),
      _vm.requiredDocumentInf.isRequired
        ? _c(
            "div",
            {
              staticClass:
                "custom-alert custom-alert-danger --outline --icon-hidden mb-2",
              class: { "--small": _vm.isModal }
            },
            [
              _c("span", {
                staticClass: "common-icon",
                domProps: { innerHTML: _vm._s(_vm.icons.warning) }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-center w-100" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.requiredDocumentInf.message) +
                    "\n    "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "rounded d-flex flex-row align-end justify-center partner-instituiton-request",
          class: [
            {
              "grey lighten-5 pa-2":
                !!_vm.partnerInstitutionRequest.status &&
                !_vm.requiredDocumentInf.isRequired
            },
            {
              "mt-1 pa-2": _vm.requiredDocumentInf.isRequired
            }
          ]
        },
        [
          _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            ref: "partnerInstitutionRequestInputFile",
            attrs: {
              type: "file",
              accept: _vm.partnerInstitutionRequestInf.accept
            },
            on: { change: _vm.handleInputFileChange }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row align-center justify-center gap-4 w-100",
              class: { "flex-wrap": !_vm.isModal }
            },
            [
              _vm.partnerInstitutionRequest.file_url &&
              !_vm.requiredDocumentInf.isRequired
                ? [
                    _vm
                      .extractType(_vm.partnerInstitutionRequest.file_url)
                      .is("pdf")
                      ? _c("img", {
                          staticClass: "mr-auto",
                          staticStyle: {
                            "max-width": "90px",
                            "max-height": "80px"
                          },
                          attrs: {
                            src: _vm
                              .extractType(
                                _vm.partnerInstitutionRequest.file_url
                              )
                              .default()
                          }
                        })
                      : _c("img", {
                          staticClass: "mr-auto",
                          staticStyle: {
                            "max-width": "110px",
                            "max-height": "100px",
                            "object-fit": "contain"
                          },
                          attrs: { src: _vm.partnerInstitutionRequest.file_url }
                        }),
                    _vm._v(" "),
                    !_vm.isModal
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-start justify-start flex-grow-1"
                          },
                          [
                            _vm.partnerInstitutionRequest.status
                              ? _c("span", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Durum")) +
                                      ":\n            " +
                                      _vm._s(
                                        _vm.partnerInstitutionRequest.status ===
                                          "pending"
                                          ? _vm.$t("Bekleniyor")
                                          : _vm.partnerInstitutionRequest
                                              .status === "rejected"
                                          ? _vm.$t("Reddedildi")
                                          : _vm.$t("Onaylandı")
                                      ) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.partnerInstitutionRequest.status ===
                              "rejected" &&
                            _vm.partnerInstitutionRequest.reject_message
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "px-2 red darken-2 white--text mb-1"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.partnerInstitutionRequest
                                            .reject_message
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.partnerInstitutionRequest.name
                              ? _c("span", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Kurum Adı")) +
                                      ":\n            " +
                                      _vm._s(
                                        _vm.partnerInstitutionRequest.name
                                      ) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.partnerInstitutionRequest.created_at
                              ? _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Oluşturma Tarihi")) +
                                      ":\n            " +
                                      _vm._s(
                                        _vm
                                          .$moment(
                                            _vm.partnerInstitutionRequest
                                              .created_at
                                          )
                                          .format("LLL")
                                      ) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row align-center justify-center flex-wrap gap-1",
                  staticStyle: { "max-width": "180px" }
                },
                [
                  !_vm.info.id && _vm.requiredDocumentInf.isRequired
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "btn fs12-1 rezzta-green-3 white--text border-radius-7 py-1 flex-1-1-auto",
                          on: { click: _vm.clickHandlePartnerInstitutionFile }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Belge yükle")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.requiredDocumentInf.isRequired
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "btn fs12-1 rezzta-grey-4 white--text border-radius-7 py-1 flex-1-1-auto",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleClickResetRequiredDocumentInf(
                                $event
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Vazgeç")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.partnerInstitutionRequest.file_url &&
                  !_vm.requiredDocumentInf.isRequired
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "btn fs12-1 rezzta-grey-4 white--text border-radius-7 py-1 flex-1-1-auto",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleClickDeletePartnerInstitutionRequest(
                                $event
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Belgeyi Sil")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      !_vm.isModal && (_vm.partnerInstitutionRequest.status || !_vm.info.id)
        ? _c(
            "div",
            { staticClass: "d-flex flex-row align-center justify-end my-1" },
            [
              _c(
                "a",
                {
                  staticClass:
                    "text-underline fs13 font-weight-regular grey--text text--darken-1 mx-2",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.blogModalHandler(
                        "partner-institution-description"
                      )
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("Detaylı Bilgi")) + "\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isModal
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-row align-center justify-end border-top-1px border--rezzta-grey-2 mt-2 pt-3 pb-1"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "fs13 text--rezzta-red text-underline px-0 py-1 font-weight-medium",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickHandlePartnerInstitutionCancel($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("Seçmeden Devam Et")) + "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.partnerInstitutionRequest.file_url ||
              _vm.isShowButtonForPartnerInstitutionList
                ? _c(
                    "b-button",
                    {
                      staticClass:
                        "fs13 rezzta-green-3 white--text border-radius-7 py-1 ml-3",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clickHandlePartnerInstitutionSave($event)
                        }
                      }
                    },
                    [_vm._v("\n      " + _vm._s(_vm.$t("Kaydet")) + "\n    ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            centered: "",
            "ok-only": "",
            lazy: "",
            scrollable: "",
            "hide-footer": "",
            "content-class": "custom-modal-1 pa-2"
          },
          model: {
            value: _vm.blog.modal,
            callback: function($$v) {
              _vm.$set(_vm.blog, "modal", $$v)
            },
            expression: "blog.modal"
          }
        },
        [
          _c("template", { slot: "modal-title" }, [
            _vm._v(" " + _vm._s(_vm.blog.textTitle) + " ")
          ]),
          _vm._v(" "),
          _c("blogArea", {
            staticStyle: { "margin-top": "-20px" },
            attrs: { slug: _vm.blog.blogSlug, "blog-name": _vm.blog.name },
            on: {
              title: function($event) {
                _vm.blog.textTitle = $event
              }
            }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": "custom-modal-1",
            "hide-footer": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            title: _vm.$t("Belge Düzenleme")
          },
          model: {
            value: _vm.partnerInstitutionRequestInf.modal,
            callback: function($$v) {
              _vm.$set(_vm.partnerInstitutionRequestInf, "modal", $$v)
            },
            expression: "partnerInstitutionRequestInf.modal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-4 pt-2 pb-4 partner-institution-request-modal" },
            [
              _c("embed", {
                attrs: {
                  src: _vm.partnerInstitutionRequestInf.file_url,
                  type: _vm.partnerInstitutionRequestInf.file_type,
                  width: "100%"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row align-center justify-center mt-4"
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "d-block fs13 rezzta-red white--text mr-2 border-radius-7",
                      attrs: { disabled: _vm.loading },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.handleClickPartnerInstitutionRequest(
                            $event
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("Onayla")) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn fs13 grey lighten-2 grey--text text--darken-3 border-radius-7",
                      attrs: { disabled: _vm.loading },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.partnerInstitutionRequestInf.modal = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("İptal")) + "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": "custom-modal-1",
            "hide-footer": "",
            centered: "",
            title: _vm.$t("Kurumdan Ayrıl")
          },
          model: {
            value: _vm.leaveInstutionModal,
            callback: function($$v) {
              _vm.leaveInstutionModal = $$v
            },
            expression: "leaveInstutionModal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-4 pt-2 pb-4" },
            [
              _vm.$store.state.auth.info.member_partner_institution
                ? _c(
                    "p",
                    {
                      staticClass:
                        "fs16-1 grey--text text--darken-3 font-weight-medium text-center mb-1"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm
                              .$t("{name} kurumundan ayrılacaksınız.")
                              .replace(
                                "{name}",
                                _vm.$store.state.auth.info
                                  .member_partner_institution.name
                              )
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "fs16-1 grey--text text--darken-3 font-weight-medium text-center mb-1"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("Onaylıyor musunuz?")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass:
                    "d-block fs13 mt-4 mx-auto rezzta-red white--text border-radius-7",
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.leaveInstution($event)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("Onayla")) + "\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }