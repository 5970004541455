export const postMemberFavoriteRestaurant = ({ memberId, restaurantId }) => ({
  url: `/member/${memberId}/favorite-restaurant/${restaurantId}`,
  method: 'post'
})

export const getMemberFavoriteRestaurantAll = ({ memberId }) => ({
  url: `/member/${memberId}/favorite-restaurant`,
  method: 'get'
})

export const deleteMemberFavoriteRestaurant = ({ memberId, restaurantId }) => ({
  url: `/member/${memberId}/favorite-restaurant/${restaurantId}`,
  method: 'delete'
})

export const postMemberFavoriteProduct = ({ memberId, productId }) => ({
  url: `/member/${memberId}/favorite-product/${productId}`,
  method: 'post'
})

export const getMemberFavoriteProductAll = ({ memberId }) => ({
  url: `/member/${memberId}/favorite-product`,
  method: 'get'
})

export const deleteMemberFavoriteProduct = ({ memberId, productId }) => ({
  url: `/member/${memberId}/favorite-product/${productId}`,
  method: 'delete'
})
