<template>
  <div class="error-404">
    <h1 class="mt-2 mb-2">
      {{ $t('Sayfa Bulunamadı!') }}
    </h1>
    <p>
      {{
        $t(
          'Aradığınız sayfa bulunamadı!  Silinmiş ya da yanlış adres yazmış olabilirsiniz'
        )
      }}
    </p>
    <nuxt-link class="btn btn-primary btn-lg" :to="localePath('index')">
      {{ $t('Anasayfaya Dön') }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
