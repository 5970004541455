export default function({ app, store }) {
  app.router.beforeEach((to, from, next) => {
    next()

    if (to.path.includes('/me') || to.path.includes('/restaurants')) {
      if (store.state.auth.location?.length !== 2) {
        store.dispatch('auth/showLocationModal')
      }
    }
  })
}
