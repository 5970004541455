import { render, staticRenderFns } from "./orderRating.vue?vue&type=template&id=388be962&"
import script from "./orderRating.vue?vue&type=script&lang=js&"
export * from "./orderRating.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('388be962')) {
      api.createRecord('388be962', component.options)
    } else {
      api.reload('388be962', component.options)
    }
    module.hot.accept("./orderRating.vue?vue&type=template&id=388be962&", function () {
      api.rerender('388be962', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/common/orderRating.vue"
export default component.exports