var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("zz-div", { attrs: { loading: _vm.loading } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "Lütfen telefonunuza gönderilen 6 haneli doğrulama kodunu giriniz."
                  )
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _vm.tempMember.phone
            ? _c("p", { staticClass: "mb-2" }, [
                _c("span", { staticClass: "grey--text text--darken-2" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.tempMember.phone) + "\n        "
                  )
                ]),
                _vm._v(" "),
                _vm.tempMember.phone && _vm.tempMember.id
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "blue--text text--darken-3 text-underline font-weight-medium ml-1",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.changeMemberPhone($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("Değiştir")) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleFormSubmitVerify($event)
                }
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("Doğrulama Kodu"),
                    "label-for": "input-code"
                  }
                },
                [
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "######",
                        expression: "'######'"
                      }
                    ],
                    attrs: {
                      id: "input-code",
                      placeholder: "######",
                      disabled: _vm.loading,
                      type: "text",
                      required: ""
                    },
                    model: {
                      value: _vm.verifyForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.verifyForm, "code", $$v)
                      },
                      expression: "verifyForm.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row align-center justify-space-between"
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "fs12-1 modal-custom-button text--rezzta-red border-radius-7",
                      class: [
                        {
                          "grey lighten-2 grey--text text--darken-4": !_vm.timeEnd
                        }
                      ],
                      attrs: {
                        size: "sm",
                        variant: "link",
                        disabled: !_vm.timeEnd
                      },
                      on: { click: _vm.resendCode }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("Doğrulama Kodunu Tekrar Gönder")) +
                          "\n            "
                      ),
                      !_vm.timeEnd
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(_vm.remainingSecond) + ") ")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "fs12-1 rezzta-red white--text",
                      attrs: { disabled: _vm.loading, type: "submit" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("Doğrula")) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }