var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "footer pt-5 pb-5" }, [
      _c("div", { staticClass: "container" }, [
        _vm.$store.state.company.settings
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-4 col-12 col-sm-12 mb-3 text-center text-md-left"
                },
                [
                  _vm.$store.state.company.settings.play_store ||
                  _vm.$store.state.company.settings.app_store
                    ? _c("div", { staticClass: "app" }, [
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("Mobil Uygulamalarımız")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.$store.state.company.settings.app_store
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.$store.state.company.settings.app_store,
                                  target: "_blank"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: { src: "/images/apple.png" }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$store.state.company.settings.play_store
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.$store.state.company.settings
                                      .play_store,
                                  target: "_blank"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: { src: "/images/google.png" }
                                })
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-language" }, [
                    _c("div", { staticClass: "footer-language-icon" }, [
                      _c("span", {
                        staticClass: "common-icon",
                        domProps: { innerHTML: _vm._s(_vm.icons.language) }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "footer-language-items" },
                      [
                        _vm.$i18n.locale !== "en"
                          ? _c(
                              "nuxt-link",
                              { attrs: { to: _vm.switchLocalePath("en") } },
                              [_vm._v("\n                EN\n              ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$i18n.locale !== "tr"
                          ? _c(
                              "nuxt-link",
                              { attrs: { to: _vm.switchLocalePath("tr") } },
                              [_vm._v("\n                TR\n              ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$i18n.locale !== "de"
                          ? _c(
                              "nuxt-link",
                              { attrs: { to: _vm.switchLocalePath("de") } },
                              [_vm._v("\n                DE\n              ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-social" }, [
                    _vm.$store.state.company.settings.facebook
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$store.state.company.settings.facebook,
                              target: "_blank"
                            }
                          },
                          [_c("fa", { attrs: { icon: ["fab", "facebook"] } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$store.state.company.settings.instagram
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$store.state.company.settings.instagram,
                              target: "_blank"
                            }
                          },
                          [_c("fa", { attrs: { icon: ["fab", "instagram"] } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$store.state.company.settings.youtube
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$store.state.company.settings.youtube,
                              target: "_blank"
                            }
                          },
                          [_c("fa", { attrs: { icon: ["fab", "youtube"] } })],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 col-12 col-sm-6 mb-3" }, [
                _c(
                  "ul",
                  [
                    Object.keys(_vm.domainBlogs)
                      ? [
                          _vm._l(Object.keys(_vm.domainBlogs), function(
                            blog,
                            index
                          ) {
                            return [
                              _vm.domainBlogs[blog].is_footer
                                ? _c(
                                    "li",
                                    { key: index },
                                    [
                                      _c(
                                        "nuxt-link",
                                        {
                                          attrs: {
                                            to: _vm.localePath({
                                              name: "blog",
                                              params: {
                                                index:
                                                  _vm.domainBlogs[blog].name
                                              }
                                            })
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.domainBlogs[blog].title
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-md-5 col-12 col-sm-12 mb-3 footer-contact"
                },
                [
                  _vm.contact
                    ? _c("ul", [
                        _vm.contact.email
                          ? _c("li", [
                              _c(
                                "span",
                                [_c("fa", { attrs: { icon: "envelope" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "text-dark",
                                  attrs: { href: "mailto:" + _vm.contact.email }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.contact.email) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contact.phone
                          ? _c("li", [
                              _c(
                                "span",
                                [_c("fa", { attrs: { icon: "phone" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "text-dark",
                                  attrs: { href: "tel:" + _vm.contact.phone }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.contact.phone) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contact.address
                          ? _c("li", [
                              _c(
                                "span",
                                [
                                  _c("fa", {
                                    attrs: { icon: "map-marker-alt" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.contact.address) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("footer", { staticClass: "pt-4 pb-4 text-center" }, [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "mt-0 mb-0" }, [
          _vm._v("© Copyright " + _vm._s(new Date().getFullYear()) + ".")
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "mt-0 mb-0" }, [
          _c(
            "a",
            {
              staticClass: "text-danger",
              attrs: {
                target: "_blank",
                href:
                  "https://www.myrezzta.com" +
                  (_vm.$i18n.locale === "tr" ? "" : "/en")
              }
            },
            [_vm._v("\n          AKINSOFT MyRezzta\n        ")]
          ),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("altyapısı kullanılmıştır.")) +
              "\n      "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }