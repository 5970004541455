<template>
  <b-modal
    id="availableTableModal"
    v-model="deliveryTypeObj.modal"
    centered
    hide-footer
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    content-class="custom-modal-1"
  >
    <template slot="modal-title">
      {{
        $t('Masa numarası: {table_no}').replace(
          '{table_no}',
          deliveryType.table_no
        )
      }}
    </template>
    <div class="pa-4 delivery-type-modal">
      <div class="dtm-type-2">
        <p
          class="font-weight-medium mb-3 fs15-1 text--rezzta-grey-1 text-center"
        >
          {{ deliveryTypeObj.text }}
        </p>
        <button
          class="btn-rezzta-gray mx-auto d-flex"
          @click.prevent="deliveryTypeObj.modal = false"
        >
          {{ $t('Tamam') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getWolvoxAvailableTable } from '@/api/restaurant'

export default {
  props: {
    receiveDeliveryType: {
      type: Object,
      default: null
    },
    receiveRestaurantId: {
      type: [Number, String],
      default: null
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deliveryType: {},
      restaurantId: null,
      deliveryTypeObj: {
        modal: false,
        text: ''
      }
    }
  },
  methods: {
    async getRestaurantAvailableTable({ restaurantId, tableNo }) {
      try {
        await this.$axios.request({
          ...getWolvoxAvailableTable({ restaurantId, tableNo })
        })

        return {
          isOK: true,
          message: ''
        }
      } catch (err) {
        return {
          isOK: false,
          message: err?.message || ''
        }
      }
    },
    async check() {
      this.deliveryType =
        this.receiveDeliveryType ||
        this.$store.state.basket?.restaurant?.delivery_type ||
        {}
      this.restaurantId =
        this.receiveRestaurantId ||
        this.$store.state.basket?.restaurantId ||
        null

      if (!this.deliveryType?.name) {
        this.deliveryTypeObj.text = this.$t(
          'Sipariş verebilmeniz için teslimat seçeneğini seçmelisiniz!'
        )
        this.deliveryTypeObj.modal = true

        return false
      } else if (this.deliveryType.name === 'orderOnTheTable') {
        const restaurantCheckResult = await this.getRestaurantAvailableTable({
          restaurantId: this.restaurantId,
          tableNo: this.deliveryType.table_no
        })

        if (!restaurantCheckResult.isOK) {
          this.deliveryTypeObj.text = restaurantCheckResult.message
          this.deliveryTypeObj.modal = true
        }

        return !!restaurantCheckResult.isOK
      }

      return true
    }
  }
}
</script>
