<template>
  <div>
    <template v-if="$store.state.isBaseDomain">
      <client-only>
        <limonDesk />
      </client-only>
      <default-header></default-header>
      <nuxt />
      <default-footer></default-footer>
    </template>
    <template v-else>
      <domainHeader></domainHeader>
      <nuxt />
      <domainFooter></domainFooter>
      <domainCookieLaw />
    </template>
  </div>
</template>

<script>
import defaultHeader from '@/layouts/header'
import defaultFooter from '@/layouts/footer'
import domainHeader from '@/layouts/header/domainHeader'
import domainFooter from '@/layouts/footer/domainFooter'
import limonDesk from '@/components/limonDesk/limonDesk'
import domainCookieLaw from '@/components/common/domainCookieLaw'

export default {
  name: 'NotRequiredLocationLayout',
  scrollToTop: true,
  components: {
    defaultHeader,
    defaultFooter,
    domainHeader,
    domainFooter,
    limonDesk,
    domainCookieLaw
  },
  watch: {
    '$i18n.locale': {
      handler() {
        setTimeout(() => {
          this.$store.dispatch('getCompanyBlogData')
        }, 300)
      }
    }
  }
}
</script>
