<template>
  <div class="header">
    <b-navbar
      toggleable="lg"
      variant="light"
      type="light"
      class="header-nav shadow-sm"
    >
      <div class="container">
        <b-navbar-brand href="https://www.myrezzta.com/">
          <img
            alt="logo"
            height="30"
            style="max-height:30px;"
            src="/images/myrezzta.svg"
          />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              v-if="$i18n.locale !== 'en'"
              :to="switchLocalePath('en')"
            >
              <span class="grey--text text--darken-2">
                English
              </span>
            </b-nav-item>
            <b-nav-item
              v-if="$i18n.locale !== 'tr'"
              :to="switchLocalePath('tr')"
            >
              <span class="grey--text text--darken-2">
                Türkçe
              </span>
            </b-nav-item>
            <b-nav-item
              v-if="$i18n.locale !== 'de'"
              :to="switchLocalePath('de')"
            >
              <span class="grey--text text--darken-2">
                Almanca
              </span>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'DefaultHeader',
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style></style>
