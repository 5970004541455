export const getRestaurantAll = () => {
  return {
    url: `/restaurant`,
    method: 'get'
  }
}

export const getRestaurantCheckOrderOnTheTable = ({ id }) => {
  return {
    url: `/restaurant/${id}/check-distance`,
    method: 'get'
  }
}

export const getRestaurant = params => {
  return {
    url: `/restaurant/get/${params.branchSlug}`,
    method: 'get'
  }
}

export const getRestaurantCommentCount = ({ id }) => {
  return {
    url: `/restaurant/${id}/comment-count`,
    method: 'get'
  }
}

export const getRestaurantMenuList = params => {
  return {
    url: `/restaurant/${params.id}/menu/${params.deliveryType}`,
    method: 'get'
  }
}

export const getRestaurantMenuListForCheckout = params => {
  return {
    url: `/restaurant/${params.id}/checkout/${params.deliveryType}`,
    method: 'get'
  }
}

export const getRestaurantProduct = params => {
  return {
    url: `/restaurant/${params.restaurantId}/product/${params.productId}/${params.deliveryType}`,
    method: 'get'
  }
}

export const getRestaurantProductForStory = params => {
  return {
    url: `/restaurant/${params.restaurantId}/product-for-story/${params.productId}/${params.deliveryType}`,
    method: 'get'
  }
}

export const getRestaurantMenu = params => {
  return {
    url: `/restaurant/${params.restaurantId}/menu/${params.menuId}`,
    method: 'get'
  }
}

export const getRestaurantDeliveryPointCheckAll = params => {
  return {
    url: `/restaurant/${params.restaurantId}/delivery-point-check`,
    method: 'get'
  }
}

export const getRestaurantPaymentMethodAll = params => {
  return {
    url: `/restaurant/${params.restaurantId}/payment-method/${params.deliveryTypeId}`,
    method: 'get'
  }
}

export const getRestaurantCommentAll = params => {
  return {
    url: `/restaurant/${params.restaurantId}/comment`,
    method: 'get'
  }
}

export const getRestaurantCommentAverage = params => {
  return {
    url: `/restaurant/${params.restaurantId}/comment-average`,
    method: 'get'
  }
}

export const getRestaurantBannerAll = params => {
  return {
    url: `/restaurant/${params.restaurantId}/banner`,
    method: 'get'
  }
}

export const postRestaurantCommand = params => {
  return {
    url: `/restaurant/${params.restaurantId}/command`,
    method: 'post'
  }
}

export const postRestaurantReservation = params => {
  return {
    url: `/restaurant/${params.restaurantId}/reservation`,
    method: 'post'
  }
}

export const postRestaurantRequestSuggestion = params => {
  return {
    url: `/restaurant/${params.restaurantId}/request-suggestion`,
    method: 'post'
  }
}

export const getWolvoxAvailableTable = params => {
  return {
    url: `/restaurant/${params.restaurantId}/wolvox-available-table/${params.tableNo}`,
    method: 'get'
  }
}
