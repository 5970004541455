<template>
  <zz-div :loading="loading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(handleFormSubmit)">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Telefon')"
          rules="required"
        >
          <b-form-group label-for="input-phone">
            <phoneNumberInput
              id="input-phone"
              v-model="model.phone"
              :disabled="loading"
              :state="state(errors[0])"
              required
              :border-radius="2"
              default-country-code="TR"
              no-example
              no-flags
              :translations="{
                phoneNumberLabel: $t('Telefon'),
                countrySelectorLabel: $t('Ülke Kodu')
              }"
              @update="p => (form['phone'] = p.formattedNumber)"
            >
            </phoneNumberInput>
          </b-form-group>
        </validation-provider>
        <div class="text-right">
          <b-button
            :disabled="loading"
            class="fs12-1 rezzta-red white--text"
            type="submit"
          >
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t('Kaydet') }}
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </zz-div>
</template>

<script>
import phoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { updateMemberPhoneForAuth } from '@/api/member'

export default {
  name: 'AuthPhone',
  components: {
    phoneNumberInput
  },
  data() {
    return {
      model: {
        phone: ''
      },
      form: {
        phone: ''
      },
      loading: false
    }
  },
  mounted() {
    this.model.phone = this.$store.state.auth.tempMember.phone || ''
  },
  methods: {
    async handleFormSubmit() {
      if (this.loading) return
      this.loading = true

      try {
        const params = {}

        params.memberId = this.$store.state?.auth?.tempMember?.id || null

        if (!params.memberId) {
          throw new Error('Bir hata oluştu!')
        }

        const result = await this.$axios.request({
          ...updateMemberPhoneForAuth(params),
          data: this.form
        })

        this.$store.commit('auth/setTempMember', {
          id: result.data.member_id,
          phone: result.data.phone
        })
        this.$store.dispatch('auth/showVerifyPhoneModal')
      } catch (error) {
        this.$notification.error(
          error?.message || 'Bir hata oluştu! Lütfen tekrar deneyin'
        )
      } finally {
        this.loading = false
      }
    },
    state(error) {
      return typeof error === 'undefined' ? error : !error
    }
  }
}
</script>

<style></style>
