<template>
  <div class="header domain-header">
    <smartAppBanner :data="smartAppBannerData"></smartAppBanner>
    <div class="location-bar bg-white d-none d-sm-block">
      <div class="container py-1">
        <a href="#" @click.prevent="$store.dispatch('auth/showLocationModal')">
          <span class="common-icon" v-html="icons.location1"></span>
          <b>
            {{ getShortAddress() }}
          </b>
          <span class="down-custom-icon"></span>
        </a>
      </div>
    </div>
    <b-navbar toggleable="lg" class="header-nav shadow-sm">
      <div class="container p-relative">
        <b-navbar-brand :to="localePath('index')">
          <img alt="logo" :src="logo" style="max-height: 38px;" />
        </b-navbar-brand>
        <span
          class="common-icon map-mobil"
          @click="$store.dispatch('auth/showLocationModal')"
        >
          <svg
            id="Capa_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
          >
            <path
              class="st0"
              d="M256,61.53c-76.57,0-138.64,62.07-138.64,138.64S256,450.47,256,450.47s138.64-173.73,138.64-250.3
                    S332.57,61.53,256,61.53z M256,264.25c-38.82,0-70.28-31.47-70.28-70.28c0-38.82,31.47-70.28,70.28-70.28s70.28,31.47,70.28,70.28
                    C326.28,232.79,294.82,264.25,256,264.25z"
            />
          </svg>
          <small class="map-icon-wrapper"> </small>
        </span>
        <b-navbar-toggle target="nav-collapse">
          <span class="navbar-toggler-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
          <span
            v-show="$store.state.auth.notificationUnreadCount > 0"
            class="badge badge-success menu-w-badge"
          >
            {{ $store.state.auth.notificationUnreadCount }}
          </span>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="localePath('index')" style="display:none;">
              {{ $t('Anasayfa') }}
            </b-nav-item>
            <!-- <b-nav-item
              class="d-block d-sm-none"
              @click="$store.dispatch('auth/showLocationModal')"
            >
              {{ $t('Konum Seç') }}
            </b-nav-item> -->
            <template v-if="$store.state.auth.isLogged">
              <b-nav-item-dropdown
                class="profil-h-w"
                menu-class="shadow-sm border-0"
                right
              >
                <template v-slot:button-content>
                  <span class="common-icon" v-html="icons.profil1"></span>
                  <span>
                    {{ $t('Profil') }}
                  </span>
                  <!-- <img
                    :src="$store.state.auth.info.profile_image"
                    class="nav-osahan-pic rounded-pill"
                  />
                  {{ $store.state.auth.info.name }} -->
                  <span
                    v-show="$store.state.auth.notificationUnreadCount > 0"
                    class="badge badge-success custom-h-badge"
                  >
                    {{ $store.state.auth.notificationUnreadCount }}
                  </span>
                </template>
                <div class="profil-menu-head">
                  <img
                    :src="$store.state.auth.info.profile_image"
                    onerror="this.style.display='none'"
                    class="nav-osahan-pic rounded-pill"
                  />
                  <span>
                    {{ $store.state.auth.info.name }}
                  </span>
                </div>
                <b-dropdown-item
                  v-if="
                    domainUrl && !($route.params && $route.params.branchSlug)
                  "
                  :to="
                    localePath({
                      name: 'restaurant-branchSlug',
                      params: {
                        branchSlug: domainUrl
                      }
                    })
                  "
                >
                  <span class="common-icon" v-html="icons.undo"></span>
                  <span>
                    {{ $t('Menüye Dön') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me')">
                  <span class="common-icon" v-html="icons.profil2"></span>
                  <span>
                    {{ $t('Profilim') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me-orders')">
                  <span class="common-icon" v-html="icons.order"></span>
                  <span>
                    {{ $t('Siparişlerim') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me-addresses')">
                  <span class="common-icon" v-html="icons.address"></span>
                  <span>
                    {{ $t('Adreslerim') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me-notifications')">
                  <span class="common-icon" v-html="icons.notification"></span>
                  <span>
                    {{ $t('Bildirimlerim') }}
                  </span>
                  <span
                    v-show="$store.state.auth.notificationUnreadCount > 0"
                    class="badge badge-success"
                  >
                    {{ $store.state.auth.notificationUnreadCount }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me-wallet')">
                  <span class="common-icon" v-html="icons.wallet"></span>
                  <span>
                    {{ $t('Cüzdanım') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me-point')">
                  <span class="common-icon" v-html="icons.reward1"></span>
                  <span>
                    {{ $t('Puanlarım') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('me-giftCards')">
                  <span class="common-icon" v-html="icons.giftCard"></span>
                  <span>
                    {{ $t('Hediye Çeklerim') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleLogout">
                  <span class="common-icon" v-html="icons.exit"></span>
                  <span>
                    {{ $t('Çıkış Yap') }}
                  </span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </template>
            <template v-else>
              <b-nav-item @click="$store.dispatch('auth/showLoginModal')">
                <span class="common-icon" v-html="icons.login"></span>
                <span>
                  {{ $t('Giriş Yap') }}
                </span>
              </b-nav-item>
              <b-nav-item
                v-if="false"
                @click="$store.dispatch('auth/showRegisterModal')"
              >
                <span class="common-icon" v-html="icons.register"></span>
                <span>
                  {{ $t('Kayıt Ol') }}
                </span>
              </b-nav-item>
            </template>
            <basket-item
              v-if="$store.state.auth.siteType === 'restaurant'"
            ></basket-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import smartAppBanner from '@/components/smartAppBanner'
import {
  location1,
  map,
  profil1,
  profil2,
  order,
  address,
  notification,
  giftCard,
  exit,
  login,
  register,
  search,
  undo,
  wallet,
  reward1
} from '@/assets/icon/index.js'
import basketItem from './items/basket'

export default {
  name: 'DefaultDomainHeader',
  components: {
    basketItem,
    smartAppBanner
  },
  data() {
    return {
      icons: {
        location1,
        map,
        profil1,
        profil2,
        order,
        address,
        notification,
        giftCard,
        exit,
        login,
        register,
        search,
        undo,
        wallet,
        reward1
      },
      domainUrl: null
    }
  },
  computed: {
    authModal() {
      return JSON.parse(JSON.stringify(this.$store.state.auth.modal))
    },
    locateModal() {
      return JSON.parse(JSON.stringify(this.$store.state.auth.locationModal))
    },
    logo() {
      const slug = this.$route.params.branchSlug || this.domainUrl

      const logo = slug
        ? this.$store.state.company.branch.find(i => i.slug === slug)?.image
        : this.$store.state.company.logo_url

      return logo || '/images/logoB.png'
    },
    smartAppBannerData() {
      const {
        logo_url: icon,
        name: title,
        settings: { app_store: iosLink, play_store: androidLink }
      } = this.$store.state.company

      return { iosLink, androidLink, icon, title }
    }
  },
  created() {
    this.domainUrl = this.$cookies.get('domainUrl')
      ? this.$cookies.get('domainUrl')
      : null
  },
  methods: {
    // Çıkış yap tıklandı
    async handleLogout() {
      await this.$store.dispatch('auth/logout')
      await this.$store.dispatch('basket/getBasket')
    },
    // Seçilen adresin kısaltılmış hali
    getShortAddress() {
      if (!this.$store.state.auth.locationAddress)
        return this.$t('Konum bilgisi alınamadı')

      try {
        const address = { ...this.$store.state.auth.locationAddress }

        delete address.country_code
        delete address.country
        delete address.state
        delete address.state_district
        delete address.postcode
        delete address.road
        delete address.house_number
        delete address.region
        delete address.province

        const r = Object.keys(address).map(i => {
          return address[i]
        })

        return r.join(' / ')
      } catch (error) {
        return this.$t('Konum bilgisi alınamadı')
      }
    }
  },
  head() {
    if (!this.$store.state.isBaseDomain)
      return {
        title:
          this.$store.state.company.settings.meta_title ||
          this.$t('Online Yemek Sipariş Sistemi'),
        meta: [
          // hid is used as unique identifier. Do not use `vmid` for it as it will not work
          {
            hid: 'description',
            name: 'description',
            content:
              this.$store.state.company.settings.meta_description ||
              this.$store.state.company.domainName
          }
        ],
        link: [
          {
            rel: 'icon',
            type: 'image/png',
            href:
              this.$store.state.company.settings.favicon_url ||
              '/favicon-76x76.png'
          }
        ]
      }

    return {}
  }
}
</script>
