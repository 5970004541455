var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c(
        "GmapMap",
        {
          ref: "gmapComponent",
          style: _vm.mapStyle,
          attrs: { options: _vm.options, center: _vm.center, zoom: _vm.zoom },
          on: { click: _vm.mapClick }
        },
        [
          _c("template", { slot: "visible" }, [
            !_vm.hideSearch
              ? _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("gmap-searchbox", {
                      attrs: { options: _vm.autocompleteOptions },
                      on: {
                        places_changed: _vm.setPlace,
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function(e) {
                            return e.preventDefault()
                          })($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.hideLocateMe
              ? _c(
                  "div",
                  {
                    staticClass: "locate-me control-button",
                    on: { click: _vm.locateMe }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "svg-inline--fa fa-map-marker-alt fa-w-12",
                        attrs: {
                          "aria-hidden": "true",
                          focusable: "false",
                          "data-prefix": "fas",
                          "data-icon": "map-marker-alt",
                          role: "img",
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 384 512"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.marker
            ? _c("GmapMarker", {
                attrs: {
                  position: _vm.marker,
                  draggable: !_vm.hideMarkerControll
                },
                on: { dragend: _vm.markerDragend }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }