<template>
  <zz-div :loading="loading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form
        novalidate
        class="customAddressForm"
        @submit.prevent="handleSubmit(handleFormSubmit)"
      >
        <div v-show="!stepData" class="stepDataAddress">
          <div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Konumunuz')"
              rules="required"
            >
              <!-- :label="$t('Siparişlerinizin gönderileceği adresi seçiniz')" -->

              <b-form-group label-for="input-coordinate">
                <span class="addressFWrapper elevation-1">
                  <span class="addressFW_1">
                    {{ $t('Siparişlerinizin Gönderileceği Adres') }}
                  </span>
                  <span class="addressFW_2">
                    {{
                      typeof location.addressDetail !== 'undefined' &&
                      typeof location.addressDetail.address !== 'undefined' &&
                      location.addressDetail &&
                      location.addressDetail.address
                        ? location.addressDetail.address
                        : $t('Lütfen bir adres seçin')
                    }}
                  </span>
                </span>
                <div style="height:400px; width:100%">
                  <client-only>
                    <zz-map
                      ref="map"
                      v-model="location"
                      hide-locate-me
                      get-address-text
                      @changed="changedLocation = true"
                    ></zz-map>
                  </client-only>
                </div>
                <b-form-invalid-feedback :state="state(errors[0])">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="formMapBtns mt-3 custom-modal-1-bottom px-3 py-2">
            <div class="locate-me-custom elevation-2" @click="locateMe">
              <svg
                id="Layer_1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path
                  d="m12 0a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12zm1 21.949v-1.949a1 1 0 0 0 -2 0v1.949a10.015 10.015 0 0 1 -8.949-8.949h1.949a1 1 0 0 0 0-2h-1.949a10.015 10.015 0 0 1 8.949-8.949v1.949a1 1 0 0 0 2 0v-1.949a10.015 10.015 0 0 1 8.949 8.949h-1.949a1 1 0 0 0 0 2h1.949a10.015 10.015 0 0 1 -8.949 8.949zm3-9.949a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1z"
                />
              </svg>
              <p>
                {{ $t('Konum Bul') }}
              </p>
            </div>
            <p class="btnNextW" @click="stepMethod">
              <span>
                {{ $t('Bu Adresi Kullan') }}
              </span>
              <svg
                id="Outline"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="21"
                height="21"
              >
                <path
                  d="M7,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l8.17-8.17a3,3,0,0,0,0-4.24L6.29,1.71A1,1,0,0,1,7.71.29l8.17,8.17a5,5,0,0,1,0,7.08L7.71,23.71A1,1,0,0,1,7,24Z"
                />
              </svg>
            </p>
          </div>
        </div>
        <div v-show="stepData" class="stepDataAddress px-4">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Adres Başlığı')"
            rules="required"
          >
            <b-form-group :label="$t('Adres Başlığı')" label-for="input-name">
              <b-form-input
                id="input-name"
                v-model="form.name"
                :state="state(errors[0])"
                :disabled="loading"
                type="text"
                required
              >
              </b-form-input>
              <b-form-invalid-feedback :state="state(errors[0])">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Açık Adres')"
            rules="required"
          >
            <b-form-group
              :label="$t('Açık Adres')"
              label-for="input-open_address"
            >
              <b-form-textarea
                id="input-open_address"
                v-model="form.open_address"
                style="font-weight:inherit;color:#88181a"
                :disabled="loading"
                :readonly="!editableOpenAddress"
                :state="state(errors[0])"
                required
                maxlength="511"
              >
              </b-form-textarea>
              <b-form-invalid-feedback :state="state(errors[0])">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Adres Tarifi')"
            rules="required"
          >
            <b-form-group
              :label="$t('Adres Tarifi')"
              label-for="input-directions"
              :description="$t('*Örn: Akın Apt. No:85 Süper Market üstü.')"
            >
              <b-form-textarea
                id="input-directions"
                v-model="form.directions"
                required
                maxlength="255"
                :disabled="loading"
                :state="state(errors[0])"
              >
              </b-form-textarea>
              <b-form-invalid-feedback :state="state(errors[0])">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-row>
            <b-col md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Ad Soyad')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Ad Soyad')"
                  label-for="input-contact_person"
                >
                  <b-form-input
                    id="input-contact_person"
                    v-model="form.contact_person"
                    :state="state(errors[0])"
                    :disabled="loading"
                    required
                  >
                  </b-form-input>
                  <b-form-invalid-feedback :state="state(errors[0])">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <div class="text-center text-lg-right customBottomBtns pb-2">
            <b-button
              class="customBtn1"
              variant="danger"
              @click="stepData = false"
            >
              {{ $t('Geri') }}
            </b-button>
            <b-button
              class="customBtn1"
              :disabled="loading"
              variant="primary"
              type="submit"
            >
              {{ $t('Kaydet') }}
            </b-button>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </zz-div>
</template>

<script>
import zzMap from '@/components/zzMap'
import { saveAddress, getAddress, updateAddress } from '@/api/member'

export default {
  name: 'CommonAddressForm',
  components: {
    zzMap
  },
  props: {
    addressId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      stepData: false,
      editableOpenAddress: true,
      form: {
        name: '',
        coordinate: [],
        contact_person: `${this.$store.state.auth.info.name || ''} ${this.$store
          .state.auth.info.surname || ''}`,
        open_address: '',
        directions: ''
      },
      location: [],
      error: '',
      loading: false,
      changedLocation: false
    }
  },
  computed: {},
  watch: {
    location(l) {
      if (Array.isArray(l)) {
        this.form.coordinate = `(${l.join(',')})`
      } else if (l.coords && l.coords.length) {
        const neighborhood = l.addressDetail.neighborhood
        const street = l.addressDetail.street
        const postalTown = l.addressDetail.postalTown
        const postalCode = l.addressDetail.postalCode
        const buildingNumber = l.addressDetail.buildingNumber

        this.form.coordinate = `(${l.coords.join(',')})`

        if (this.changedLocation || !this.addressId) {
          this.form.open_address = `${neighborhood ? neighborhood + ', ' : ''}${
            street ? street + ', ' : ''
          }${postalTown ? postalTown + ' ' : ''}${
            postalCode ? postalCode + ' ' : ''
          }`

          if (this.addressId) {
            this.form.open_address = `${
              neighborhood ? neighborhood + ', ' : ''
            }${street ? street + ', ' : ''}${
              postalTown ? postalTown + ' ' : ''
            }${postalCode ? postalCode + ' ' : ''}${this.$t(
              'Bina No'
            )}: ${buildingNumber || '-'}, ${this.$t('Daire No')}: -`
          }
        }
      }
    },
    addressId() {
      if (this.addressId && !isNaN(this.addressId)) {
        this.getForm()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof window !== 'undefined') {
        const map = document.querySelector('.bv-no-focus-ring img')

        setTimeout(() => {}, 1000)

        if (map) {
        }
      }
    })

    if (
      !this.$store.state.isBaseDomain &&
      // eslint-disable-next-line camelcase
      this.$store.state.company.settings.site?.member?.open_address_editable ===
        false
    ) {
      this.editableOpenAddress = false
    }

    if (this.addressId) {
      this.getForm()
    } else {
      this.location = this.$store.state.auth.location
    }
    // this.$refs.map.invalidateSize()
  },
  methods: {
    locateMe() {
      this.$refs.map.locateMe()
    },
    stepMethod() {
      this.stepData = true
    },
    async handleFormSubmit() {
      if (this.loading) return
      this.loading = true

      try {
        const params = { ...this.$route.params }

        params.memberId = this.$store.state.auth.info.id
        params.id = this.addressId

        let result = null
        const form = JSON.parse(JSON.stringify(this.form))

        if (this.addressId) {
          result = await this.$axios.request({
            ...updateAddress(params),
            data: form
          })
        } else {
          result = await this.$axios.request({
            ...saveAddress(params),
            data: form
          })
        }

        this.$notification.success(result.data)
        this.$emit('success', { ...this.form, location: this.location })
      } catch (error) {
        this.$notification.error(
          error.message || 'Bir hata oluştu! Lütfen tekrar deneyin'
        )
      } finally {
        this.loading = false
      }
    },
    async getForm() {
      try {
        this.loading = true

        const params = { ...this.$route.params }

        params.memberId = this.$store.state.auth.info.id
        params.id = this.addressId

        const result = await this.$axios.request({
          ...getAddress(params)
        })

        // this.form = result.data
        Object.keys(this.form).map(key => {
          this.form[key] = result.data[key]
        })

        // this.form.open_address = null
        if (
          result.data.coordinate &&
          result.data.coordinate.x &&
          result.data.coordinate.y
        ) {
          this.location = [result.data.coordinate.x, result.data.coordinate.y]
        }
      } catch (err) {
        this.$notification.error(err.message)
      } finally {
        this.loading = false
      }
    },
    state(error) {
      return typeof error === 'undefined' ? error : !error
    }
  }
}
</script>

<style scoped lang="scss">
// .stepDataAddressFirstActive {
//   // transform: rotateY(0deg);
//   // max-height: 1000px;
//   // max-height: 1000px;
//   // opacity: 1;
// }
// .stepDataAddressFirstDeactive {
//   // transform: rotateY(90deg);
//   // max-height: 0px;
//   // opacity: 0;
// }
// .stepDataAddressSecondActive {
//   // max-height: 1000px;
//   // transform: rotateY(0deg);
//   // max-height: 1000px;
//   // opacity: 1;
// }
// .stepDataAddressSecondDeactive {
//   // max-height: 0px;
//   // transform: rotateY(-90deg);
//   // opacity: 0;
// }

.customBottomBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stepDataAddress {
  // overflow-x: hidden;
  // overflow-y: initial;
  transition: 0.3s ease-in-out;
  // padding-bottom: 8px;
}
.customAddressForm {
  // overflow-x: hidden;
  // overflow-y: initial;
  padding: 8px 0;
}
.customAddressForm::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.customAddressForm::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.customAddressForm::-webkit-scrollbar-thumb {
  background-color: #424242;
}
// .customAddressForm
.addressFWrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // max-width: 400px;
  width: 98%;
  text-align: center;
  padding: 6px 4px;
  border-radius: 7px;
  background-color: #fafafa;
  margin-bottom: 12px;
  .addressFW_1 {
    border-bottom: 1px solid #ddd;
    margin-bottom: 2px;
    padding-bottom: 2px;
    font-weight: 700;
    color: #88181a;
  }
  .addressFW_2 {
    line-height: 16px;
    max-height: 33px;
    overflow: hidden;
  }
}
</style>
