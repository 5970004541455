import { postTraffic } from '@/api/traffic'

export default {
  methods: {
    async postTrafficMethod({
      type,
      email = null,
      coords = { lat: null, lon: null },
      cId = null
    }) {
      try {
        // if (
        //   this.$store.state.isBaseDomain &&
        //   !localStorage.getItem('testpixel')
        // ) {
        // if (!email) {
        //   email = this.$store.state.auth.isLogged
        //     ? this.$store.state.auth.info
        //       ? this.$store.state.auth.info.email
        //       : null
        //     : null
        const data = {
          movement_type: type,
          email,
          CID: cId || this.$cookies.get('CID') || null,
          query: this.$route.query,
          fullPath: this.$route.fullPath,
          coords
        }

        const result = await this.$axios.request({
          ...postTraffic(),
          data
        })

        this.$cookies.set('CID', result.data, {
          maxAge: 60 * 60 * 24,
          path: '/'
        })

        return result.data
        // sessionStorage.setItem('CID', result.data)
        // }
      } catch (e) {}
    }
  }
}
