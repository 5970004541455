const at = `
<svg
id="Layer_1"
xmlns="http://www.w3.org/2000/svg"
data-name="Layer 1"
viewBox="0 0 24 24"
width="14"
height="14"
>
<path
  d="M12,0A12.013,12.013,0,0,0,0,12c-.126,9.573,11.159,15.429,18.9,9.817a1,1,0,1,0-1.152-1.634C11.3,24.856,1.9,19.978,2,12,2.549-1.266,21.453-1.263,22,12v2a2,2,0,0,1-4,0V12C17.748,4.071,6.251,4.072,6,12a6.017,6.017,0,0,0,10.52,3.933A4,4,0,0,0,24,14V12A12.013,12.013,0,0,0,12,0Zm0,16a4,4,0,0,1,0-8A4,4,0,0,1,12,16Z"
/>
</svg>
`

const password = `
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="18" height="18"><path d="M19,8.424V7A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5,5,0,0,0,19,8.424ZM7,7A5,5,0,0,1,17,7V8H7ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z"/><path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z"/></svg>
`

const reference = `
<svg
  id="Capa_1"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="18px"
  height="18px"
  viewBox="0 0 84.948 84.949"
  style="enable-background:new 0 0 84.948 84.949;"
  xml:space="preserve"
>
  <g>
    <path
      d="M14.169,36.862c-2.174,2.655-6.088,3.047-8.744,0.875l-3.148-2.576c-2.654-2.173-3.045-6.087-0.873-8.745L17.826,6.349
        c2.172-2.658,6.086-3.048,8.742-0.875l3.148,2.575c2.656,2.173,3.047,6.088,0.873,8.744L14.169,36.862z M82.801,22.734
        L63.195,5.764c-2.594-2.247-6.52-1.963-8.764,0.631l-2.664,3.077c-2.244,2.595-1.963,6.518,0.633,8.764l19.605,16.97
        c2.594,2.246,6.52,1.963,8.766-0.631l2.66-3.075C85.678,28.904,85.396,24.98,82.801,22.734z M73.119,50.582
        c0.347,3.45-0.674,6.827-2.871,9.512l-7.862,9.604c-2.197,2.685-5.308,4.353-8.754,4.695c-1.082,0.107-2.156,0.083-3.205-0.072
        c-1.98-0.291-3.853-1.068-5.515-2.235l-3.303,4.035c-2.875,3.514-7.455,5.284-11.949,4.621c-2.314-0.341-4.5-1.308-6.318-2.796
        l-9.605-7.864c-2.684-2.196-4.352-5.306-4.697-8.756c-0.344-3.449,0.678-6.828,2.875-9.509l4.4-5.38
        c0.313-0.383,0.766-0.626,1.258-0.675c0.49-0.05,0.984,0.1,1.365,0.412l21.773,17.821c0.135,0.063,0.27,0.139,0.391,0.238
        l5.379,4.401c1.914,1.566,4.32,2.295,6.777,2.049c2.459-0.247,4.676-1.436,6.241-3.347l7.863-9.605
        c3.232-3.949,2.646-9.789-1.301-13.021l-5.586-4.333c-2.613,2.83-5.738,4.388-9.312,4.627c-6.893,0.461-13.537-4.19-16.582-6.699
        c-4.377,6.284-9.539,6.419-9.775,6.422c-0.1,0.001-0.197-0.006-0.293-0.021c-0.215-0.031-0.424-0.1-0.615-0.202
        c-1.918-1.037-3.045-2.319-3.445-3.924c-0.537-2.15,0.264-4.679,2.602-8.195c5.809-8.75,12.646-8.694,16.732-8.661
        c0.936,0.007,1.746,0.015,2.361-0.075c2.706-0.392,3.875-3.178,3.887-3.208c0.379-0.956,1.463-1.422,2.418-1.05
        c0.957,0.379,1.429,1.462,1.049,2.42c-0.075,0.195-1.952,4.821-6.818,5.526c-0.896,0.13-1.883,0.122-2.928,0.114
        c-3.967-0.033-8.904-0.073-13.598,6.995c-2.039,3.07-2.262,4.54-2.088,5.23c0.07,0.29,0.271,0.719,1.121,1.264
        c1.168-0.222,4.5-1.291,7.346-6.398c0.283-0.507,0.787-0.853,1.361-0.936c0.574-0.083,1.158,0.105,1.572,0.512
        c0.078,0.077,7.934,7.671,15.454,7.164c2.991-0.204,5.491-1.65,7.646-4.421c0.504-0.645,1.346-0.862,2.078-0.609
        c0.381,0.011,0.764,0.132,1.088,0.384l6.648,5.156C71.107,44.023,72.775,47.132,73.119,50.582z M41.945,69.825L18.017,50.242
        l-3.221,3.938c-1.566,1.911-2.291,4.318-2.047,6.776c0.246,2.46,1.436,4.678,3.348,6.242l9.605,7.864
        c1.297,1.062,2.854,1.751,4.5,1.994c3.256,0.479,6.439-0.752,8.521-3.293L41.945,69.825z"
    />
  </g>
</svg>
`

const location1 = `
<svg
    xmlns="http://www.w3.org/2000/svg"
    height="18px"
    viewBox="0 0 24 24"
    width="18px"
    fill="#000000"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
    />
  </svg>
`

const map = `
<svg xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24" width="18" height="18"><path d="M12,.021A10.077,10.077,0,0,0,1.935,10.087c0,5.274,8.166,12.329,9.1,13.117l.967.817.967-.817c.932-.788,9.1-7.843,9.1-13.117A10.077,10.077,0,0,0,12,.021Zm0,20.043c-3.065-2.792-7.067-7.328-7.067-9.977a7.067,7.067,0,1,1,14.134,0C19.067,12.731,15.064,17.269,12,20.064Z"/><circle cx="12" cy="10.002" r="2.997"/></svg>
`

const map1 = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M12,0A10.011,10.011,0,0,0,2,10c0,5.282,8.4,12.533,9.354,13.343l.646.546.646-.546C13.6,22.533,22,15.282,22,10A10.011,10.011,0,0,0,12,0Zm0,15a5,5,0,1,1,5-5A5.006,5.006,0,0,1,12,15Z"/><circle cx="12" cy="10" r="3"/></svg>
`

const profil1 = `
<svg
  id="Outline"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
>
  <path
    d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z"
  />
  <path
    d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"
  />
</svg>
`

const profil2 = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <path d="M0 0h24v24H0V0z" fill="none" />
  <path
    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"
  />
</svg>
`

const order = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <path d="M0 0h24v24H0V0z" fill="none" />
  <path
    d="M16 6v8h3v8h2V2c-2.76 0-5 2.24-5 4zm-5 3H9V2H7v7H5V2H3v7c0 2.21 1.79 4 4 4v9h2v-9c2.21 0 4-1.79 4-4V2h-2v7z"
  />
</svg>
`

const address = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <path d="M0 0h24v24H0z" fill="none" />
  <path
    d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"
  />
</svg>
`

const notification = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><g id="_01_align_center" data-name="01 align center"><path d="M23.259,16.2l-2.6-9.371A9.321,9.321,0,0,0,2.576,7.3L.565,16.35A3,3,0,0,0,3.493,20H7.1a5,5,0,0,0,9.8,0h3.47a3,3,0,0,0,2.89-3.8ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm9.165-4.395a.993.993,0,0,1-.8.395H3.493a1,1,0,0,1-.976-1.217l2.011-9.05a7.321,7.321,0,0,1,14.2-.372l2.6,9.371A.993.993,0,0,1,21.165,17.605Z"/></g></svg>
`

const notification1 = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M4.068,18H19.724a3,3,0,0,0,2.821-4.021L19.693,6.094A8.323,8.323,0,0,0,11.675,0h0A8.321,8.321,0,0,0,3.552,6.516l-2.35,7.6A3,3,0,0,0,4.068,18Z"/><path d="M7.1,20a5,5,0,0,0,9.8,0Z"/></svg>
`

const favorite = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <path d="M0 0h24v24H0V0z" fill="none" />
  <path
    d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
  />
</svg>
`

const wallet = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <path d="M0 0h24v24H0V0z" fill="none" />
  <path
    d="M21 7.28V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-2.28c.59-.35 1-.98 1-1.72V9c0-.74-.41-1.37-1-1.72zM20 9v6h-7V9h7zM5 19V5h14v2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6v2H5z"
  />
  <circle cx="16" cy="12" r="1.5" />
</svg>
`

const giftCard = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <path d="M0 0h24v24H0z" fill="none" />
  <path
    d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z"
  />
</svg>
`

const exit = `
<svg
  id="Outline"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="18"
  height="18"
>
  <path
    d="M7,22H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7A1,1,0,0,0,7,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z"
  />
  <path
    d="M23,11h0l-15.777.032a2.018,2.018,0,0,1,.326-.446l3.879-3.879a1,1,0,1,0-1.414-1.414L6.133,9.172a4,4,0,0,0,0,5.656l3.879,3.879a1,1,0,1,0,1.414-1.414L7.547,13.414a2.01,2.01,0,0,1-.291-.382L23,13a1,1,0,0,0,0-2Z"
  />
</svg>
`

const comment = `
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="18" height="18"><path d="M24,11.247A12.012,12.012,0,1,0,12.017,24H19a5.005,5.005,0,0,0,5-5V11.247ZM22,19a3,3,0,0,1-3,3H12.017a10.041,10.041,0,0,1-7.476-3.343,9.917,9.917,0,0,1-2.476-7.814,10.043,10.043,0,0,1,8.656-8.761A10.564,10.564,0,0,1,12.021,2,9.921,9.921,0,0,1,18.4,4.3,10.041,10.041,0,0,1,22,11.342Z"/><path d="M8,9h4a1,1,0,0,0,0-2H8A1,1,0,0,0,8,9Z"/><path d="M16,11H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"/><path d="M16,15H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"/></svg>
`

const comment1 = `
<svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="18" height="18"><path d="M19.675,2.758A11.936,11.936,0,0,0,10.474.1,12,12,0,0,0,12.018,24H19a5.006,5.006,0,0,0,5-5V11.309l0-.063A12.044,12.044,0,0,0,19.675,2.758ZM8,7h4a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm8,10H8a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Zm0-4H8a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z"/></svg>
`

const login = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z"
  />
  <path
    d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"
  />
</svg>
`

const register = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z"
  />
  <path
    d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z"
  />
  <path
    d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z"
  />
</svg>
`

const basket = `
<svg
  id="Outline"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="21"
  height="21"
>
  <path
    d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41A1,1,0,0,1,21.4,6.178Z"
  />
  <circle cx="7" cy="22" r="2" />
  <circle cx="17" cy="22" r="2" />
</svg>
`

const basket2 = `
<svg version="1.1" id="katman_1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 190 176" style="enable-background:new 0 0 190 176;" xml:space="preserve">
	<style type="text/css">
	.st0{fill:#88181A;}
	.st1{fill:#FFFFFF;}
	.st2{fill:none;stroke:#88181A;stroke-width:9;stroke-linecap:round;stroke-linejoin:round;}
	.st3{fill:none;stroke:#88181A;stroke-width:11.48;}
	.st4{fill:none;stroke:#88181A;stroke-width:11.48;stroke-linecap:round;stroke-linejoin:round;}
	</style>
	<circle class="st0" cx="85" cy="54" r="5"/>
	<circle class="st1" cx="83" cy="56" r="1"/>
	<circle id="Ellipse_3_copy" class="st1" cx="87" cy="51" r="1"/>
	<circle id="Ellipse_2_copy" class="st0" cx="117" cy="54" r="5"/>
	<path class="st2" d="M82,86.4c0,0,20-15.4,40,0.1"/>
	<path class="st3" d="M75.6,137.1c6.3,0,11.4,5.1,11.4,11.4c0,6.3-5.1,11.4-11.4,11.4c-6.3,0-11.4-5.1-11.4-11.4
	C64.2,142.3,69.3,137.1,75.6,137.1C75.6,137.1,75.6,137.1,75.6,137.1z"/>
	<circle id="Ellipse_3_copy_3" class="st1" cx="119" cy="51" r="1"/>
	<path id="Ellipse_1_copy" class="st3" d="M130.6,137.1c6.3,0,11.4,5.1,11.4,11.4s-5.1,11.4-11.4,11.4c-6.3,0-11.4-5.1-11.4-11.4
	C119.2,142.3,124.3,137.1,130.6,137.1C130.6,137.1,130.6,137.1,130.6,137.1z"/>
	<circle id="Ellipse_3_copy_2" class="st1" cx="115" cy="56" r="1"/>
	<path class="st4" d="M17,22h19l17,100h99l28-73h-36"/>
</svg>
`

const basket3 = `
<svg
  class="empty-basket-icon"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="24"
  height="24"
>
  <path
    d="M21,6H18A6,6,0,0,0,6,6H3A3,3,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A3,3,0,0,0,21,6ZM12,2a4,4,0,0,1,4,4H8A4,4,0,0,1,12,2ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9A1,1,0,0,1,3,8H6v2a1,1,0,0,0,2,0V8h8v2a1,1,0,0,0,2,0V8h3a1,1,0,0,1,1,1Z"
  ></path>
</svg>
`

const edit = `
<svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="18" height="18"><path d="M1.172,19.119A4,4,0,0,0,0,21.947V24H2.053a4,4,0,0,0,2.828-1.172L18.224,9.485,14.515,5.776Z"/><path d="M23.145.855a2.622,2.622,0,0,0-3.71,0L15.929,4.362l3.709,3.709,3.507-3.506A2.622,2.622,0,0,0,23.145.855Z"/></svg>
`

const warning = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M12,4a8.009,8.009,0,0,0-8,8c.376,10.588,15.626,10.585,16,0A8.009,8.009,0,0,0,12,4Zm0,14C4.071,17.748,4.072,6.251,12,6,19.929,6.252,19.928,17.749,12,18Zm1-9v3a1,1,0,0,1-2,0V9A1,1,0,0,1,13,9Zm11,3a12.026,12.026,0,0,1-2.743,7.637,1,1,0,0,1-1.543-1.274,10.052,10.052,0,0,0,0-12.726,1,1,0,0,1,1.543-1.274A12.026,12.026,0,0,1,24,12ZM4.286,18.363a1,1,0,0,1-1.542,1.274,12.065,12.065,0,0,1,0-15.274A1,1,0,0,1,4.286,5.637,10.052,10.052,0,0,0,4.286,18.363ZM13,15a1,1,0,0,1-2,0A1,1,0,0,1,13,15Z"/></svg>
`

const gift = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M20,7H18.262A5.137,5.137,0,0,0,20,3a1,1,0,0,0-2,0c0,2.622-2.371,3.53-4.174,3.841A9.332,9.332,0,0,0,15,3,3,3,0,0,0,9,3a9.332,9.332,0,0,0,1.174,3.841C8.371,6.53,6,5.622,6,3A1,1,0,0,0,4,3,5.137,5.137,0,0,0,5.738,7H4a4,4,0,0,0-4,4v1a2,2,0,0,0,2,2v5a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V14a2,2,0,0,0,2-2V11A4,4,0,0,0,20,7ZM12,2a1,1,0,0,1,1,1,7.71,7.71,0,0,1-1,3.013A7.71,7.71,0,0,1,11,3,1,1,0,0,1,12,2ZM2,11A2,2,0,0,1,4,9h7v3H2Zm2,8V14h7v8H7A3,3,0,0,1,4,19Zm16,0a3,3,0,0,1-3,3H13V14h7Zm-7-7V9h7a2,2,0,0,1,2,2v1Z"/></svg>
`

const gift1 = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M2,15h9v9H7a5,5,0,0,1-5-5Zm22-4a2,2,0,0,1-2,2H13V8.957c-.336.026-.671.043-1,.043s-.664-.017-1-.043V13H2a2,2,0,0,1-2-2A4,4,0,0,1,4,7H5.738A5.137,5.137,0,0,1,4,3,1,1,0,0,1,6,3c0,2.622,2.371,3.53,4.174,3.841A9.332,9.332,0,0,1,9,3a3,3,0,0,1,6,0,9.332,9.332,0,0,1-1.174,3.841C15.629,6.53,18,5.622,18,3a1,1,0,0,1,2,0,5.137,5.137,0,0,1-1.738,4H20A4,4,0,0,1,24,11ZM11,3a7.71,7.71,0,0,0,1,3.013A7.71,7.71,0,0,0,13,3a1,1,0,0,0-2,0Zm2,21h4a5,5,0,0,0,5-5V15H13Z"/></svg>
`

const plus = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="18" height="18">
  <g>
    <path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z"/>
  </g>
  </svg>
`

const search = `
<svg
  id="Outline"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="18"
  height="18"
>
  <path
    d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"
  />
</svg>
`

const close = `
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="18" height="18"><path d="M23.707.293h0a1,1,0,0,0-1.414,0L12,10.586,1.707.293a1,1,0,0,0-1.414,0h0a1,1,0,0,0,0,1.414L10.586,12,.293,22.293a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0L12,13.414,22.293,23.707a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L13.414,12,23.707,1.707A1,1,0,0,0,23.707.293Z"/></svg>
`

const favorite1 = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M17.5.917a6.4,6.4,0,0,0-5.5,3.3A6.4,6.4,0,0,0,6.5.917,6.8,6.8,0,0,0,0,7.967c0,6.775,10.956,14.6,11.422,14.932l.578.409.578-.409C13.044,22.569,24,14.742,24,7.967A6.8,6.8,0,0,0,17.5.917Z"/></svg>
`

const trash = `
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="18" height="18"><path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z"/><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"/><path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"/></svg>
`

const star = `
<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-star fa-w-18"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" class=""></path></svg>
`

const mapMarker = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,6Zm8.66,3.157-.719-.239A8,8,0,0,0,12,0,7.993,7.993,0,0,0,4.086,9.092a5.045,5.045,0,0,0-2.548,1.3A4.946,4.946,0,0,0,0,14v4.075a5.013,5.013,0,0,0,3.6,4.8l2.87.9A4.981,4.981,0,0,0,7.959,24a5.076,5.076,0,0,0,1.355-.186l5.78-1.71a2.987,2.987,0,0,1,1.573,0l2.387.8A4,4,0,0,0,24,19.021V13.872A5.015,5.015,0,0,0,20.66,9.156ZM7.758,3.762a5.987,5.987,0,0,1,8.484,0,6.037,6.037,0,0,1,.011,8.5L12.7,15.717a.992.992,0,0,1-1.389,0L7.758,12.277A6.04,6.04,0,0,1,7.758,3.762ZM22,19.021a1.991,1.991,0,0,1-.764,1.572,1.969,1.969,0,0,1-1.626.395L17.265,20.2a5.023,5.023,0,0,0-2.717-.016L8.764,21.892a3,3,0,0,1-1.694-.029l-2.894-.9A3.013,3.013,0,0,1,2,18.075V14a2.964,2.964,0,0,1,.92-2.163,3.024,3.024,0,0,1,1.669-.806A8.021,8.021,0,0,0,6.354,13.7l3.567,3.453a2.983,2.983,0,0,0,4.174,0l3.563-3.463a7.962,7.962,0,0,0,1.813-2.821l.537.178A3.006,3.006,0,0,1,22,13.872Z"/></svg>
`

const megaphone = `
<svg id="Layer_1" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m17 0a1 1 0 0 0 -1 1c0 2.949-2.583 4-5 4h-7a4 4 0 0 0 -4 4v2a3.979 3.979 0 0 0 1.514 3.109l3.572 7.972a3.233 3.233 0 0 0 2.953 1.919 2.982 2.982 0 0 0 2.72-4.2l-2.2-4.8h2.441c2.417 0 5 1.051 5 4a1 1 0 0 0 2 0v-18a1 1 0 0 0 -1-1zm-8.063 20.619a.983.983 0 0 1 -.898 1.381 1.232 1.232 0 0 1 -1.126-.734l-2.808-6.266h2.254zm7.063-6.019a7.723 7.723 0 0 0 -5-1.6h-7a2 2 0 0 1 -2-2v-2a2 2 0 0 1 2-2h7a7.723 7.723 0 0 0 5-1.595zm7.9.852a1 1 0 0 1 -1.342.448l-2-1a1 1 0 0 1 .894-1.79l2 1a1 1 0 0 1 .448 1.337zm-3.79-9a1 1 0 0 1 .448-1.342l2-1a1 1 0 1 1 .894 1.79l-2 1a1 1 0 0 1 -1.342-.448zm-.11 3.548a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 -1-1z"/></svg>
`

const rightArrow = `
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="18" height="18"><path d="M7,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l8.17-8.17a3,3,0,0,0,0-4.24L6.29,1.71A1,1,0,0,1,7.71.29l8.17,8.17a5,5,0,0,1,0,7.08L7.71,23.71A1,1,0,0,1,7,24Z"/></svg>
`

const refresh = `
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="18" height="18"><path d="M21.962,12.875A10.03,10.03,0,1,1,19.122,5H16a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h4.143A1.858,1.858,0,0,0,22,5.143V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V3.078A11.985,11.985,0,1,0,23.95,13.1a1.007,1.007,0,0,0-1-1.1h0A.982.982,0,0,0,21.962,12.875Z"/></svg>
`

const spinner = `
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="18" height="18"><path d="M.415,12a12,12,0,0,1,23.17-4.364l-1.863.728A10,10,0,1,0,12.415,22a9.939,9.939,0,0,0,9.307-6.364l1.863.728A12,12,0,0,1,.415,12Z"/></svg>
`

const deleteAccount = `
<svg id="Layer_1" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 12a6 6 0 1 0 -6-6 6.006 6.006 0 0 0 6 6zm0-10a4 4 0 1 1 -4 4 4 4 0 0 1 4-4zm9 21a1 1 0 0 1 -2 0 7 7 0 0 0 -14 0 1 1 0 0 1 -2 0 9 9 0 0 1 18 0zm5.707-8.707a1 1 0 1 1 -1.414 1.414l-1.793-1.793-1.793 1.793a1 1 0 0 1 -1.414-1.414l1.793-1.793-1.793-1.793a1 1 0 0 1 1.414-1.414l1.793 1.793 1.793-1.793a1 1 0 0 1 1.414 1.414l-1.793 1.793z"/></svg>
`

const undo = `
<svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="18" height="18"><path d="M15,7H10.17V5.414A2,2,0,0,0,6.756,4L.876,9.879a3,3,0,0,0,0,4.242L6.756,20a2,2,0,0,0,3.414-1.414V17H16a6.006,6.006,0,0,1,6,6,1,1,0,0,0,2,0V16A9.01,9.01,0,0,0,15,7Z"/></svg>
`

const motorcycle = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M512.9 192c-14.9-.1-29.1 2.3-42.4 6.9L437.6 144H520c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24h-45.3c-6.8 0-13.3 2.9-17.8 7.9l-37.5 41.7-22.8-38C392.2 68.4 384.4 64 376 64h-80c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h66.4l19.2 32H227.9c-17.7-23.1-44.9-40-99.9-40H72.5C59 104 47.7 115 48 128.5c.2 13 10.9 23.5 24 23.5h56c24.5 0 38.7 10.9 47.8 24.8l-11.3 20.5c-13-3.9-26.9-5.7-41.3-5.2C55.9 194.5 1.6 249.6 0 317c-1.6 72.1 56.3 131 128 131 59.6 0 109.7-40.8 124-96h84.2c13.7 0 24.6-11.4 24-25.1-2.1-47.1 17.5-93.7 56.2-125l12.5 20.8c-27.6 23.7-45.1 58.9-44.8 98.2.5 69.6 57.2 126.5 126.8 127.1 71.6.7 129.8-57.5 129.2-129.1-.7-69.6-57.6-126.4-127.2-126.9zM128 400c-44.1 0-80-35.9-80-80s35.9-80 80-80c4.2 0 8.4.3 12.5 1L99 316.4c-8.8 16 2.8 35.6 21 35.6h81.3c-12.4 28.2-40.6 48-73.3 48zm463.9-75.6c-2.2 40.6-35 73.4-75.5 75.5-46.1 2.5-84.4-34.3-84.4-79.9 0-21.4 8.4-40.8 22.1-55.1l49.4 82.4c4.5 7.6 14.4 10 22 5.5l13.7-8.2c7.6-4.5 10-14.4 5.5-22l-48.6-80.9c5.2-1.1 10.5-1.6 15.9-1.6 45.6-.1 82.3 38.2 79.9 84.3z"/></svg>
`

const tableChair = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 22H6A2 2 0 0 1 8 20V8H2V5H16V8H10V20A2 2 0 0 1 12 22M22 2V22H20V15H15V22H13V14A2 2 0 0 1 15 12H20V2Z" /></svg>
`

const humanBoard = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20,17A2,2 0 0,0 22,15V4A2,2 0 0,0 20,2H9.46C9.81,2.61 10,3.3 10,4H20V15H11V17M15,7V9H9V22H7V16H5V22H3V14H1.5V9A2,2 0 0,1 3.5,7H15M8,4A2,2 0 0,1 6,6A2,2 0 0,1 4,4A2,2 0 0,1 6,2A2,2 0 0,1 8,4Z" /></svg>
`

const restaurant = `
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M285 976V608q-52-11-88.5-52.5T160 456V176h60v280h65V176h60v280h65V176h60v280q0 58-36.5 99.5T345 608v368h-60Zm415 0V656H585V351q0-79 48-127t127-48v800h-60Z"/></svg>
`

const complete = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 1080 1080"
  width="95"
  height="95"
  preserveAspectRatio="xMidYMid meet"
  style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px);"
>
  <g clip-path="url(#__lottie_element_40)">
    <g
      transform="matrix(1.5835800170898438,0,0,1.5835800170898438,389.72845458984375,255.67628479003906)"
      opacity="1"
      style="display: block;"
    >
      <g
        opacity="1"
        transform="matrix(1,0,0,1,162.94000244140625,124.44400024414062)"
      >
        <path
          class="complete-icon-done"
          fill="rgb(230,230,230)"
          fill-opacity="1"
          d=" M156.13099670410156,-117.35199737548828 C149.57000732421875,-124.06800079345703 138.80599975585938,-124.19400024414062 132.08999633789062,-117.63400268554688 C132.08999633789062,-117.63400268554688 -71.88999938964844,81.625 -71.88999938964844,81.625 C-71.88999938964844,81.625 -132.00999450683594,21.506000518798828 -132.00999450683594,21.506000518798828 C-138.6490020751953,14.866999626159668 -149.41200256347656,14.866999626159668 -156.05099487304688,21.506000518798828 C-162.69000244140625,28.145000457763672 -162.69000244140625,38.909000396728516 -156.05099487304688,45.54800033569336 C-156.05099487304688,45.54800033569336 -84.0510025024414,117.5479965209961 -84.0510025024414,117.5479965209961 C-77.46700286865234,124.13200378417969 -66.81199645996094,124.19400024414062 -60.152000427246094,117.68800354003906 C-60.152000427246094,117.68800354003906 155.84800720214844,-93.31199645996094 155.84800720214844,-93.31199645996094 C162.56399536132812,-99.87300109863281 162.6909942626953,-110.63600158691406 156.13099670410156,-117.35199737548828z"
        ></path>
      </g>
    </g>
    <g
      transform="matrix(0.9838600158691406,0,0,0.9838600158691406,535.7949829101562,535.5679931640625)"
      opacity="1"
      style="display: block;"
    >
      <g
        opacity="1"
        transform="matrix(0.8901246786117554,0.4692300856113434,-0.4692300856113434,0.8901246786117554,3.7179999351501465,3.742000102996826)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="miter"
          fill-opacity="0"
          stroke-miterlimit="4"
          stroke="rgb(136,24,26)"
          stroke-opacity="1"
          stroke-width="54"
          d=" M297.8789978027344,-210.73899841308594 C340.0989990234375,-151.197998046875 364.92498779296875,-78.4749984741211 364.92498779296875,0 C364.92498779296875,201.402099609375 201.402099609375,364.92498779296875 0,364.92498779296875 C-201.402099609375,364.92498779296875 -364.92498779296875,201.402099609375 -364.92498779296875,0 C-364.92498779296875,-201.402099609375 -201.402099609375,-364.92498779296875 0,-364.92498779296875"
        ></path>
      </g>
    </g>
  </g>
</svg>
`

const reward = `
<?xml version="1.0" encoding="iso-8859-1"?>
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg fill="#000000" height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512.039 512.039" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M309.139,234.667c11.755,0,21.333-9.557,21.333-21.333v-42.667c0-11.776-9.579-21.333-21.333-21.333
				s-21.333,9.557-21.333,21.333v42.667C287.806,225.109,297.385,234.667,309.139,234.667z"/>
			<path d="M90.921,236.181c1.088,4.075-0.533,11.072-2.091,17.792c-2.56,11.008-5.44,23.467,0.683,34.069
				c6.208,10.709,18.539,14.443,29.44,17.728c6.549,1.984,13.312,4.032,16.171,6.891c2.859,2.88,4.907,9.643,6.891,16.192
				c3.285,10.88,7.019,23.232,17.771,29.44c8.981,5.205,22.123,3.435,34.027,0.683c7.083-1.621,14.165-3.072,17.792-2.091
				c3.733,0.981,9.003,5.931,13.653,10.304c8.384,7.893,17.899,16.811,30.549,16.811s22.165-8.917,30.549-16.789
				c4.651-4.373,9.92-9.344,13.632-10.304c3.691-0.981,10.731,0.448,17.813,2.091c11.925,2.752,25.045,4.523,34.048-0.683
				c10.731-6.208,14.464-18.539,17.749-29.44c1.984-6.549,4.011-13.291,6.891-16.171s9.643-4.907,16.171-6.891
				c10.88-3.285,23.232-7.019,29.419-17.749c6.144-10.603,3.243-23.061,0.704-34.069c-1.557-6.72-3.179-13.717-2.091-17.792
				c1.003-3.733,5.952-9.003,10.325-13.653c7.872-8.384,16.789-17.877,16.789-30.549c0-12.651-8.917-22.144-16.789-30.528
				c-4.373-4.672-9.323-9.941-10.325-13.675c-1.088-4.075,0.533-11.072,2.091-17.792c2.56-11.008,5.44-23.467-0.683-34.069
				c-6.208-10.709-18.539-14.443-29.44-17.728c-6.549-1.984-13.312-4.032-16.171-6.891c-2.859-2.88-4.907-9.643-6.891-16.192
				c-3.285-10.88-7.019-23.232-17.771-29.44c-8.96-5.205-22.123-3.435-34.027-0.683c-7.083,1.643-14.144,3.051-17.792,2.091
				c-3.733-0.981-9.003-5.931-13.653-10.304C277.971,8.917,268.457,0,255.806,0c-12.651,0-22.165,8.917-30.549,16.789
				c-4.651,4.373-9.92,9.344-13.632,10.304c-3.691,0.981-10.731-0.448-17.813-2.091c-11.904-2.752-25.045-4.523-34.048,0.683
				c-10.731,6.208-14.464,18.539-17.749,29.44c-1.984,6.549-4.011,13.291-6.891,16.171s-9.643,4.907-16.171,6.891
				c-10.88,3.285-23.232,7.019-29.419,17.749c-6.144,10.603-3.243,23.061-0.704,34.069c1.557,6.72,3.179,13.717,2.091,17.792
				c-1.003,3.733-5.952,9.003-10.325,13.653c-7.872,8.384-16.789,17.877-16.789,30.549c0,12.651,8.917,22.144,16.789,30.528
				C84.969,227.179,89.918,232.448,90.921,236.181z M266.473,170.667c0-23.531,19.136-42.667,42.667-42.667
				s42.667,19.136,42.667,42.667v42.667c0,23.531-19.136,42.667-42.667,42.667s-42.667-19.136-42.667-42.667V170.667z M213.139,192
				h-42.667c-5.888,0-10.667-4.779-10.667-10.667v-42.667c0-5.888,4.779-10.667,10.667-10.667h64
				c5.888,0,10.667,4.779,10.667,10.667s-4.779,10.667-10.667,10.667h-53.333v21.333h32c17.643,0,32,14.357,32,32V224
				c0,17.643-14.357,32-32,32h-21.333c-17.643,0-32-14.357-32-32c0-5.888,4.779-10.667,10.667-10.667s10.667,4.779,10.667,10.667
				c0,5.867,4.779,10.667,10.667,10.667h21.333c5.888,0,10.667-4.8,10.667-10.667v-21.333C223.806,196.8,219.027,192,213.139,192z"
				/>
			<path d="M445.971,494.763L393.79,368.021c-1.429-3.477-4.587-5.931-8.32-6.507c-3.797-0.448-7.467,0.939-9.835,3.84
				c-3.947,4.864-8.213,8.555-13.099,11.392c-14.507,8.384-33.344,6.72-50.965,2.688c-3.52-0.939-8,0.832-10.624,3.307
				c-8.427,7.915-22.507,21.141-42.304,22.336c-0.619,0.043-1.152,0.384-1.749,0.512c-0.384-0.021-0.704-0.256-1.088-0.256
				c-21.099,0-35.563-13.589-45.845-23.232c-2.624-2.517-7.851-3.179-11.371-2.325c-14.677,3.392-35.051,5.312-49.451-2.987
				c-4.928-2.859-9.237-6.613-13.163-11.435c-2.389-2.901-6.144-4.309-9.813-3.84c-3.733,0.555-6.891,3.029-8.32,6.507
				L64.617,497.28c-1.685,4.117-0.661,8.853,2.603,11.883c2.027,1.877,4.629,2.859,7.253,2.859c1.621,0,3.264-0.384,4.779-1.131
				l79.787-39.893l58.859,39.232c2.688,1.813,6.123,2.261,9.195,1.28c3.115-1.003,5.568-3.349,6.72-6.4l23.296-62.144l20.587,61.781
				c1.045,3.136,3.52,5.632,6.656,6.699c3.115,1.109,6.613,0.64,9.365-1.216l58.859-39.232l79.787,39.893
				c1.515,0.747,3.136,1.131,4.779,1.131h0.213c5.035-0.085,10.859-4.715,10.88-10.667
				C448.233,498.859,447.379,496.576,445.971,494.763z"/>
		</g>
	</g>
</g>
</svg>
`

const reward1 = `
<?xml version="1.0" encoding="iso-8859-1"?>
<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 width="32px" height="32px" viewBox="0 0 54.558 54.559"
	 xml:space="preserve">
<g>
	<g>
		<path d="M27.28,3.911c-8.024,0-14.553,6.528-14.553,14.552s6.528,14.553,14.553,14.553c8.024,0,14.552-6.529,14.552-14.553
			S35.304,3.911,27.28,3.911z M27.28,31.016c-6.921,0-12.553-5.631-12.553-12.553c0-6.921,5.631-12.552,12.553-12.552
			c6.921,0,12.552,5.631,12.552,12.552C39.832,25.384,34.201,31.016,27.28,31.016z"/>
		<path d="M45.743,18.463C45.743,8.282,37.46,0,27.28,0C17.1,0,8.816,8.282,8.816,18.463c0,5.947,2.847,11.471,7.647,14.946
			l-5.877,15.06c-0.124,0.317-0.078,0.676,0.122,0.95c0.2,0.276,0.534,0.437,0.865,0.412l6.676-0.366l4.663,4.791
			c0.19,0.196,0.45,0.303,0.717,0.303c0.066,0,0.132-0.006,0.199-0.02c0.333-0.066,0.609-0.3,0.733-0.615l2.719-6.968L30,53.924
			c0.123,0.315,0.399,0.549,0.732,0.615c0.066,0.014,0.133,0.02,0.199,0.02c0.267,0,0.525-0.106,0.717-0.303l4.663-4.791
			l6.676,0.366c0.022,0.001,0.045,0.003,0.065,0.001c0.549,0.008,1.01-0.443,1.01-1c0-0.197-0.057-0.381-0.156-0.537l-5.811-14.886
			C42.896,29.934,45.743,24.41,45.743,18.463z M23.262,51.747l-3.897-4.004c-0.189-0.194-0.448-0.304-0.717-0.304
			c-0.018,0-0.037,0-0.055,0.002l-5.579,0.306l5.163-13.228c0.019,0.011,0.039,0.02,0.058,0.029
			c0.225,0.127,0.457,0.239,0.686,0.355c0.184,0.095,0.365,0.195,0.552,0.283c0.082,0.039,0.167,0.07,0.249,0.106
			c1.544,0.698,3.171,1.181,4.85,1.429c0.008,0.002,0.016,0.004,0.024,0.004c0.365,0.053,0.734,0.09,1.104,0.121
			c0.096,0.008,0.191,0.021,0.288,0.027c0.294,0.02,0.59,0.025,0.886,0.032c0.136,0.003,0.271,0.015,0.406,0.015
			c0.041,0,0.082-0.006,0.123-0.006c0.513-0.005,1.027-0.027,1.545-0.077c0.039-0.003,0.077-0.003,0.115-0.007
			c0.006,0,0.013,0,0.021-0.001l-2.735,7.004c0,0,0,0.001,0,0.002L23.262,51.747z M35.966,47.441
			c-0.285-0.012-0.57,0.095-0.771,0.302l-3.896,4.004l-2.944-7.543l3.021-7.741c0.34-0.076,0.674-0.171,1.006-0.268
			c0.08-0.021,0.159-0.038,0.237-0.062c0.513-0.154,1.017-0.334,1.513-0.533c0.139-0.056,0.272-0.119,0.409-0.176
			c0.366-0.158,0.728-0.326,1.083-0.507c0.152-0.078,0.305-0.155,0.454-0.237c0.101-0.055,0.206-0.103,0.306-0.16l5.164,13.229
			L35.966,47.441z M36.328,32.208c-1.798,1.187-3.775,1.996-5.881,2.406c-1.632,0.317-3.257,0.389-4.839,0.229
			c-2.636-0.264-5.15-1.166-7.378-2.637c-4.643-3.062-7.415-8.201-7.415-13.746c0-9.078,7.385-16.463,16.463-16.463
			s16.463,7.385,16.463,16.463C43.743,24.007,40.97,29.146,36.328,32.208z"/>
	</g>
</g>
</svg>
`

const phone = `
<svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Icons-Communication" transform="translate(-1.000000, 0.000000)"><g id="call" transform="translate(1.000000, 0.000000)"><path d="M3.6,7.8 C5,10.6 7.4,12.9 10.2,14.4 L12.4,12.2 C12.7,11.9 13.1,11.8 13.4,12 C14.5,12.4 15.7,12.6 17,12.6 C17.6,12.6 18,13 18,13.6 L18,17 C18,17.6 17.6,18 17,18 C7.6,18 0,10.4 0,1 C0,0.4 0.4,0 1,0 L4.5,0 C5.1,0 5.5,0.4 5.5,1 C5.5,2.2 5.7,3.4 6.1,4.6 C6.2,4.9 6.1,5.3 5.9,5.6 L3.6,7.8 L3.6,7.8 Z" id="Shape"/></g></g></g></svg>
`

const percent = `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 58 58" xml:space="preserve">
<rect style="fill:#E7ECED;" width="18" height="18"/>
<line style="fill:none;stroke:#424A60;stroke-width:2;stroke-miterlimit:10;" x1="11" y1="47" x2="47" y2="11"/>
<circle style="fill:none;stroke:#424A60;stroke-width:2;stroke-miterlimit:10;" cx="16" cy="16" r="5"/>
<circle style="fill:none;stroke:#424A60;stroke-width:2;stroke-miterlimit:10;" cx="42" cy="42" r="5"/>
</svg>
`

const permission = `
<svg class="svg-icon" style="width: 18px; height: 18px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M510.976182 208.775954c89.87596 0 162.748664 68.00279 162.748664 151.891386l81.374332 0c0-125.823684-109.296265-227.835032-244.122997-227.835032-134.830825 0-244.12402 102.011348-244.12402 227.835032l81.373309 0C348.224448 276.778744 421.095106 208.775954 510.976182 208.775954L510.976182 208.775954zM782.227026 411.314857 239.727385 411.314857c-29.964452 0-54.253648 22.672372-54.253648 50.621934l0 379.733581c0 27.973098 24.288173 50.646494 54.253648 50.646494l542.499641 0 0.004093 0c29.953196 0.004093 54.247508-22.673395 54.247508-50.646494L836.478627 461.935768C836.478627 433.987229 812.184315 411.314857 782.227026 411.314857L782.227026 411.314857zM791.107277 842.848197c0 5.759167-4.713348 10.471492-10.474562 10.471492L243.891218 853.319689c-5.762237 0-10.471492-4.712325-10.471492-10.471492L233.419725 468.452181c0-5.759167 4.709255-10.472516 10.471492-10.472516L780.632715 457.979666c5.761214 0 10.474562 4.713348 10.474562 10.472516L791.107277 842.848197 791.107277 842.848197zM510.978229 537.89067l-0.002047-0.004093c-44.954865 0-81.377402 33.991162-81.377402 75.947739 0 28.028357 16.48137 52.267411 40.691771 65.404616l0 72.262814c0 21.823028 18.222013 39.545668 40.688701 39.545668 22.458501 0 40.686654-17.721617 40.686654-39.545668l0-72.262814c24.209378-13.137205 40.687678-37.376259 40.687678-65.404616C592.349491 571.881832 555.929 537.89067 510.978229 537.89067L510.978229 537.89067zM510.978229 537.89067"  /></svg>
`

const question = `
<svg class="svg-icon" style="width: 18px; height: 18px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 85.333333a426.666667 426.666667 0 1 0 426.666667 426.666667A426.666667 426.666667 0 0 0 512 85.333333z m0 768a341.333333 341.333333 0 1 1 341.333333-341.333333 341.333333 341.333333 0 0 1-341.333333 341.333333z"  /><path d="M512 256a149.333333 149.333333 0 0 0-149.333333 149.333333 42.666667 42.666667 0 0 0 85.333333 0A64 64 0 1 1 512 469.333333a42.666667 42.666667 0 0 0-42.666667 42.666667v85.333333a42.666667 42.666667 0 0 0 85.333334 0v-49.493333A148.906667 148.906667 0 0 0 512 256z"  /><path d="M512 725.333333m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z"  /></svg>
`

const language = `
<svg width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="none" stroke="#000000" stroke-width="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"/>
</svg>
`

export {
  at,
  password,
  reference,
  location1,
  map,
  map1,
  profil1,
  profil2,
  order,
  address,
  notification,
  notification1,
  favorite,
  wallet,
  giftCard,
  exit,
  comment,
  comment1,
  login,
  register,
  basket,
  edit,
  warning,
  gift,
  gift1,
  plus,
  search,
  close,
  favorite1,
  basket2,
  basket3,
  trash,
  star,
  mapMarker,
  megaphone,
  rightArrow,
  refresh,
  spinner,
  deleteAccount,
  undo,
  motorcycle,
  tableChair,
  humanBoard,
  restaurant,
  complete,
  reward,
  reward1,
  phone,
  percent,
  permission,
  question,
  language
}
