import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */
/* eslint-disable camelcase */
import nuxt_plugin_pluginseo_726157e4 from 'nuxt_plugin_pluginseo_726157e4' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_229d0105 from 'nuxt_plugin_pluginrouting_229d0105' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_62abdeec from 'nuxt_plugin_pluginmain_62abdeec' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_bootstrapvue_7e523160 from 'nuxt_plugin_bootstrapvue_7e523160' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_gtm_55809537 from 'nuxt_plugin_gtm_55809537' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_templatespluginea232a70_62f6d3ec from 'nuxt_plugin_templatespluginea232a70_62f6d3ec' // Source: ./templates.plugin.ea232a70.js (mode: 'client')
import nuxt_plugin_libplugin29e6e967_12373050 from 'nuxt_plugin_libplugin29e6e967_12373050' // Source: ./lib.plugin.29e6e967.js (mode: 'all')
import nuxt_plugin_axios_58c5ba21 from 'nuxt_plugin_axios_58c5ba21' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_vuecurrencyfilter_5a37ee6c from 'nuxt_plugin_vuecurrencyfilter_5a37ee6c' // Source: ./vue-currency-filter.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_74f0f7c9 from 'nuxt_plugin_cookieuniversalnuxt_74f0f7c9' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_templatesplugin8f6063fc_c52f59e2 from 'nuxt_plugin_templatesplugin8f6063fc_c52f59e2' // Source: ./templates.plugin.8f6063fc.js (mode: 'all')
import nuxt_plugin_googleanalytics_4defaf65 from 'nuxt_plugin_googleanalytics_4defaf65' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_sessionConfig_720c447c from 'nuxt_plugin_sessionConfig_720c447c' // Source: ../plugins/sessionConfig (mode: 'all')
import nuxt_plugin_vuesplide_6c4c7390 from 'nuxt_plugin_vuesplide_6c4c7390' // Source: ../plugins/vue-splide.js (mode: 'client')
import nuxt_plugin_vuestarrating_0929582a from 'nuxt_plugin_vuestarrating_0929582a' // Source: ../plugins/vue-star-rating.js (mode: 'client')
import nuxt_plugin_append_1a7aafb8 from 'nuxt_plugin_append_1a7aafb8' // Source: ../plugins/append.js (mode: 'client')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_googleMap_70973ca6 from 'nuxt_plugin_googleMap_70973ca6' // Source: ../plugins/googleMap (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_components_6fb0430c from 'nuxt_plugin_components_6fb0430c' // Source: ../plugins/components (mode: 'all')
import nuxt_plugin_moment_4f74a614 from 'nuxt_plugin_moment_4f74a614' // Source: ../plugins/moment (mode: 'all')
import nuxt_plugin_currencyMain_c358d840 from 'nuxt_plugin_currencyMain_c358d840' // Source: ../plugins/currencyMain (mode: 'all')
import nuxt_plugin_vmask_3801a64e from 'nuxt_plugin_vmask_3801a64e' // Source: ../plugins/v-mask (mode: 'all')
import nuxt_plugin_vuenotification_18c43a2b from 'nuxt_plugin_vuenotification_18c43a2b' // Source: ../plugins/vuenotification.js (mode: 'client')
import nuxt_plugin_i18n_2ced00f4 from 'nuxt_plugin_i18n_2ced00f4' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_gtm_9103e0c8 from 'nuxt_plugin_gtm_9103e0c8' // Source: ../plugins/gtm.js (mode: 'all')

/* eslint-enable camelcase */

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true
      // eslint-disable-next-line no-console
      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})// eslint-disable-line

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}// eslint-disable-line

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    /* eslint-disable array-bracket-spacing, quotes, quote-props, semi, indent, comma-spacing, key-spacing, object-curly-spacing, space-before-function-paren, object-shorthand  */
    head: {"title":"Rezzta Online Yemek Sipariş Sistemi","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"},{"hid":"keywords","name":"keywords","content":"rezzta, online yemek sipariş"},{"hid":"description","name":"description","content":"Online Yemek Siparişi Vermenin En Hızlı Ve En Kolay Yolu - Rezzta Online Yemek Sipariş"},{"name":"apple-itunes-app","content":"app-id=1357638100"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"google-play-app","content":"app-id=tr.com.akinsoft.rezzta"},{"name":"facebook-domain-verification","content":"1r8s9pq2giogya21yv97x1zxvihiau"}],"link":[],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function (w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},
    /* eslint-enable array-bracket-spacing, quotes, quote-props, semi, indent, comma-spacing, key-spacing, object-curly-spacing, space-before-function-paren, object-shorthand */

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution
  /* eslint-disable camelcase */

  if (typeof nuxt_plugin_pluginseo_726157e4 === 'function') {
    await nuxt_plugin_pluginseo_726157e4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_229d0105 === 'function') {
    await nuxt_plugin_pluginrouting_229d0105(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_62abdeec === 'function') {
    await nuxt_plugin_pluginmain_62abdeec(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrapvue_7e523160 === 'function') {
    await nuxt_plugin_bootstrapvue_7e523160(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_55809537 === 'function') {
    await nuxt_plugin_gtm_55809537(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatespluginea232a70_62f6d3ec === 'function') {
    await nuxt_plugin_templatespluginea232a70_62f6d3ec(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin29e6e967_12373050 === 'function') {
    await nuxt_plugin_libplugin29e6e967_12373050(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_58c5ba21 === 'function') {
    await nuxt_plugin_axios_58c5ba21(app.context, inject)
  }

  if (typeof nuxt_plugin_vuecurrencyfilter_5a37ee6c === 'function') {
    await nuxt_plugin_vuecurrencyfilter_5a37ee6c(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_74f0f7c9 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_74f0f7c9(app.context, inject)
  }

  if (typeof nuxt_plugin_templatesplugin8f6063fc_c52f59e2 === 'function') {
    await nuxt_plugin_templatesplugin8f6063fc_c52f59e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_4defaf65 === 'function') {
    await nuxt_plugin_googleanalytics_4defaf65(app.context, inject)
  }

  if (typeof nuxt_plugin_sessionConfig_720c447c === 'function') {
    await nuxt_plugin_sessionConfig_720c447c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesplide_6c4c7390 === 'function') {
    await nuxt_plugin_vuesplide_6c4c7390(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuestarrating_0929582a === 'function') {
    await nuxt_plugin_vuestarrating_0929582a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_append_1a7aafb8 === 'function') {
    await nuxt_plugin_append_1a7aafb8(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (typeof nuxt_plugin_googleMap_70973ca6 === 'function') {
    await nuxt_plugin_googleMap_70973ca6(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_components_6fb0430c === 'function') {
    await nuxt_plugin_components_6fb0430c(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_4f74a614 === 'function') {
    await nuxt_plugin_moment_4f74a614(app.context, inject)
  }

  if (typeof nuxt_plugin_currencyMain_c358d840 === 'function') {
    await nuxt_plugin_currencyMain_c358d840(app.context, inject)
  }

  if (typeof nuxt_plugin_vmask_3801a64e === 'function') {
    await nuxt_plugin_vmask_3801a64e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuenotification_18c43a2b === 'function') {
    await nuxt_plugin_vuenotification_18c43a2b(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_2ced00f4 === 'function') {
    await nuxt_plugin_i18n_2ced00f4(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_9103e0c8 === 'function') {
    await nuxt_plugin_gtm_9103e0c8(app.context, inject)
  }

  /* eslint-enable camelcase */
  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
