var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 mb-1" }, [
    _vm.showTotalPoint
      ? _c("div", { staticClass: "mb-3" }, [
          _c("h4", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm
                    .$t("Puan Bakiyeniz: {amount}")
                    .replace("{amount}", _vm.totalPoint)
                ) +
                "\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "w-100" }, [
      _vm.isActive && _vm.parsePoint
        ? _c(
            "div",
            {
              class:
                _vm.wrapperClass +
                " d-flex flex-column align-start justify-center w-100"
            },
            [
              !_vm.hiddenTopTitle && _vm.topTitle
                ? _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-bold mb-2 fs14-1 d-flex align-center"
                    },
                    [
                      _c("span", {
                        staticClass: "common-icon mr-2",
                        domProps: { innerHTML: _vm._s(_vm.icons.warning) }
                      }),
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t(_vm.topTitle)) + "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.parsePoint.earn.is_active
                ? [
                    _vm.parsePoint.earn.purchase_point.show
                      ? [
                          !_vm.hiddenTitle
                            ? _c(
                                "span",
                                { staticClass: "font-weight-bold fs11-1" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Sipariş Puanı")) +
                                      ":\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2 mb-1 d-flex flex-column w-100"
                            },
                            [
                              _vm.parsePoint.earn.purchase_point.price &&
                              _vm.parsePoint.earn.purchase_point.point
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium fs11-1 d-flex flex-column ml-2",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._l(
                                        Object.keys(
                                          _vm.parsePoint.earn.purchase_point
                                            .point
                                        ),
                                        function(pointName, index) {
                                          return [
                                            _vm.parsePoint.earn.purchase_point
                                              .point[pointName]
                                              ? _c("span", { key: index }, [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t(pointName)
                                                      ) +
                                                      ":\n                  " +
                                                      _vm._s(
                                                        _vm
                                                          .$t(
                                                            "Her {price} harcamanızda {point} puan kazanırsınız"
                                                          )
                                                          .replace(
                                                            "{price}",
                                                            _vm.$options.filters.currency(
                                                              _vm.parsePoint
                                                                .earn
                                                                .purchase_point
                                                                .price,
                                                              {
                                                                symbol:
                                                                  _vm.currency
                                                              }
                                                            )
                                                          )
                                                          .replace(
                                                            "{point}",
                                                            _vm.parsePoint.earn
                                                              .purchase_point
                                                              .point[pointName]
                                                          )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _c("span", { class: _vm.descriptionClass }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "Restoranda verdiğiniz siparişten puan kazanamazsınız"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                              _vm._v(" "),
                              _vm.parsePoint.earn.purchase_point.expire_day
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Satın alma puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür"
                                              )
                                              .replace(
                                                "{day}",
                                                _vm.parsePoint.earn
                                                  .purchase_point.expire_day
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.parsePoint.earn.purchase_point.minimum_total
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Siparişinizden puan kazanabilmeniz için, sipariş toplamının en az {minTotal} olmalıdır"
                                              )
                                              .replace(
                                                "{minTotal}",
                                                _vm.$options.filters.currency(
                                                  _vm.parsePoint.earn
                                                    .purchase_point
                                                    .minimum_total,
                                                  {
                                                    symbol: _vm.currency
                                                  }
                                                )
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parsePoint.earn.wallet_point.show
                      ? [
                          !_vm.hiddenTitle
                            ? _c(
                                "span",
                                { staticClass: "font-weight-bold fs11-1" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Cüzdan Puanı")) +
                                      ":\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2 mb-1 d-flex flex-column w-100"
                            },
                            [
                              _vm.parsePoint.earn.wallet_point.price &&
                              _vm.parsePoint.earn.wallet_point.point
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Tek seferde her {price} cüzdan yüklemenizde {point} puan kazanırsınız"
                                              )
                                              .replace(
                                                "{price}",
                                                _vm.$options.filters.currency(
                                                  _vm.parsePoint.earn
                                                    .wallet_point.price,
                                                  {
                                                    symbol: _vm.currency
                                                  }
                                                )
                                              )
                                              .replace(
                                                "{point}",
                                                _vm.parsePoint.earn.wallet_point
                                                  .point
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _c("span", { class: _vm.descriptionClass }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "Restoranda cüzdan yüklemesinden puan kazanamazsınız"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                              _vm._v(" "),
                              _vm.parsePoint.earn.wallet_point.expire_day
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Cüzdandan kazandığınız puanının geçerlilik süresi kazandığınız tarihten itibaren {day} gündür"
                                              )
                                              .replace(
                                                "{day}",
                                                _vm.parsePoint.earn.wallet_point
                                                  .expire_day
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.parsePoint.earn.wallet_point.minimum_total
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Cüzdan yüklemenizde puan kazanabilmeniz için en az {minTotal} yüklemelisiniz"
                                              )
                                              .replace(
                                                "{minTotal}",
                                                _vm.$options.filters.currency(
                                                  _vm.parsePoint.earn
                                                    .wallet_point.minimum_total,
                                                  {
                                                    symbol: _vm.currency
                                                  }
                                                )
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parsePoint.earn.registration_point.show
                      ? [
                          !_vm.hiddenTitle
                            ? _c(
                                "span",
                                { staticClass: "font-weight-bold fs11-1" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("İlk Üyelik Puanı")) +
                                      ":\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2 mb-1 d-flex flex-column w-100"
                            },
                            [
                              _vm.parsePoint.earn.registration_point.point
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "İlk üyeliğinizde {point} puan kazanırsınız"
                                              )
                                              .replace(
                                                "{point}",
                                                _vm.parsePoint.earn
                                                  .registration_point.point
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _c("span", { class: _vm.descriptionClass }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "Restoranda ilk üyeliğinizden puan kazanamazsınız"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                              _vm._v(" "),
                              _vm.parsePoint.earn.registration_point.expire_day
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "İlk üyelik puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür"
                                              )
                                              .replace(
                                                "{day}",
                                                _vm.parsePoint.earn
                                                  .registration_point.expire_day
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parsePoint.earn.comment_point.show
                      ? [
                          !_vm.hiddenTitle
                            ? _c(
                                "span",
                                { staticClass: "font-weight-bold fs11-1" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Yorum Puanı")) +
                                      ":\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2 mb-1 d-flex flex-column w-100"
                            },
                            [
                              _vm.parsePoint.earn.comment_point.point
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Siparişinize yorum yaptığınızda {point} puan kazanırsınız"
                                              )
                                              .replace(
                                                "{point}",
                                                _vm.parsePoint.earn
                                                  .comment_point.point
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _c("span", { class: _vm.descriptionClass }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "Siparişinize yorum yaptığınızda puan kazanamazsınız"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                              _vm._v(" "),
                              _vm.parsePoint.earn.comment_point.expire_day
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "Sipariş yorum puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür"
                                              )
                                              .replace(
                                                "{day}",
                                                _vm.parsePoint.earn
                                                  .comment_point.expire_day
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parsePoint.earn.suggestion_point.show
                      ? [
                          !_vm.hiddenTitle
                            ? _c(
                                "span",
                                { staticClass: "font-weight-bold fs11-1" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("İstek Öneri")) +
                                      ":\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2 mb-1 d-flex flex-column w-100"
                            },
                            [
                              _vm.parsePoint.earn.suggestion_point.point
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "İstek önerinizde {point} puan kazanırsınız"
                                              )
                                              .replace(
                                                "{point}",
                                                _vm.parsePoint.earn
                                                  .suggestion_point.point
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _c("span", { class: _vm.descriptionClass }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "İstek önerinizde puan kazanamazsınız"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                              _vm._v(" "),
                              _vm.parsePoint.earn.suggestion_point.expire_day
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-medium fs11-1",
                                      class: _vm.descriptionClass
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "İstek öneri puanınızın geçerlilik süresi kazandığınız tarihten itibaren {day} gündür"
                                              )
                                              .replace(
                                                "{day}",
                                                _vm.parsePoint.earn
                                                  .suggestion_point.expire_day
                                              )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.parsePoint.spend.is_active && _vm.parsePoint.spend.show
                ? [
                    _c("span", { staticClass: "font-weight-bold fs11-1" }, [
                      _vm._v(" " + _vm._s(_vm.$t("Harcarken")) + ": ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-2 d-flex flex-column" }, [
                      _vm.parsePoint.spend.exchange_rate
                        ? _c(
                            "span",
                            {
                              staticClass: "font-weight-medium fs11-1",
                              class: _vm.descriptionClass
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("1 Puan {amount} tutarıdır").replace(
                                      "{amount}",
                                      _vm.$options.filters.currency(
                                        _vm.parsePoint.spend.exchange_rate,
                                        {
                                          symbol: _vm.currency
                                        }
                                      )
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _c("span", { class: _vm.descriptionClass }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("Restoranda puan harcama aktif değil")
                                ) +
                                "\n          "
                            )
                          ]),
                      _vm._v(" "),
                      _vm.parsePoint.spend.min_limit
                        ? _c(
                            "span",
                            {
                              staticClass: "font-weight-medium fs11-1",
                              class: _vm.descriptionClass
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm
                                      .$t(
                                        "Puanınızı kullanabilmek için, bakiyeniz en az {point} puan olmalıdır"
                                      )
                                      .replace(
                                        "{point}",
                                        _vm.parsePoint.spend.min_limit
                                      )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.parsePoint.spend.max_limit
                        ? _c(
                            "span",
                            {
                              staticClass: "font-weight-medium fs11-1",
                              class: _vm.descriptionClass
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm
                                      .$t(
                                        "Her siparişirinizde en fazla {point} puan kullanabilirsiniz"
                                      )
                                      .replace(
                                        "{point}",
                                        _vm.parsePoint.spend.max_limit
                                      )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.parsePoint.spend.max_rate
                        ? _c(
                            "span",
                            {
                              staticClass: "font-weight-medium fs11-1",
                              class: _vm.descriptionClass
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm
                                      .$t(
                                        "Siparişinizin en fazla %{percent} oranını puan ile ödeyebilirsiniz"
                                      )
                                      .replace(
                                        "{percent}",
                                        _vm.parsePoint.spend.max_rate
                                      )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-medium fs11-1",
                          class: _vm.descriptionClass
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "Puan kullandığınız siparişten puan kazanamazsınız."
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : !_vm.isActive && !_vm.hiddenError
        ? _c(
            "div",
            { staticClass: "custom-alert custom-alert-danger mb-2 w-100" },
            [
              _c("span", {
                staticClass: "common-icon",
                domProps: { innerHTML: _vm._s(_vm.icons.warning) }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("Bu restoranda puan sistemi aktif değil!")) +
                    "\n        "
                )
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }