import { refresh } from '@/api/auth'

export default function({ $axios, store, redirect, app }) {
  const offsetMinute = process.client ? new Date().getTimezoneOffset() * -1 : ''

  $axios.defaults.timeout = process.env.axiosTimeout || 20000

  $axios.onRequest(config => {
    if (store.state.auth.token) {
      config.headers.Authorization = 'Bearer ' + store.state.auth.token
      config.headers.offset = offsetMinute
    }
  })

  $axios.onError(async err => {
    const originalRequest = err.config
    const statusCode = err.response ? err.response.status : 402
    let errorMessage = null

    switch (statusCode) {
      case 401:
        if (!originalRequest._retry && store.state.auth.refreshToken) {
          originalRequest._retry = true

          try {
            const { data } = await $axios({
              ...refresh(),
              data: {
                refresh_token: store.state.auth.refreshToken
              }
            })

            const { token } = data

            store.dispatch('auth/setToken', token)
            originalRequest.headers.Authorization = 'Bearer ' + token

            return $axios(originalRequest)
          } catch (error) {
            return Promise.reject(error)
          }
        } else {
          await store.dispatch('auth/setAnonymToken')
          window.location.reload(true)
        }

        break
      case 402:
        // TODO: timeout için bir mesaj ayarlanacak
        errorMessage =
          'Şu anda sunucu yanıt vermiyor. Birazdan tekrar deneyiniz.'
        break
      default:
        errorMessage = err.response.data
        break
    }

    throw new Error(errorMessage)
  })
}
