<template>
  <zz-div :loading="loading">
    <div class="row">
      <div class="col-md-12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="form-material"
            @submit.prevent="handleSubmit(handleLoginSubmit)"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('E-Posta Adresiniz')"
            >
              <div class="form-label-group">
                <input
                  id="inputEmail"
                  v-model="login.email"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('E-Posta Adresiniz')"
                  :disabled="loading"
                  type="email"
                  class="form-control pr-6"
                  required
                />
                <span class="common-icon icon-style-1" v-html="icons.at"></span>
                <label for="inputEmail">{{ $t('E-Posta Adresiniz') }} </label>
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" :name="$t('Şifreniz')">
              <div class="form-label-group">
                <input
                  id="inputPassword"
                  v-model="login.password"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('Şifreniz')"
                  :disabled="loading"
                  type="password"
                  class="form-control pr-6"
                  required
                />
                <span
                  class="common-icon icon-style-1"
                  v-html="icons.password"
                ></span>
                <label for="inputPassword">{{ $t('Şifreniz') }}</label>
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </div>
            </validation-provider>
            <div class="custom-control custom-checkbox mb-3">
              <input
                id="rememberMeCheckbox"
                v-model="login.rememberMe"
                type="checkbox"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="rememberMeCheckbox">{{
                $t('Beni Hatırla')
              }}</label>
            </div>
            <button
              :disabled="loading"
              class="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 rezzta-red white--text"
              type="submit"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Giriş Yap') }}
            </button>
          </form>
        </ValidationObserver>
        <div v-if="!$store.state.isDemo" class="text-center pt-3">
          <a
            href="javascript:;"
            class="font-weight-bold"
            @click="handleClickForgotPassword"
          >
            {{ $t('Şifremi Unuttum') }}
          </a>
          |
          <a
            href="javascript:;"
            class="font-weight-bold"
            @click="$store.dispatch('auth/showRegisterModal')"
          >
            {{ $t('Kayıt Ol') }}
          </a>
        </div>
      </div>
    </div>
  </zz-div>
</template>

<script>
import { at, password } from '@/assets/icon/index.js'

export default {
  name: 'AuthLoginWithEmail',
  data() {
    return {
      login: {
        email: '',
        password: '',
        rememberMe: true
      },
      facebook: {
        appId: process.env.facebookAppId,
        isConnected: false,
        FB: undefined,
        user: {}
      },
      loading: false,
      icons: {
        at,
        password
      }
    }
  },
  mounted() {
    if (this.$store.state.isBaseDomain) {
      this.login.email = 'test@rezzta.com'
      this.login.password = '123456'
      this.login.rememberMe = true
    }
  },
  methods: {
    handleClickForgotPassword() {
      this.$store.dispatch('auth/showForgotPasswordModal')
    },
    async handleLoginSubmit() {
      this.loading = true

      try {
        await this.$store.dispatch('auth/login', {
          ...this.login,
          partner_institution_ref: this.$cookies.get('ref')?.toString() || null
        })
        this.$emit('success')
      } catch (error) {
        this.$emit('fail', error)
        this.$notification.error(
          error?.message || this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
