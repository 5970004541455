<template>
  <div>
    <div v-if="$store.state.isDemo" class="container">
      <div class="row">
        <div class="col-md-8">
          <a
            href="https://www.akinsoft.com.tr/as/irtibat/istekoneri?d=rezzta"
            target="__blank"
            class="text-dark teklif-al"
          >
            {{
              $t(
                'Rezzta Hakkında Detaylı Bilgi ve Fiyat Teklifi için tıklayınız.'
              )
            }}
          </a>
        </div>
      </div>
    </div>
    <section class="footer pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-12 col-sm-12 mb-3 text-center text-md-left">
            <div class="app">
              <p class="mb-2">
                {{ $t('Rezzta mobil ile restoranlar cebinizde') }}
              </p>
              <a
                :href="
                  $store.state.isDemo
                    ? '#'
                    : 'https://itunes.apple.com/us/app/rezzta/id1357638100?ls=1&mt=8'
                "
                :target="$store.state.isDemo ? '_self' : '_blank'"
              >
                <img class="img-fluid" src="/images/apple.png" />
              </a>
              <a
                :href="
                  $store.state.isDemo
                    ? '#'
                    : 'https://play.google.com/store/apps/details?id=tr.com.akinsoft.rezzta'
                "
                :target="$store.state.isDemo ? '_self' : '_blank'"
              >
                <img class="img-fluid" src="/images/google.png" />
              </a>
            </div>
            <div class="footer-language">
              <div class="footer-language-icon">
                <span
                  class="common-icon"
                  v-html="icons.language"
                ></span>
              </div>
              <div class="footer-language-items">
                <nuxt-link
                  v-if="$i18n.locale !== 'en'"
                  :to="switchLocalePath('en')"
                >
                  EN
                </nuxt-link>
                <nuxt-link
                  v-if="$i18n.locale !== 'tr'"
                  :to="switchLocalePath('tr')"
                >
                  TR
                </nuxt-link>
                <nuxt-link
                  v-if="$i18n.locale !== 'de'"
                  :to="switchLocalePath('de')"
                >
                  DE
                </nuxt-link>
              </div>
            </div>
            <div class="footer-social">
              <a
                :href="
                  $store.state.isDemo ? '#' : 'https://www.facebook.com/rezzta'
                "
                :target="$store.state.isDemo ? '_self' : '_blank'"
              >
                <fa :icon="['fab', 'facebook']"></fa>
              </a>
              <a
                :href="
                  $store.state.isDemo ? '#' : 'https://www.instagram.com/rezzta'
                "
                :target="$store.state.isDemo ? '_self' : '_blank'"
              >
                <fa :icon="['fab', 'instagram']"></fa>
              </a>
              <a
                :href="
                  $store.state.isDemo
                    ? '#'
                    : 'https://www.youtube.com/channel/UC-l89F8USJWIK3z5ak6PFFQ'
                "
                :target="$store.state.isDemo ? '_self' : '_blank'"
              >
                <fa :icon="['fab', 'youtube']"></fa>
              </a>
            </div>
          </div>
          <div
            v-if="$store.state.isDemo"
            class="col-md-4 offset-md-1 col-12 col-sm-6 mb-3"
          >
            <ul>
              <li>
                <a :href="branchLink" target="_blank">
                  {{ $t('Demo Restoran Portalı') }}
                </a>
              </li>
              <li>
                <nuxt-link
                  :to="
                    localePath({
                      name: 'blog',
                      params: {
                        index: getBlogAreaSlug('demo-rezzta-description')
                      }
                    })
                  "
                >
                  {{ $t('Rezzta Hakkında') }}
                </nuxt-link>
              </li>
              <li>
                <a
                  :href="
                    $i18n.locale === 'en'
                      ? 'https://www.akinsoft.com/contact'
                      : 'https://www.akinsoft.com.tr/as/irtibat/irtibat.php'
                  "
                  target="_blank"
                >
                  {{ $t('İletişim') }}
                </a>
              </li>
            </ul>
          </div>
          <div
            v-if="!$store.state.isDemo"
            class="col-md-2 col-12 col-sm-6 mb-3"
          >
            <ul>
              <li>
                <nuxt-link
                  v-if="$i18n.locale !== 'en'"
                  :to="switchLocalePath('en')"
                >
                  English
                </nuxt-link>
                <nuxt-link
                  v-if="$i18n.locale !== 'tr'"
                  :to="switchLocalePath('tr')"
                >
                  Türkçe
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="
                    localePath({
                      name: 'blog',
                      params: {
                        index: getBlogAreaSlug('about')
                      }
                    })
                  "
                >
                  {{ $t('Rezzta Hakkında') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="
                    localePath({
                      name: 'blog',
                      params: {
                        index: getBlogAreaSlug('demo-rezzta-description')
                      }
                    })
                  "
                >
                  {{ $t('Kullanıcı Sözleşmesi') }}
                </nuxt-link>
              </li>
              <li>
                <a
                  target="_blank"
                  :href="
                    $i18n.locale === 'en'
                      ? 'https://www.akinsoft.com/pdpp.php'
                      : 'https://www.akinsoft.com.tr/kisisel-verilerin-korunmasi-kanunu.php'
                  "
                >
                  {{ $t('Kişisel Verilerin Korunması Kanunu') }}
                </a>
              </li>
              <li>
                <nuxt-link
                  :to="
                    localePath({
                      name: 'blog',
                      params: {
                        index: getBlogAreaSlug('cookie')
                      }
                    })
                  "
                >
                  {{ $t('Çerez Politikası') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="
                    localePath({
                      name: 'blog',
                      params: {
                        index: getBlogAreaSlug('privacy')
                      }
                    })
                  "
                >
                  {{ $t('Gizlilik Politikası') }}
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div
            v-if="!$store.state.isDemo"
            class="col-md-2 col-12 col-sm-6 mb-3"
          >
            <ul>
              <li>
                <a
                  :href="
                    $i18n.locale === 'en'
                      ? 'https://panel.rezzta.com/home?lang=en'
                      : 'https://panel.rezzta.com/home?lang=tr'
                  "
                  target="_blank"
                >
                  {{ $t('Restoran Portalı') }}
                </a>
              </li>
              <li>
                <nuxt-link :to="localePath('staticBlog-registerInfo')">
                  {{ $t('Üye İş Yeri Kayıt') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('staticBlog-suggestRestaurant')">
                  {{ $t('Restoran Öner') }}
                </nuxt-link>
              </li>
              <!-- <li>
                <nuxt-link :to="localePath('staticBlog-myrezztaRegisterDemo')">
                  {{ $t('MyRezzta Demo Kayıt') }}
                </nuxt-link>
              </li> -->
              <li>
                <a
                  :href="
                    $i18n.locale === 'en'
                      ? 'https://www.akinsoft.com/contact'
                      : 'https://www.akinsoft.com.tr/as/irtibat/irtibat.php'
                  "
                  target="_blank"
                >
                  {{ $t('İletişim') }}
                </a>
              </li>
            </ul>
          </div>
          <div
            class="col-md-4 col-12 col-sm-12 mb-3 footer-contact"
            :class="{ 'mx-auto': $store.state.isBaseDomain }"
          >
            <ul>
              <li v-if="!$store.state.isDemo">
                <span>
                  <fa icon="envelope"></fa>
                </span>
                <a href="mailto:bilgi@rezzta.com" class="text-dark">
                  bilgi@rezzta.com
                </a>
              </li>
              <li v-if="!$store.state.isDemo">
                <span>
                  <fa icon="phone"></fa>
                </span>
                <a href="tel:08504414000" class="text-dark">
                  0 850 441 4000
                </a>
              </li>
              <li v-if="!$store.state.isDemo">
                <span>
                  <fa icon="map-marker-alt"></fa>
                </span>
                İstanbul Yolu, AKINSOFT Plaza No:103 42070
                Selçuklu/Konya/TÜRKİYE
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="pt-4 pb-4 text-center">
      <div class="container">
        <p class="mt-0 mb-0">© Copyright {{ new Date().getFullYear() }}.</p>
        <i18n path="akinsoft_footer" tag="small" class="mt-0 mb-0">
          <a
            class="text-danger"
            target="_blank"
            href="https://www.akinsoft.com/"
          >
            AKINSOFT
          </a>
        </i18n>
      </div>
      <client-only>
        <cookie-law :button-text="$t('Tamam')" theme="custom-cookie">
          <div slot="message">
            <i18n
              path="cookie_policy_read_and_aggre_tos"
              tag="span"
              class="text-underline"
            >
              <a href="#" @click.prevent="lawModal('cookie')">
                {{ $t('Çerez Politikamızı') }}
              </a>
              <a href="#" @click.prevent="lawModal('privacy')">
                {{ $t('Gizlilik Politikamızı') }}
              </a>
            </i18n>
          </div>
        </cookie-law>
      </client-only>

      <b-modal
        v-model="law.modal"
        size="lg"
        centered
        content-class="custom-modal-1"
        lazy
        ok-only
        :ok-title="$t('Kapat')"
      >
        <blogArea :slug="law.blogSlug" style="margin-top: -20px"></blogArea>
      </b-modal>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import blogArea from '@/pages/blog/_index'
import { language } from '@/assets/icon/index.js'

let cookieLaw

if (process.client) {
  cookieLaw = require('vue-cookie-law').default
}

export default {
  name: 'DefaultFooter',
  components: {
    cookieLaw,
    blogArea
  },
  data() {
    return {
      law: {
        modal: false,
        blogSlug: null
      },
      icons: {
        language
      }
    }
  },
  methods: {
    lawModal(name) {
      this.law.modal = true
      this.law.blogSlug = this.getBlogAreaSlug(name)
    }
  },
  computed: {
    ...mapGetters({
      getBlogAreaSlug: 'getBlogAreaSlug'
    }),
    branchLink() {
      return this.$axios.defaults.baseURL === 'http://rezzta-api/api'
        ? 'http://rezzta-management/login?user=demo'
        : this.$axios.defaults.baseURL === 'https://api.rezzta.com'
        ? 'https://panel.rezzta.com/login?user=demo'
        : 'https://rezztatestpanel.akinsoft.com.tr/login?user=demo'
    }
  }
}
</script>

<style scoped lang="scss">
.teklif-al {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  background-color: #ffc300;
  width: 100%;
  font-weight: 600;
  font-size: calc(15px + 0.2vw);
  border-radius: 6px;
  text-align: center;
}
</style>
