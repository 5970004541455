export const getFrontCampaignAll = () => ({
  url: '/front-campaign',
  method: 'get'
})

export const getFrontCampaignAllCategory = () => ({
  url: '/front-campaign/category',
  method: 'get'
})

export const getFrontCampaignByCategoryId = ({ categoryId }) => ({
  url: `/front-campaign/category/${categoryId}`,
  method: 'get'
})

export const getFrontCampaign = ({ campaignId }) => ({
  url: `/front-campaign/campaign/${campaignId}`,
  method: 'get'
})

export const getFrontStoryAll = () => ({
  url: '/front-story',
  method: 'get'
})

export const deleteAccountInf = () => ({
  url: '/front/delete-account-information',
  method: 'get'
})

export const getCurrencyAllForFront = () => ({
  url: '/front/currency-list',
  method: 'get'
})

export const getOpenstreetmapReverse = () => ({
  url: '/front/openstreetmap-reverse',
  method: 'get'
})

export const getWellKnown = ({ platform }) => ({
  url: `/front/well-known/${platform}`,
  method: 'get'
})
