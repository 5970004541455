var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-500" },
    [
      _c("img", { attrs: { src: "/images/500.jpg" } }),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _c("h1", { staticClass: "mb-2" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("Bir şeyler ters gitti!")) + "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(
                  "Özür dileriz. İşlem sırasında bir hata oluştu. Hata kaydını aldık. En kısa sürede çözüm sağlanacaktır."
                )
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "mt-md-5" }, [
          _vm._v("\n      " + _vm._s(_vm.error.message) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _vm.$store.state.isBaseDomain
        ? _c(
            "nuxt-link",
            {
              staticClass: "btn btn-primary btn-lg",
              attrs: { to: _vm.localePath("index") }
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("Anasayfaya Dön")) + "\n  ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }