<template>
  <div>
    <b-nav-item-dropdown
      menu-class="border-radius-7 dropdown-cart-top p-0 dropdown-cart"
      right
    >
      <template v-slot:button-content>
        <span class="common-icon" v-html="icons.basket"></span>
        <span>
          {{ $t('Sepet') }}
        </span>
        <span class="badge badge-success custom-h-badge">
          {{ $store.state.basket.count }}
        </span>
      </template>
      <div
        v-if="basket.items && basket.items.length"
        class="dropdown-cart-top-header m-0 white"
      >
        <nuxt-link
          :to="
            localePath({
              name: 'restaurant-branchSlug',
              params: {
                branchSlug: $store.state.basket.restaurant.slug
              }
            })
          "
          class="font-weight-bold d-block text-center position-relative mb-1 pb-1 rounded-7 py-1 px-1 w-100"
        >
          <h6 class="mb-0 font-weight-medium grey--text text--darken-3 fs13">
            {{ $store.state.basket.restaurant.name }}123
          </h6>
          <p class="font-weight-regular grey--text text--darken-2 mb-0 fs11">
            {{ $store.state.basket.restaurant.neighborhood_name }}
          </p>
        </nuxt-link>
      </div>
      <zz-div
        :loading="loading"
        class="dropdown-cart-top-body pl-2 pr-4 pt-4 pb-2 border-radius-7"
      >
        <div
          v-for="(item, index) in basket.items"
          :key="`basket${index}`"
          class="mb-2 basket-item"
        >
          <p class="mb-2 grey lighten-5 py-1 px-2">
            <span class="float-right grey--text text--darken-3">
              {{
                item.price.totalWithoutDiscount | currency({ symbol: currency })
              }}
            </span>
            <a
              class="basket-item-delete"
              href="javascript:;"
              @click="handleDeleteBasketItem(item.uuid)"
            >
              <fa icon="times"></fa>
            </a>
            <span class="grey--text text--darken-3">
              {{ item.name }} x {{ item.piece }}
            </span>
            <span class="basket-item-desc">
              {{ item.content }}
            </span>
          </p>
        </div>
        <div v-show="!$store.state.basket.count" class="text-center mb-2">
          <b class="fs12-1"> {{ $t('Sepetiniz boş') }} </b><br />
          {{ $t('Sepetiniz gibi mideniz de boşsa neyi bekliyorsunuz?') }}
          <button
            v-if="$store.state.isBaseDomain"
            class=" mt-3"
            type="button"
            @click="goRestaurants"
          >
            {{ $t('Restoranları Listele') }}
          </button>
        </div>
      </zz-div>
      <div
        v-show="$store.state.basket.count"
        class="dropdown-cart-top-footer border-top p-4"
      >
        <p
          v-if="basket.price.discount"
          class="mb-1 fs12-1 dropdown-cart-top-footer-discount"
        >
          {{ $t('Toplam İndirim') }}
          <span class="float-right fs12-1">
            {{ -basket.price.discount | currency({ symbol: currency }) }}
          </span>
        </p>
        <p class="mb-0 font-weight-bold fs12-1 grey--text text--darken-2">
          {{ $t('Toplam Sepet Tutarı') }}
          <span class="float-right fs12-1 grey--text text--darken-3">
            {{ basket.price.net | currency({ symbol: currency }) }}
          </span>
        </p>
      </div>
      <div
        v-show="$store.state.basket.count"
        class="dropdown-cart-top-footer border-top p-2"
      >
        <button
          class="button-basket-order"
          type="button"
          @click="handleClickCheckout"
        >
          {{ $t('Sipariş Ver') }}
        </button>
      </div>
    </b-nav-item-dropdown>
    <availableTable ref="refAvailableTable" />
  </div>
</template>

<script>
import basketMixin from '@/mixins/basket'
import { basket } from '@/assets/icon/index.js'
import availableTable from '@/components/common/interceptor/availableTable'

export default {
  name: 'HeaderItemsCard',
  components: {
    availableTable
  },
  mixins: [basketMixin],
  data() {
    return {
      clickedCheckout: false,
      currency: '₺',
      icons: {
        basket
      }
    }
  },
  computed: {
    isLocation() {
      if (this.$store.state.auth.location?.length === 2) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    '$store.state.basket.items': {
      deep: true,
      handler(items) {
        this.parseBasket(items)
        this.parsePrice(this.$store.state.basket.price)
      }
    },
    '$route.path'() {
      document.querySelector('body').click()
    },
    '$store.state.auth.isLogged'(v) {
      if (v && this.clickedCheckout) {
        this.$router.push(this.localePath('checkout'))
        this.clickedCheckout = false
      }
    }
  },
  methods: {
    async handleClickCheckout() {
      if (!this.$store.state.auth.isLogged) {
        this.clickedCheckout = true

        return this.$store.dispatch('auth/showLoginModal')
      }

      this.loading = true

      const availableTableResult = this.$refs.refAvailableTable
        ? await this.$refs.refAvailableTable.check()
        : true

      this.loading = false

      if (availableTableResult) {
        this.$router.push(this.localePath('checkout'))
        document.querySelector('body').click()
      }
    },
    goRestaurants() {
      this.$router.push(
        this.localePath({
          name: 'restaurants'
        })
      )
    }
  }
}
</script>
