export const register = () => {
  return {
    url: `/member`,
    method: 'post'
  }
}

export const postMemberWithPhone = () => {
  return {
    url: `/member/register-with-phone`,
    method: 'post'
  }
}

export const getAddressAll = params => {
  return {
    url: `/member/${params.memberId}/address`,
    method: 'get'
  }
}

export const saveAddress = params => {
  return {
    url: `/member/${params.memberId}/address`,
    method: 'post'
  }
}

export const updateAddress = params => {
  return {
    url: `/member/${params.memberId}/address/${params.id}`,
    method: 'put'
  }
}

export const updateMemberPhone = params => {
  return {
    url: `/member/${params.memberId}/update-phone`,
    method: 'post'
  }
}

export const updateMemberPhoneForAuth = params => {
  return {
    url: `/member/${params.memberId}/update-phone-for-auth`,
    method: 'post'
  }
}

export const postVerifyMemberPhone = params => {
  return {
    url: `/member/${params.memberId}/verify-member-phone`,
    method: 'post'
  }
}

export const loginWithPhone = () => {
  return {
    url: `/member/login-phone`,
    method: 'post'
  }
}

export const postVerifyMemberPhoneForAuth = params => {
  return {
    url: `/member/${params.memberId}/verify-member-phone/for-auth`,
    method: 'post'
  }
}

export const resendSmsForPhoneRegister = () => {
  return {
    url: `/member/resend-sms-for-phone-register`,
    method: 'post'
  }
}

export const phoneVerifyCodeControl = () => {
  return {
    url: `/member/phone-verify-code-control`,
    method: 'post'
  }
}

export const patchMemberResendSms = params => {
  return {
    url: `/member/${params.memberId}/member-resend-sms`,
    method: 'patch'
  }
}

export const deleteAddress = params => {
  return {
    url: `/member/${params.memberId}/address/${params.id}`,
    method: 'delete'
  }
}

export const getAddress = params => {
  return {
    url: `/member/${params.memberId}/address/${params.id}`,
    method: 'get'
  }
}

export const putMember = params => {
  return {
    url: `/member/${params.memberId}`,
    method: 'put'
  }
}

export const deleteAccountControl = params => {
  return {
    url: `/member/delete-account-control/${params.memberId}`,
    method: 'get'
  }
}

export const deleteAccount = params => {
  return {
    url: `/member/delete-account/${params.memberId}`,
    method: 'post'
  }
}

export const postMemberFavoriteRestaurant = params => {
  return {
    url: `/member/${params.memberId}/favorite-restaurant/${params.restaurantId}`,
    method: 'post'
  }
}

export const deleteMemberFavoriteRestaurant = params => {
  return {
    url: `/member/${params.memberId}/favorite-restaurant/${params.restaurantId}`,
    method: 'delete'
  }
}

export const postMemberOrder = params => {
  return {
    url: `/member/${params.memberId}/order`,
    method: 'post'
  }
}

export const patchMemberChangePassword = params => {
  return {
    url: `/member/${params.memberId}/change-password`,
    method: 'patch'
  }
}

export const getMemberOrderAll = params => {
  return {
    url: `/member/${params.memberId}/order`,
    method: 'get'
  }
}

export const getMemberMyOrders = params => {
  return {
    url: `/member/${params.memberId}/myorders`,
    method: 'get'
  }
}

export const getMemberOrder = params => {
  return {
    url: `/member/${params.memberId}/order/${params.orderId}`,
    method: 'get'
  }
}

export const patchMemberReorder = params => {
  return {
    url: `/member/${params.memberId}/reorder/${params.orderId}`,
    method: 'patch'
  }
}

export const postMemberForgetPassword = () => {
  return {
    url: '/member/forget-password',
    method: 'post'
  }
}

export const getMemberForgetPasswordResetCheck = params => {
  return {
    url: `/member/forget-password/${params.code}`,
    method: 'get'
  }
}

export const postMemberForgetPasswordReset = params => {
  return {
    url: `/member/forget-password/${params.code}`,
    method: 'post'
  }
}

export const patchEditIsActiveMemberAddress = ({ memberId, addressId }) => {
  return {
    url: `member/${memberId}/edit-is-active-member-address/${addressId}`,
    method: 'patch'
  }
}

export const getMemberCancelReasonAll = ({ deliveryType }) => {
  return {
    url: `/member-cancel-reason/${deliveryType}`,
    method: 'get'
  }
}

export const patchMemberOrderCancel = params => {
  return {
    url: `member/${params.memberId}/order/${params.orderId}/cancel`,
    method: 'patch'
  }
}

export const getMemberLastOrderPoints = memberId => {
  return {
    url: `member/${memberId}/last-order-points`,
    method: 'get'
  }
}

export const memberOrderChangeIsShow = params => {
  return {
    url: `member/${params.memberId}/order-change-is-show/${params.orderId}`,
    method: 'get'
  }
}

export const getMemberGiftCardSequentialOrder = ({ memberId }) => {
  return {
    url: `member/${memberId}/sequential-order`,
    method: 'get'
  }
}

export const postOrderPoint = params => {
  return {
    url: `order/${params.orderId}/point`,
    method: 'post'
  }
}

export const getMemberNotificationUnreadCount = params => {
  return {
    url: `/member/${params.memberId}/notification-unread-count`,
    method: 'get'
  }
}

export const getMemberNotificationAll = params => {
  return {
    url: `/member/${params.memberId}/notification`,
    method: 'get'
  }
}

export const getMemberPresignedPutObject = params => {
  return {
    url: `/member/${params.memberId}/presigned-url`,
    method: 'get'
  }
}

export const getMemberPartnerInstitutionRequestPresignedPutObject = params => {
  return {
    url: `/member/${params.memberId}/partner-institution-request-presigned-url`,
    method: 'get'
  }
}

export const deleteMemberNotification = params => {
  return {
    url: `/member/${params.memberId}/notification/${params.notificationId}`,
    method: 'delete'
  }
}

export const getMemberNotification = params => {
  return {
    url: `/member/${params.memberId}/notification/${params.notificationId}`,
    method: 'get'
  }
}

export const getMemberNotificationCommon = params => {
  return {
    url: `/member/${params.memberId}/notification/${params.notificationId}/common`,
    method: 'get'
  }
}

export const deleteMemberNotificationAll = params => {
  return {
    url: `/member/${params.memberId}/notification-all`,
    method: 'delete'
  }
}

export const patchMemberNotification = params => {
  // Okundu işaretleme
  return {
    url: `/member/${params.memberId}/notification/${params.notificationId}`,
    method: 'patch'
  }
}

export const patchMemberNotificationAll = params => {
  // Okundu işaretleme
  return {
    url: `/member/${params.memberId}/notification`,
    method: 'patch'
  }
}

export const postMemberWalletAdd = params => {
  return {
    url: `/member/${params.memberId}/wallet/add`,
    method: 'post'
  }
}

export const getMemberWalletHistory = params => {
  return {
    url: `/member/${params.memberId}/wallet/history`,
    method: 'get'
  }
}

export const applyMemberPoint = params => {
  return {
    url: `/member/${params.memberId}/apply-point`,
    method: 'get'
  }
}

export const getMemberPointHistory = params => {
  return {
    url: `/member/${params.memberId}/point/history`,
    method: 'get'
  }
}

export const getMemberTotalPoint = params => {
  return {
    url: `/member/${params.memberId}/point/total`,
    method: 'get'
  }
}

export const postMemberAddressBill = params => {
  return {
    url: `/member/${params.memberId}/address/bill`,
    method: 'post'
  }
}

export const putMemberAddressBill = params => {
  return {
    url: `/member/${params.memberId}/address/bill/${params.id}`,
    method: 'put'
  }
}

export const getMemberAddressBillAll = params => {
  return {
    url: `/member/${params.memberId}/address/bill`,
    method: 'get'
  }
}

export const getMemberGiftCardAll = params => {
  return {
    url: `/member/${params.memberId}/gift-card`,
    method: 'get'
  }
}

export const getMemberGiftCardAllForOrder = params => {
  return {
    url: `/member/${params.memberId}/gift-card-for-order`,
    method: 'get'
  }
}

export const postMemberGiftCardApply = params => {
  return {
    url: `/member/${params.memberId}/gift-card-apply`,
    method: 'post'
  }
}

export const getMemberReferenceLogs = params => {
  return {
    url: `/member/${params.memberId}/reference/logs`,
    method: 'get'
  }
}

export const getMemberReferenceMembers = params => {
  return {
    url: `/member/${params.memberId}/reference/members`,
    method: 'get'
  }
}

export const getMemberCommentAll = params => {
  return {
    url: `/member/${params.memberId}/comment`,
    method: 'get'
  }
}

export const postMemberCouponCode = params => {
  return {
    url: `/member/${params.memberId}/coupon-code`,
    method: 'post'
  }
}

export const postMemberPartnerInstitution = params => {
  return {
    url: `/member/${params.memberId}/partner-institution`,
    method: 'post'
  }
}

export const postMemberLeavePartnerInstution = params => {
  return {
    url: `/member/${params.memberId}/leave-partner-institution`,
    method: 'post'
  }
}

export const postMemberPartnerInstitutionRequest = params => {
  return {
    url: `/member/${params.memberId}/partner-institution-request`,
    method: 'post'
  }
}

export const getMemberPartnerInstitutionRequest = params => {
  return {
    url: `/member/${params.memberId}/partner-institution-request`,
    method: 'get'
  }
}

export const deleteMemberPartnerInstitutionRequest = params => {
  return {
    url: `/member/${params.memberId}/partner-institution-request`,
    method: 'delete'
  }
}
