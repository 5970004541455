<template>
  <div class="position-relative zz-div">
    <div
      v-show="loading"
      class="loading-wrap"
      :style="{ position: fixed ? 'fixed' : 'absolute' }"
    >
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-border text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ZzDiv',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>
