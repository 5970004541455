import { checkAvailability } from '@/api/index'
import { getSiteSettingAll } from '@/api/siteSetting'
import { getCurrencyAllForFront } from '@/api/front'
import { getCompanyByDomain, getCompanyPointSetting } from '@/api/company'
import {
  getCompanyBlogsByDomain,
  getCompanyBlogFront,
  getBlogForFront
} from '@/api/blog'

//* blogNameArea.domain objesinin varsayılan değerleri
// 'user-agreement': 'userAgreement',
// 'member-ship-form': 'memberShipForm',
// 'cookie': 'cookie',
// 'privacy': 'privacy',
// 'sales-agreement': 'salesAgreement',
// 'kvkk': 'kvkk',
// 'terms-of-use': 'termsOfUse',
// 'terms-and-conditions': 'termsAndConditions'
// 'land-scaping': 'landScaping
// 'about': 'about
// 'refund-policy': 'refundPolicy'
// 'delivery-policy': 'deliveryPolicy'

export const state = () => ({
  isBaseDomain: true, // Temel domain(rezzta.com) mi değil mi(Müşteri domaini ise false olacak)
  isDemo: true,
  company: {},
  companyPointSetting: {},
  popup: null,

  // Rezzta'da email ile giriş yapılacak. Myrezzta'da default'u 'both' olarak ayarlar
  loginType: 'email',

  // Kayıt sonrası Anlaşmalı Kurum üyeliği için
  makePartnerInstitutionAfterRegistration: false,
  companyBlogs: {},
  domainName: '',
  isMaintenance: false,
  siteSettings: {},
  orderCompleteModal: false,
  isPageFixed: false,
  currencyList: [],
  blogNameArea: {
    baseDomain: {
      'user-agreement': 'kullanici_sozlesmesi',
      'member-ship-form': 'uyelik_formu_metni',
      about: 'rezzta_hakkinda',
      cookie: 'cerez_politikasi',
      privacy: 'gizlilik_sozlesmesi',
      'service-city': 'hizmet_verilen_sehirler',
      'member-wallet-description': 'uye_cuzdan_sayfasi_aciklama',
      'member-reference-description': 'uye_referans_sayfasi_aciklama',
      'demo-rezzta-description': 'demo_rezzta_aciklama'
    },
    domain: {}
  }
})
export const mutations = {
  setIsPageFixed(state, payload) {
    state.isPageFixed = payload
  },
  setDomainName(state, domainName) {
    domainName = domainName.replace(/^(www.)/, '')
    state.domainName = domainName
  },
  isBaseDomain(state, host) {
    state.isBaseDomain = host === process.env.baseDomain
    state.isDemo = host === process.env.baseDomain
  },
  setCompany(state, data) {
    const { login_type: loginType, make_partner_institution_after_registration: makePartnerInstitutionAfterRegistration } = data?.settings?.site?.member || {}

    state.company = data
    state.loginType = loginType || 'both'
    state.makePartnerInstitutionAfterRegistration = makePartnerInstitutionAfterRegistration || false

    if (data.blogs && Array.isArray(data.blogs) && data.blogs.length) {
      data.blogs.forEach(b => {
        state.companyBlogs[b.name] = { ...b }
        state.blogNameArea.domain[b.name] = b.name
      })
    }
  },
  setCompanyPointSetting(state, data) {
    state.companyPointSetting = data
  },
  setCompanyBlog(state, data) {
    if (data && Array.isArray(data) && data.length) {
      data.forEach(b => {
        state.companyBlogs[b.name] = { ...b }
      })
    }
  },
  setMaintenanceStatus(state, data) {
    state.isMaintenance = data
  },
  setSiteSettings(state, data) {
    state.siteSettings = data
  },
  setCurrencyList(state, data) {
    state.currencyList = data
  },
  setOrderCompleteModal(state, data) {
    state.orderCompleteModal = data
  },
  setPopup(state, data) {
    state.popup = data
  }
}

export const actions = {
  async nuxtServerInit({ commit, dispatch, state }, { req, error, query }) {
    try {
      commit('isBaseDomain', req.headers.host)
      commit('setDomainName', req.headers.host)

      if (!state.isBaseDomain) {
        this.$axios.setHeader('origin', state.domainName)
      }

      if (await dispatch('checkMaintenance')) return
      await dispatch('getCurrencyList')
      await dispatch('getSiteSettings')
      await dispatch('auth/takeToken')

      // Eğer rezzta.com değilse
      if (!state.isBaseDomain) {
        await dispatch('getCompanyData', error)
        await dispatch('getCompanyPointSettingData')
        await dispatch('getCompanyBlogData')

        // Demo ise konum otomatik gönderiliyor. Heryerden erişilebilsin diye
        if (state.domainName === process.env.demoDomain) {
          const coordinate = process.env.demoCoordinate

          dispatch('auth/setLocation', [coordinate.lat, coordinate.lon])
        }
        // else if (
        //   !(state.auth.location?.length === 2) &&
        //   state.company?.branch?.length === 1
        // ) {
        //   // Konum seçilmemişse ve tek restoranı varsa o restorananın konumu setleniyor
        //   const coordinate = state.company.branch[0].coordinates
        //   dispatch('auth/setLocation', [coordinate.x, coordinate.y])
        // }
        else if (!(state.auth.location?.length === 2)) {
          const coordinate = state.company?.branch?.[0]?.coordinates

          if (coordinate) {
            dispatch('auth/setLocation', [coordinate.x, coordinate.y])
          }
        }
      } else {
        const coordinate = process.env.demoCoordinate

        dispatch('auth/setLocation', [coordinate.lat, coordinate.lon])
      }

      await dispatch('auth/memberLastOrderPoints')
      // Üye olsun olmasın token alan herkesin sepeti olabilir.
      await dispatch('basket/getBasket', { qr: query?.qr || undefined })
    } catch (err) {
      error({
        statusCode: err.statusCode || 500,
        message: err.message || err
      })
    }
  },
  // Site bakım modunda mı
  async checkMaintenance({ commit }) {
    const result = await this.$axios.request({
      ...checkAvailability()
    })

    commit('setMaintenanceStatus', result.data.maintenanceStatus)

    return result.data.maintenanceStatus
  },
  // Site Ayarları
  async getSiteSettings({ commit }) {
    const result = await this.$axios.request({
      ...getSiteSettingAll()
    })

    commit('setSiteSettings', result.data)

    return result.data
  },
  async getCurrencyList({ commit }) {
    const result = await this.$axios.request({
      ...getCurrencyAllForFront()
    })

    commit('setCurrencyList', result.data)

    return result.data
  },
  // Firma verileri
  async getCompanyData({ commit, state }, error) {
    try {
      const result = await this.$axios.request({
        ...getCompanyByDomain({
          domain: state.domainName
        })
      })

      commit('setCompany', result.data)
    } catch (err) {
      error({
        statusCode: 400,
        message: err.message || err
      })
    }
  },
  // Firma puan ayarı verileri
  async getCompanyPointSettingData({ commit, state }) {
    try {
      const result = await this.$axios.request({
        ...getCompanyPointSetting()
      })

      commit('setCompanyPointSetting', result.data)
      
      return result.data
    } catch (err) {
      commit('setCompanyPointSetting', {})

      return null
    }
  },
  async getCompanyBlogData({ commit, state }) {
    const result = await this.$axios.request({
      ...getCompanyBlogsByDomain()
    })

    commit('setCompanyBlog', result.data)

    return result.data || []
  },
  async getCompanyBlogContentData({ commit, state }, slug) {
    const areaName = state.isBaseDomain
      ? state.blogNameArea.baseDomain[slug]
      : state.blogNameArea.domain[slug]

    if (!areaName) return null

    const id = state.isBaseDomain
      ? state.siteSettings?.pages?.[areaName]?.id
      : state.companyBlogs?.[areaName]?.id

    if (!id) return null

    const blogApi = state.isBaseDomain ? getBlogForFront : getCompanyBlogFront

    const result = await this.$axios.request({
      ...blogApi({ id })
    })

    return result.data || { title: null, description: null }
  }
}

export const getters = {
  getOrderCompleteModal(state, payload) {
    return state.orderCompleteModal
  },
  getIsPageFixed(state) {
    return state.isPageFixed
  },
  getIsBaseDomain(state) {
    return state.isBaseDomain
  },
  getCompanyBlog(state) {
    return state.companyBlogs
  },
  getPopup(state) {
    return state.popup
  },
  getBlogAreaSlug: state => name => {
    return name
  }
}
