<template>
  <zz-div :loading="loading">
    <div class="row">
      <div class="col-md-12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="form-material"
            @submit.prevent="handleSubmit(handleLoginSubmit)"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Telefon')"
              rules="required"
            >
              <div class="mb-6">
                <phoneNumberInput
                  id="input-phone"
                  v-model="displayPhone"
                  :disabled="loading"
                  :class="{ 'is-invalid': errors[0] }"
                  required
                  :border-radius="2"
                  default-country-code="TR"
                  no-flags
                  no-example
                  :translations="{
                    phoneNumberLabel: $t('Telefon'),
                    countrySelectorLabel: $t('Ülke Kodu')
                  }"
                  @update="p => (login['phone'] = p.formattedNumber)"
                >
                </phoneNumberInput>
              </div>
            </validation-provider>
            <button
              :disabled="loading"
              class="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 rezzta-red white--text"
              type="submit"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Devam Et') }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </zz-div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import phoneNumberInput from 'vue-phone-number-input'

export default {
  name: 'AuthLoginWithPhone',
  components: {
    phoneNumberInput
  },
  data() {
    return {
      displayPhone: '',
      login: {
        phone: ''
      },
      loading: false
    }
  },
  methods: {
    async handleLoginSubmit() {
      this.loading = true

      try {
        await this.$store.dispatch('auth/loginWithPhone', this.login)
        this.$emit('success')
      } catch (error) {
        this.$emit('fail', error)
        this.$notification.error(
          error?.message || this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
