/* eslint-disable camelcase */
import {
  getBasket,
  postBasket,
  deleteBasket,
  deleteBasketItem,
  patchBasketItemPiece,
  patchBasketDeliveryDate
} from '@/api/basket'

const defaultValues = {
  price: {
    net_price: 0,
    discount_price: 0,
    total_price_with_gift_card: 0,
    gift_card_price: 0,
    point_price: 0,
    total_price_with_point: 0,
    tip_amount: 0
  },
  restaurant: {
    name: '',
    neighborhood_name: '',
    slug: '',
    currency: '₺',
    delivery_type: null,
    showDeliveryType: false
  },
  items: [],
  count: 0,
  restaurantId: null
}

export const state = () => ({
  ...defaultValues
})

export const mutations = {
  setPrice: (state, price) => {
    state.price = { ...state.price, ...price }
  },
  setRestaurantData: (state, restaurantData) => {
    state.restaurant = { ...state.restaurant, ...restaurantData }
  },
  setItems: (state, items) => {
    state.items = items
  },
  setCount: (state, count) => {
    state.count = count
  },
  setRestaurantId: (state, restaurantId) => (state.restaurantId = restaurantId),
  clear: state => {
    state.price = { ...defaultValues.price }
    state.restaurant = { ...defaultValues.restaurant }
    state.items = []
    state.count = 0
    state.restaurantId = null
  }
}

export const actions = {
  async getBasket({ commit }, payload) {
    try {
      const resp = await this.$axios({
        ...getBasket(),
        params: {
          qr: payload?.qr || undefined
        }
      })

      const {
        price,
        basket,
        restaurant_id,
        restaurant_name,
        restaurant_neighborhood_name,
        restaurant_slug,
        currency,
        delivery_type,
        showDeliveryType
      } = resp.data

      commit('clear')

      if (price) {
        commit('setPrice', price)
        commit('setRestaurantData', {
          name: restaurant_name,
          neighborhood_name: restaurant_neighborhood_name,
          slug: restaurant_slug,
          currency,
          delivery_type,
          showDeliveryType
        })
        commit('setCount', price.piece || 0)
      }

      if (basket) {
        commit('setItems', basket)
      }

      // eslint-disable-next-line camelcase
      if (restaurant_id) commit('setRestaurantId', restaurant_id)

      return Promise.resolve(resp.data)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async addBasket({ dispatch }, payload) {
    try {
      const result = await this.$axios.request({
        ...postBasket(),
        data: {
          data: [payload.data],
          delivery_type: payload.deliveryType,
          delivery_date: payload.deliveryDate,
          table_no: payload.tableNo
        }
      })

      await dispatch('getBasket')

      return Promise.resolve(result.data)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async clearBasket({ dispatch }) {
    try {
      const result = await this.$axios.request({
        ...deleteBasket()
      })

      await dispatch('getBasket')

      return Promise.resolve(result.data)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async deleteBasketItem({ dispatch }, itemUuid) {
    try {
      const result = await this.$axios.request({
        ...deleteBasketItem({ itemUuid })
      })

      await dispatch('getBasket')

      return Promise.resolve(result.data)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async patchBasketItemPiece({ dispatch }, data) {
    try {
      const result = await this.$axios.request({
        ...patchBasketItemPiece({ itemUuid: data.uuid }),
        data: { piece: data.piece }
      })

      await dispatch('getBasket')

      return Promise.resolve(result.data)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async patchBasketDeliveryDate({ dispatch }, data) {
    try {
      const result = await this.$axios.request({
        ...patchBasketDeliveryDate(),
        data: { delivery_date: data.deliveryDate }
      })

      await dispatch('getBasket')

      return Promise.resolve(result.data)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  setPrice({ commit }, data) {
    commit('setPrice', data)
  }
}
