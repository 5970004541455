<template>
  <zz-div :loading="loading">
    <div class="row">
      <div class="col-md-12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="form-material"
            @submit.prevent="handleSubmit(handleRegisterSubmit)"
          >
            <validation-provider v-slot="{ errors }" :name="$t('İsim')">
              <div class="form-label-group">
                <input
                  id="inputName"
                  v-model="register.name"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('İsim')"
                  :disabled="loading"
                  maxlength="100"
                  type="text"
                  class="form-control"
                  required
                />
                <label for="inputName">
                  {{ $t('İsim') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" :name="$t('Soyisim')">
              <div class="form-label-group">
                <input
                  id="inputSurname"
                  v-model="register.surname"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('Soyisim')"
                  :disabled="loading"
                  maxlength="100"
                  type="text"
                  class="form-control"
                  required
                />
                <label for="inputSurname">
                  {{ $t('Soyisim') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('E-Posta')"
              rules="required"
            >
              <div class="form-label-group">
                <input
                  id="inputEmail"
                  v-model="register.email"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('E-Posta')"
                  :disabled="loading"
                  type="email"
                  class="form-control pr-6"
                  required
                />
                <span class="common-icon icon-style-1" v-html="icons.at"></span>
                <label for="inputEmail">
                  {{ $t('E-Posta') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" :name="$t('E-Posta')">
              <div class="form-label-group register-telephone-w">
                <phoneNumberInput
                  id="input-phone"
                  v-model="displayPhone"
                  :disabled="loading"
                  :class="{ 'is-invalid': errors[0] }"
                  required
                  :border-radius="2"
                  default-country-code="TR"
                  no-example
                  no-flags
                  :translations="{
                    phoneNumberLabel: $t('Telefon'),
                    countrySelectorLabel: $t('Ülke Kodu')
                  }"
                  @update="p => (register['phone'] = p.formattedNumber)"
                >
                </phoneNumberInput>
                <span
                  class="common-icon icon-rotate-270 icon-style-1 icon-path-grey"
                  style="top: 13px;"
                  v-html="icons.phone"
                ></span>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('Şifre')">
              <div class="form-label-group">
                <input
                  id="inputPassword"
                  v-model="register.password"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('Şifre')"
                  :disabled="loading"
                  type="password"
                  class="form-control pr-6"
                  required
                  minlength="6"
                />
                <span
                  class="common-icon icon-style-1"
                  v-html="icons.password"
                ></span>
                <label for="inputPassword">
                  {{ $t('Şifre') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <validation-provider
              v-if="$store.state.isBaseDomain"
              v-slot="{ errors }"
              :name="$t('Referans Kodu')"
            >
              <div class="form-label-group">
                <input
                  id="inputReferenceCode"
                  v-model="register.reference_code"
                  :class="{ 'is-invalid': errors[0] }"
                  :placeholder="$t('Referans Kodu')"
                  :disabled="loading"
                  class="form-control pr-6"
                  minlength="16"
                  maxlength="16"
                />
                <span
                  class="common-icon icon-style-1"
                  v-html="icons.reference"
                ></span>
                <label for="inputReferenceCode">
                  {{ $t('Referans Kodu') }}
                </label>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <!-- Kullanıcı Sözleşmesi -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Kullanıcı Sözleşmesi')"
            >
              <div class="custom-control custom-checkbox mb-3">
                <input
                  id="userAgreementReadAndAggreCheckbox"
                  v-model="userAgreementReadAndAggre"
                  type="checkbox"
                  class="custom-control-input"
                  required
                />
                <i18n
                  path="useragreement_tos"
                  tag="label"
                  class="custom-control-label"
                  for="userAgreementReadAndAggreCheckbox"
                >
                  <a href="javascript:;" @click="blogHandler('user-agreement')">
                    {{ $t('Kullanıcı Sözleşmesini') }}
                  </a>
                </i18n>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <!-- KvK Sözleşmesi -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Kullanıcı Aydınlatma Metni')"
            >
              <div class="custom-control custom-checkbox mb-3">
                <input
                  id="clarificationTextCheckbox"
                  v-model="clarificationTextAggre"
                  type="checkbox"
                  class="custom-control-input"
                  required
                />
                <i18n
                  path="clarificationtext_tos"
                  tag="label"
                  class="custom-control-label"
                  for="clarificationTextCheckbox"
                >
                  <a
                    href="javascript:;"
                    @click="blogHandler('member-ship-form')"
                  >
                    {{ $t('Kullanıcı Aydınlatma Metnini') }}
                  </a>
                </i18n>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>
            <button
              :disabled="loading"
              class="btn btn-lg btn-block text-uppercase font-weight-bold mb-2 rezzta-red white--text"
              type="submit"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Kayıt Ol') }}
            </button>
          </form>
        </ValidationObserver>

        <div class="text-center pt-3">
          {{ $t('Zaten üye misiniz?') }}
          <a
            href="javascript:;"
            class="font-weight-bold"
            @click="handleClickLogin"
          >
            {{ $t('Giriş Yap') }}
          </a>
        </div>
      </div>
    </div>

    <!-- Sözleşmeler modal -->
    <b-modal
      v-model="blog.modal"
      size="lg"
      centered
      ok-only
      lazy
      scrollable
      hide-footer
      content-class="custom-modal-1 pa-2"
    >
      <template slot="modal-title"> {{ blog.textTitle }} </template>
      <blogArea
        :slug="blog['blogSlug']"
        style="margin-top: -20px"
        @title="blog['textTitle'] = $event"
      ></blogArea>
    </b-modal>
  </zz-div>
</template>

<script>
// TODO: Kullanıcı sözleşmesi içeriği eklenecek
import { mapGetters } from 'vuex'
import { register } from '@/api/member'
import trafficMixin from '@/mixins/traffic'
import { at, password, reference, phone } from '@/assets/icon/index.js'
import blogArea from '@/pages/blog/_index'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import phoneNumberInput from 'vue-phone-number-input'

export default {
  name: 'AuthRegister',
  components: {
    blogArea,
    phoneNumberInput
  },
  mixins: [trafficMixin],
  data() {
    return {
      userAgreementReadAndAggre: false,
      clarificationTextAggre: false,
      register: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        password: '',
        register_type: 'website',
        reference_code: null
      },
      displayPhone: '',
      loading: false,
      icons: {
        at,
        password,
        reference,
        phone
      },
      blog: {
        modal: false,
        blogSlug: null,
        textTitle: null
      }
    }
  },
  computed: {
    ...mapGetters({
      getBlogAreaSlug: 'getBlogAreaSlug'
    })
  },
  mounted() {
    if (this.$route.params.code) {
      this.register.reference_code = this.$route.params.code
    }
  },
  methods: {
    blogHandler(name) {
      this.blog.blogSlug = this.getBlogAreaSlug(name)
      this.blog.modal = true
    },
    async handleRegisterSubmit() {
      this.loading = true

      try {
        const result = await this.$axios.request({
          ...register(),
          data: this.register
        })

        this.$store.commit('auth/setTempMember', {
          id: result.data.member_id,
          phone: result.data.phone
        })
        this.$store.dispatch('auth/showVerifyPhoneModal')
      } catch (error) {
        this.$emit('fail', error)
        this.$notification.error(
          error?.message || this.$t('Bir hata oluştu. Lütfen tekrar deneyiniz.')
        )
      } finally {
        this.loading = false
      }
    },
    handleClickLogin() {
      return this.$store.dispatch('auth/showLoginModal')
    }
  }
}
</script>
