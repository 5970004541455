var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "header-search-form",
      class: { "header-search-result": _vm.isSearch },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSearch($event)
        }
      }
    },
    [
      _c("div", {
        staticClass: "search-modal-bg",
        on: { click: _vm.searchModalClose }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.searchQuery,
            expression: "searchQuery",
            modifiers: { trim: true }
          }
        ],
        attrs: {
          placeholder: _vm.$t("Yemek veya restoran arayın..."),
          type: "text"
        },
        domProps: { value: _vm.searchQuery },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value.trim()
            },
            _vm.handleSearchMethod
          ],
          click: _vm.handleSearchClick,
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _vm._v(" "),
      _c("button", { attrs: { type: "submit" } }, [
        _c("span", {
          staticClass: "common-icon",
          class: { "search-loading": _vm.loading },
          domProps: {
            innerHTML: _vm._s(
              _vm.loading ? _vm.icons.spinner : _vm.icons.search
            )
          }
        })
      ]),
      _vm._v(" "),
      _vm.isSearch && _vm.searchResult && _vm.searchResult.length
        ? _c(
            "div",
            {
              staticClass: "search-result-wrapper restaurant-section-list-box-w"
            },
            [
              _c("div", { staticClass: "custom-scroll" }, [
                _c(
                  "div",
                  { staticClass: "restaurant-section-list" },
                  _vm._l(_vm.searchResult, function(item, index) {
                    return _c("Restaurant", {
                      key: index,
                      attrs: { item: item, "show-favorite": false }
                    })
                  }),
                  1
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }