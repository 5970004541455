export const getBlogForFront = ({ id }) => {
  return {
    url: `/blog/front/${id}`,
    method: 'get'
  }
}

export const getCompanyBlogFront = ({ id }) => {
  return {
    url: `/company-blog/front/${id}`,
    method: 'get'
  }
}

export const getCompanyBlogsByDomain = () => {
  return {
    url: '/company-blog/front',
    method: 'get'
  }
}
