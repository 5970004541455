export const getCompanyByDomain = ({ domain }) => {
  return {
    url: `/company/domain/${domain}`,
    method: 'get'
  }
}

export const getCompanyPointSetting = () => {
  return {
    url: '/company/point-setting',
    method: 'get'
  }
}
