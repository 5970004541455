<template>
  <b-modal
    v-if="getMemberLastOrderPointLast"
    ref="ratingModal"
    v-model="getOrderPointModal"
    :title="$t('Siparişinizi değerlendirin!')"
    :content-class="'custom-modal-1 custom-modal-1-perspective ' + noError"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div class="order-rating-w">
      <PointInfo
        show="suggestion"
        hidden-title
        hidden-top-title
        hidden-error
        description-class="text-center"
        :active-func="handlePointActive"
      />
      <rating
        v-if="isShow"
        ref="point"
        class="px-4 pt-2 pb-4 transition-2"
        :order-id="getMemberLastOrderPointLast.id"
        is-home
        @success="handleSuccessRating"
      >
        <div slot="productInf" class="rating-product-w">
          <p
            v-html="
              $t(`<small> {restaurantName} </small>
                restoranından verdiğiniz siparişi beğendiniz
                mi?`).replace(
                '{restaurantName}',
                getMemberLastOrderPointLast.restaurant.name
              )
            "
          ></p>
          <p>
            -
            <span
              v-for="(product,
              productIndex) in getMemberLastOrderPointLast.order_products"
              :key="productIndex"
            >
              {{ product }}
              {{
                getMemberLastOrderPointLast.order_products.length - 1 ===
                productIndex
                  ? ''
                  : ','
              }}
            </span>
          </p>
        </div>
        <a
          slot="ignore"
          href="#"
          class="btn btn-rezzta-grey border-radius-7 ml-3 flex-shrink-0"
          @click.prevent="ignoreHandle(getMemberLastOrderPointLast)"
        >
          {{ $t('Geç') }}
        </a>
      </rating>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import PointInfo from '@/components/common/pointInfo'
import rating from '@/components/me/orders/rating'
import { memberOrderChangeIsShow } from '@/api/member'

export default {
  components: {
    rating,
    PointInfo
  },
  data() {
    return {
      noError: 'custom-modal-1-close-h-btn',
      isShow: true
    }
  },
  computed: {
    ...mapGetters({
      getMemberLastOrderPointLast: 'auth/getMemberLastOrderPointLast',
      getOrderPointModal: 'auth/getOrderPointModal'
    })
  },
  methods: {
    handlePointActive(point) {
      return !this.$store.state.isBaseDomain && point?.earn?.is_active && +point.earn?.comment_point?.point
    },
    async ignoreHandle(item) {
      try {
        const params = {
          memberId: null,
          orderId: null
        }

        params.memberId = this.$store.state.auth.info.id
        params.orderId = item.id
        await this.$axios.request({
          ...memberOrderChangeIsShow(params)
        })
        this.$refs.point.$el.style.transform = `rotateY(90deg)`
        this.$store.commit('auth/setMemberLastOrderPointList', item.id)
        this.$refs.point.$el.style.transform = `rotateY(180deg) scaleX(-1)`

        setTimeout(() => {
          this.isShow = false
        }, 200)
        setTimeout(() => {
          this.isShow = true
        }, 200)
      } catch (error) {
        this.noError = ''
        this.$notification.error(
          this.$t('Bir hata oluştu daha sonra tekrar deneyin')
        )
      }
    },
    handleSuccessRating() {
      this.$refs.point.$el.style.transform = `rotateY(90deg)`
      this.$store.commit(
        'auth/setMemberLastOrderPointList',
        this.getMemberLastOrderPointLast.id
      )
      this.$refs.point.$el.style.transform = `rotateY(180deg) scaleX(-1)`
      setTimeout(() => {
        this.isShow = false
      }, 200)
      setTimeout(() => {
        this.isShow = true
      }, 200)
    }
  }
}
</script>
