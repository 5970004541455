<template>
  <div class="error-domain-license">
    <img src="/images/500.jpg" />
    <h1>
      {{ $t('Geçici bir süreliğine hizmet veremiyoruz') }}
    </h1>
    <div class="error-domain-license-bottom">
      <span>
        {{ $t('Anlayışınız için teşekkür ederiz') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorDomainLicense'
}
</script>

<style scoped lang="scss">
.error-domain-license {
  position: fixed;
  left: 50%;
  top: calc(50% - 40px);
  transform: translateX(-50%) translateY(-50%);
  max-width: 690px;
  max-height: 420px;
  margin: auto;
  width: 70vw;
  min-width: 300px;
  min-height: 365px;
  height: 28vw;
  padding: 24px 24px;
  background-color: #b61924;
  border-radius: 7px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  img {
    opacity: 0.9;
    z-index: -5;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 300px;
    min-height: 365px;
    max-width: 700px;
    min-width: 450px;
    width: 75vw;
  }
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    width: 260px;
    height: 3vw;
    min-height: 22px;
    max-height: 30px;
    transform: translateY(-70px) translateX(50%) rotate(45deg);
    transform-origin: left top;
  }
  h1 {
    color: #fff;
    font-size: calc(30px + 0.4vw);
    margin-bottom: 60px;
    margin-top: 0;
    text-shadow: 2px 1px #232323;
    letter-spacing: 1px;
  }
  .error-domain-license-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 7px;
    border-top: 0;
    border-bottom-right-radius: 7px;
    border: 1px solid #b61924;
    span {
      font-size: calc(14px + 0.2vw);
      font-weight: 600;
      color: #b61924;
    }
  }
}
</style>
