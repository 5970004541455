import { localize } from 'vee-validate'

export default function({ store, app }) {
  // beforeLanguageSwitch called right before setting a new locale
  //   app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
  //     console.log(oldLocale, newLocale)
  //   }
  // onLanguageSwitched called right after a new locale has been set
  function loadLocale(code) {
    store.dispatch('auth/setLang', code)

    return import(`@/lang/${code}.js`).then(translate => {
      const message = translate.default

      localize(code, message.veeValidate)
    })
  }

  loadLocale(app.i18n.locale || 'tr')

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    loadLocale(newLocale)
  }
}
