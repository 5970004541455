var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$scopedSlots["input"]
    ? _c(
        "span",
        [
          _vm._t("input", null, {
            attrs: _vm.$attrs,
            listeners: _vm.$listeners
          })
        ],
        2
      )
    : !_vm.$scopedSlots["input"]
    ? _c(
        "input",
        _vm._g(
          _vm._b(
            { ref: "input", attrs: { placeholder: _vm.$t("Adres Ara") } },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }