<template>
  <div id="jaklcp-chat-container"></div>
</template>

<script>
export default {
  mounted() {
    (function(w, d, s, u) {
      w.id = 10
      w.lang = ''
      w.cName = ''
      w.cEmail = ''
      w.cMessage = ''
      w.lcjUrl = u

      const h = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)

      j.async = true
      j.src = 'https://ihbxtfpktz-10.apptest.limondesk.com/js/jaklcpchat.js?v=2'
      h.parentNode.insertBefore(j, h)
    })(
      window,
      document,
      'script',
      'https://ihbxtfpktz-10.apptest.limondesk.com/'
    )
  }
}
</script>

<style></style>
