import { render, staticRenderFns } from "./verifyPhone.vue?vue&type=template&id=447f9fc7&"
import script from "./verifyPhone.vue?vue&type=script&lang=js&"
export * from "./verifyPhone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('447f9fc7')) {
      api.createRecord('447f9fc7', component.options)
    } else {
      api.reload('447f9fc7', component.options)
    }
    module.hot.accept("./verifyPhone.vue?vue&type=template&id=447f9fc7&", function () {
      api.rerender('447f9fc7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/common/auth/verifyPhone.vue"
export default component.exports