var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "rezzta-wrapper",
          staticStyle: {
            "background-image": "url('/images/rezzta-home/background.png')"
          }
        },
        [
          _c("img", {
            staticClass: "rw-line-1",
            attrs: { src: "/images/rezzta-home/line-1.png" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "rw-content" }, [
            _c("div", { staticClass: "rwc-text" }, [
              _c("div", { staticClass: "rwct-icon" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        _vm.$i18n.locale === "tr"
                          ? "https://www.akinsoft.com/tr"
                          : "https://www.akinsoft.com",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "rwc-akinsoft-logo mr-5",
                      attrs: { alt: "logo", src: "/images/akinsoft.svg" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("img", {
                  staticClass: "rwc-rezzta-logo",
                  attrs: { alt: "logo", src: "/images/rezzta.svg" }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rwct-center" },
                [
                  _c(
                    "i18n",
                    {
                      staticClass:
                        "font-weight-regular text--rezzta-black-1 rwctc-text-1",
                      attrs: { path: "rezzta_home_text_1", tag: "p" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "text--rezzta-red font-weight-medium" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("Online Yemek Sipariş")) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("i18n", {
                    staticClass:
                      "font-weight-bold text--rezzta-black-1 rwctc-text-2",
                    attrs: { path: "rezzta_home_text_2", tag: "p" }
                  }),
                  _vm._v(" "),
                  _c("i18n", {
                    staticClass:
                      "font-weight-bold text--rezzta-black-1 mb-0 font-italic mt-auto rwctc-text-3",
                    attrs: { path: "rezzta_home_text_3", tag: "p" }
                  }),
                  _vm._v(" "),
                  _c("i18n", {
                    staticClass:
                      "font-weight-regular text--rezzta-black-1 mb-0 font-italic rwctc-text-4",
                    attrs: { path: "rezzta_home_text_4", tag: "p" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "rwctc-bottom" },
                    [
                      _c("div", { staticClass: "rwctcb-btn p-relative" }, [
                        _c("img", {
                          staticClass: "rw-line-2",
                          attrs: { src: "/images/rezzta-home/line-2.png" }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "rezzta-red text-nowrap rwctcbb-a",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.blogHandler(
                                  "demo-rezzta-description"
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("Rezzta Hakkında")) +
                                "\n              "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("i18n", {
                        staticClass:
                          "font-weight-bold text--rezzta-red text-underline text-center rwctc-text-5",
                        attrs: {
                          path: "rezzta_home_text_5",
                          tag: "a",
                          target: "_blank",
                          href:
                            _vm.$i18n.locale === "en"
                              ? "https://www.akinsoft.com.tr/as/irtibat/istekoneri?ozel=rezzta"
                              : "https://www.akinsoft.com.tr/as/irtibat/istekoneri?ozel=rezzta"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "rw-link" }, [
            _c(
              "div",
              { staticClass: "rw-lang" },
              [
                _vm.$i18n.locale !== "en"
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "fs15",
                        attrs: { to: _vm.switchLocalePath("en") }
                      },
                      [_vm._v("\n          English\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$i18n.locale !== "tr"
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "fs15",
                        attrs: { to: _vm.switchLocalePath("tr") }
                      },
                      [_vm._v("\n          Türkçe\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$i18n.locale !== "de"
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "fs15",
                        attrs: { to: _vm.switchLocalePath("de") }
                      },
                      [_vm._v("\n          Deutsch\n        ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            centered: "",
            scrollable: "",
            "hide-footer": "",
            "content-class": "custom-modal-1"
          },
          model: {
            value: _vm.blog.modal,
            callback: function($$v) {
              _vm.$set(_vm.blog, "modal", $$v)
            },
            expression: "blog.modal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-4 pt-2 pb-4" },
            [
              _c("h5", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.blog.textTitle))
              ]),
              _vm._v(" "),
              _c("blogArea", {
                staticStyle: { "margin-top": "-20px" },
                attrs: { slug: _vm.blog.blogSlug },
                on: {
                  title: function($event) {
                    _vm.blog["textTitle"] = $event
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rwc-right" }, [
      _c("div", { staticClass: "rwcr-top" }, [
        _c("img", {
          staticClass: "rwcrt-img-1",
          attrs: {
            src: "/images/rezzta-home/hamburger-background.png",
            alt: "hamburger_background"
          }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "rwcrt-img-2",
          attrs: { src: "/images/rezzta-home/hamburger.png", alt: "hamburger" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rwcr-bottom" }, [
        _c("img", {
          staticClass: "rwcrb-img",
          attrs: {
            src: "/images/rezzta-home/mobil-image.png",
            alt: "mobile_image"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }