<template>
  <div>
    <client-only>
      <cookie-law :button-text="$t('Tamam')" theme="custom-cookie">
        <div slot="message">
          <i18n
            path="cookie_policy_read_and_aggre_tos"
            tag="span"
            class="text-underline"
          >
            <a href="#" @click.prevent="lawModal('cookie')">
              {{ $t('Çerez Politikamızı') }}
            </a>
            <a href="#" @click.prevent="lawModal('privacy')">
              {{ $t('Gizlilik Politikamızı') }}
            </a>
          </i18n>
        </div>
      </cookie-law>
    </client-only>
    <b-modal
      v-model="law.modal"
      size="lg"
      centered
      content-class="custom-modal-1"
      lazy
      ok-only
      :ok-title="$t('Kapat')"
    >
      <blogArea :slug="law.blogSlug" style="margin-top: -20px"></blogArea>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import blogArea from '@/pages/blog/_index'

let cookieLaw

if (process.client) {
  cookieLaw = require('vue-cookie-law').default
}

export default {
  name: 'DomainCookieLaw',
  components: {
    cookieLaw,
    blogArea
  },
  data() {
    return {
      law: {
        modal: false,
        blogSlug: null
      }
    }
  },
  computed: {
    ...mapGetters({
      getBlogAreaSlug: 'getBlogAreaSlug'
    })
  },
  methods: {
    lawModal(name) {
      this.law.blogSlug = name
      this.law.modal = true
    }
  }
}
</script>
