<template>
  <div>
    <layoutBase v-if="$store.state.isBaseDomain"></layoutBase>
    <layoutDomain v-else></layoutDomain>

    <order-rating />

    <b-modal
      ref="loginModal"
      v-model="authModal.show"
      :content-class="`${authModal.hiddenClose ? 'custom-modal-1-close-h-btn' : ''} ${authModal.component === 'loginForm' ? 'custom-modal-1-visible custom-modal-1-mask-image-none' : ''} custom-modal-1 user-login-register`"
      hide-footer
      centered
      :no-close-on-backdrop="authModal.noClose"
      :no-close-on-esc="authModal.noClose"
      @hidden="handleHiddenModal"
    >
      <template slot="modal-title"> {{ $t(authModal.title) }} </template>
      <client-only>
        <component :is="authModal.component" v-bind="authModal.data" is-modal @success="handleLoginSuccess" @close="handleLoginClose" />
      </client-only>
    </b-modal>

    <b-modal
      ref="locationModal"
      v-model="locateModal.show"
      :title="$t('Konumunuzu Ayarlayın')"
      size="lg"
      content-class="custom-modal-1"
      modal-class="location-map-modal"
      centered
      scrollable
      hide-footer
      @hidden="handleHiddenLocateModal"
    >
      <component
        :is="locateModal.component"
        :show="locateModal.show"
        @handleHiddenLocateModal="handleHiddenLocateModal"
      />
    </b-modal>
  </div>
</template>

<script>
import layoutDomain from '@/layouts/_domain'
import layoutBase from '@/layouts/_base'
import orderRating from '@/components/common/orderRating'
import registerForm from '@/components/common/auth/register'
import memberDetailForm from '@/components/common/auth/memberDetail'
import loginForm from '@/components/common/auth/login'
import loginWithEmailForm from '@/components/common/auth/loginWithEmail'
import loginWithPhoneForm from '@/components/common/auth/loginWithPhone'
import verifyPhoneForm from '@/components/common/auth/verifyPhone'
import phoneForm from '@/components/common/auth/phone'
import partnerInstitutionForm from '@/components/me/index/partnerInstitution'
import selectMap from '@/components/common/selectMap'
import forgotPasswordForm from '@/components/common/auth/forgotPassword'
import { postFrontError } from '@/api/frontError'
import trafficMixin from '@/mixins/traffic'
import {
  location1,
  map,
  profil1,
  profil2,
  order,
  address,
  notification,
  giftCard,
  exit,
  login,
  register,
  search,
  undo,
  wallet,
  reward1
} from '@/assets/icon/index.js'

export default {
  name: 'DefaultLayout',
  components: {
    loginForm,
    loginWithEmailForm,
    loginWithPhoneForm,
    memberDetailForm,
    verifyPhoneForm,
    phoneForm,
    registerForm,
    forgotPasswordForm,
    partnerInstitutionForm,
    selectMap,
    layoutDomain,
    layoutBase,
    orderRating
  },
  mixins: [trafficMixin],
  middleware: 'requiredLocation',
  scrollToTop: true,
  data() {
    return {
      icons: {
        location1,
        map,
        profil1,
        profil2,
        order,
        address,
        notification,
        giftCard,
        exit,
        login,
        register,
        search,
        undo,
        wallet,
        reward1
      }
    }
  },
  computed: {
    authModal() {
      return JSON.parse(JSON.stringify(this.$store.state.auth.modal))
    },
    locateModal() {
      return JSON.parse(JSON.stringify(this.$store.state.auth.locationModal))
    }
  },
  mounted() {
    this.getCoords().then(result => {
      if (!this.$cookies.get('CID')) {
        if (result.isOK) {
          const { currentLat, currentLon } = result.coords

          this.postTrafficMethod({
            type: 'page_view',
            coords: { lat: currentLat, lon: currentLon }
          })
        }
      }
    })
    if (this.$store.state.auth.isLogged)
      this.$store.dispatch('auth/getMemberNotificationUnreadCount')
  },
  methods: {
    // Giriş yap Kayıt ol Şifremi unuttum başarılı sonuçlandı
    handleLoginSuccess() {
      this.$store.dispatch('auth/closeModal')
    },
    handleLoginClose() {
      this.$store.dispatch('auth/closeModal')
    },
    // Modal kapandı
    handleHiddenModal() {
      this.$store.dispatch('auth/closeModal')
      // if (this.$route.name && this.$route.name.includes('registerMember')) {
      //   this.$router.push(
      //     this.localePath({
      //       name: 'Index'
      //     })
      //   )
      // }
    },
    handleHiddenLocateModal() {
      this.$store.dispatch('auth/closeLocationModal')
    },
    getCoords() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }

      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            e => {
              setTimeout(() => {
                resolve({
                  isOK: true,
                  coords: {
                    currentLat: e.coords.latitude,
                    currentLon: e.coords.longitude
                  }
                })
              }, 100)
            },
            e => {
              switch (e.code) {
                case 1:
                  resolve({
                    isOK: false,
                    message:
                      'Tarayıcınızın "Konum" özelliğine izin vermeniz gerekmektedir.'
                  })
                  break
                default:
                  resolve({ isOK: false, message: 'Konum bulunamadı' })
                  break
              }
            },
            options
          )
        } else {
          resolve({
            isOK: false,
            message: 'Konum bulma tarayıcınız tarafından desteklenmiyor!'
          })
        }
      })
    }
  },
  errorCaptured(err, vm, info) {
    this.$axios({
      ...postFrontError(),
      data: {
        data: {
          message: err.message || '',
          stack: err.stack || '',
          info,
          url: window.location.href
        }
      }
    })
  }
  // mounted() {
  //   this.$nextTick(() => {
  //     const body = document.querySelector('body')
  //     const options = {
  //       attributes: true
  //     }

  //     function callback(mutationList, observer) {
  //       mutationList.forEach(function(mutation) {
  //         if (
  //           mutation.type === 'attributes' &&
  //           mutation.attributeName === 'class'
  //         ) {
  //           if (mutation.target.classList.contains('modal-open')) {
  //             body.style.height = window.innerHeight - 1 + 'px'
  //           } else {
  //             body.style.height = 'initial'
  //           }
  //         }
  //       })
  //     }

  //     const observer = new MutationObserver(callback)
  //     observer.observe(body, options)
  //   })
  // }
}
// Domain ya da rezzta için gerekli olan layoutu getirir
</script>
