var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-404" },
    [
      _c("h1", { staticClass: "mt-2 mb-2" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("Sayfa Bulunamadı!")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(
                "Aradığınız sayfa bulunamadı!  Silinmiş ya da yanlış adres yazmış olabilirsiniz"
              )
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "btn btn-primary btn-lg",
          attrs: { to: _vm.localePath("index") }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("Anasayfaya Dön")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }