var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.company.html && !_vm.isUrl
        ? _c("div", [_c("domainCmsHome")], 1)
        : _c(
            "div",
            { staticClass: "sub-domain" },
            [
              _c("domainHeader"),
              _vm._v(" "),
              _vm.location ? _c("nuxt") : _c("selectLocationMyRezzta"),
              _vm._v(" "),
              _c("domainFooter")
            ],
            1
          ),
      _vm._v(" "),
      _c("domainCookieLaw")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }